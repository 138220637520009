import React, { Component } from "react";
import { Link } from "react-router-dom";
import { history } from "lib/history";

import { withStyles } from "@material-ui/core/styles";
import { Box, IconButton, Tooltip, Button } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import * as configApp from "config/config-app";

/**
 * 페이지 내의 박스 블럭 Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name BoxWrap
 * @class
 * @component
 */

const styles = (theme) => ({
  root: {
    // border: '1px solid #ccc',
    borderRadius: "5px",
    background: "#fff",
    position: "relative",
    "& h3": {
      //      margin: 0,
      marginTop: "0",
      //marginLeft: "25px",
      fontSize: "17px",
    },
    "& h4": {
      //      margin: 0,
      marginTop: "0",
      //marginLeft: "25px",
      fontSize: "14px",
    },
  },
  boxWrapHeader: {
    //    border: "1px solid red"
    // maxWidth: "60%",
  },
  boxWrapHeaderWraper: {
    borderBottom: "1px solid #ddd",
    marginBottom: "20px",
  },
  boxWrapIcons: {
    position: "absolute",
    top: "17px",
  },
  subTitle: {
    // border: '1px solid red',
    "& h4": {
      margin: "4px 0 0 0",
      color: "#949494",
    },
    "& h6": {
      margin: "2px 0 0 0",
      color: "#949494",
    },
  },
  bigSubTitle: {
    "& div": {
      fontSize: "16px",
      margin: "1px 0 0 0",
    },
  },
});

class BoxWrap extends Component {
  /** 정보 업데이트 클릭 이벤트입니다. */

  render() {
    const {
      classes,
      title,
      link,
      subTitle,
      bigSubTitle,
      style,
      titleBorder,
      linkState,
      titleStyle,
      titleBoxStyle,
      badgeVal,
      titleLink,
      titleComment,
      warningTitle,
      historyPush,
      id,
      TitleButton,
      userType,
      buttonTitle,
      buttonFontSize,
      buttonAvailable,
      buttonOnClick,
      buttonColor,
      customStyle
    } = this.props;
    return (
      <Box my={2} p={2} className={classes.root} style={style} id={id}>
        {title && (
          <Box
            className={titleBorder && classes.boxWrapHeaderWraper}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <Box style={{ width: "80%", display: "flex" }}>
              {titleComment ? (
                <Tooltip title={titleComment} arrow>
                  <Box className={classes.boxWrapHeader} style={titleBoxStyle}>
                    {configApp.SERVICE_TYPE === "pc" && (
                      <>
                        <h3 style={titleStyle}>
                          {title}
                          {warningTitle && (
                            <>
                              &nbsp;
                              <span style={{ color: "#f34788" }}>
                                {warningTitle}
                              </span>
                            </>
                          )}
                        </h3>
                      </>
                    )}
                    {configApp.SERVICE_TYPE === "mobile" && (
                      <h4 style={titleStyle}>{title}</h4>
                    )}
                  </Box>
                </Tooltip>
              ) : (
                <Box className={classes.boxWrapHeader} style={titleBoxStyle}>
                  {configApp.SERVICE_TYPE === "pc" && (
                    <h3 style={titleStyle}>
                      {title}
                      {warningTitle && (
                        <>
                          &nbsp;
                          <span style={{ color: "#f34788" }}>
                            {warningTitle}
                          </span>
                        </>
                      )}
                    </h3>
                  )}
                  {configApp.SERVICE_TYPE === "mobile" && (
                    <p
                      style={
                        !!titleStyle
                          ? titleStyle
                          : {
                              fontSize: "12px",
                              fontWeight: "bold",
                              margin: "0 0 12px 0",
                            }
                      }
                    >
                      {title}
                    </p>
                  )}
                </Box>
              )}

              {subTitle && (
                <Box ml={1} className={classes.subTitle}>
                  {configApp.SERVICE_TYPE === "pc" && <h4>{subTitle}</h4>}
                  {configApp.SERVICE_TYPE === "mobile" && <h6>{subTitle}</h6>}
                </Box>
              )}
              {bigSubTitle && (
                <Box ml={1} className={classes.bigSubTitle}>
                  {configApp.SERVICE_TYPE === "pc" ? (
                    <div>{bigSubTitle}</div>
                  ) : (
                    <h6>{bigSubTitle}</h6>
                  )}
                </Box>
              )}

              {badgeVal && (
                <Box ml={1} className={"item-badge"}>
                  {badgeVal}
                </Box>
              )}
            </Box>
            {TitleButton && (
              <Box mb={1}>
                <TitleButton />
              </Box>
            )}
            {/* 웹마스터 계정 혹은 업주 PC에서 스마트형, 스마트형(변경예정)템플릿일 경우 보이는 버튼 */}
            {userType === "WEBMASTER" || buttonAvailable ? (
              <Button
                onClick={buttonOnClick}
                variant="contained"
                color={buttonColor || "primary"}
                style={{
                  width: "30%",
                  fontSize: buttonFontSize || "inherit",
                  ...customStyle
                }}
              >
                <strong>{buttonTitle}</strong>
              </Button>
            ) : null}
          </Box>
        )}

        {link && (
          <Box position="absolute" top={2} right={2}>
            {titleLink && (
              <span
                style={{
                  fontSize: "0.65em",
                  fontWeight: "bold",
                  opacity: "0.7",
                }}
              >
                {titleLink}
              </span>
            )}
            {!!historyPush ? (
              <IconButton
                onClick={function () {
                  //현재 화면 히스토리 푸시
                  console.log("historyPush: ", historyPush);
                  history.replace(historyPush);
                  //목표 화면으로 리다이렉트
                  history.push({ pathname: link, state: linkState });
                }}
              >
                <ChevronRightIcon />
              </IconButton>
            ) : (
              <Link to={{ pathname: link, state: linkState }}>
                <IconButton>
                  <ChevronRightIcon />
                </IconButton>
              </Link>
            )}
          </Box>
        )}
        {this.props.children}
      </Box>
    );
  }
}

//export default BoxWrap;
const styledBoxWrap = withStyles(styles)(BoxWrap);

export { styledBoxWrap as BoxWrap };
