import React from "react";
import {Box} from "@material-ui/core";

const HELPER_TEXT = {
    TODAY_COUPON_ADD: [
        {
            content: "※ 지금 즉시 발행되는 쿠폰입니다.",
            color: "pink",
        },
        // 20200510 김주호, PLATFORM-729 1차 개발 요구
        // {
        //   content: `※ 하루 발급 가능 수량은 총 ${COUNT_PUBLISH_COUPON}장입니다.`,
        //   color: "blue",
        // },
        {
            content: "※ 사용하지 않은 쿠폰은 소멸되며 비용이 지출되지 않습니다.",
            color: "blue",
        },
        {
            content:
                "※ 추가 발행 쿠폰 취소 시, 취소 수량은 요청 처리시간에 따라 상이할 수 있습니다.",
            color: "blue",
        },
    ],
    EASY_COUPON: [
        {
            content: "※ 설정한 쿠폰은 즉시 반영됩니다.",
            color: "red",
        },
        // {
        //   content: "※ 사용하지 않은 쿠폰은 소멸되며 비용이 지출되지 않습니다.",
        //   color: "blue",
        // },
        {
            content: "※ 사용하지 않은 쿠폰은 소멸됩니다.",
            color: "blue",
        },
    ],
    EASY_STAY_COUPON_PAGE: [
        {
            content: "※ 설정한 쿠폰은 즉시 반영됩니다.",
            color: "red",
        },
        // {
        //   content: "※ 사용하지 않은 쿠폰은 소멸되며 비용이 지출되지 않습니다.",
        //   color: "blue",
        // },
        {
            content: "※ 사용하지 않은 쿠폰은 소멸됩니다.",
            color: "blue",
        },
        {
            content: "※ 쿠폰 수량은 재설정된 수량으로 적용되며 재설정된 수량이 이미 사용된 수량보다 많을 경우 삭제되지 않습니다.",
            color: "blue",
        },
        {
            content: "※ 숙박 쿠폰은 주말 쿠폰으로 설정할 경우 [금/토 및 공휴일 전일]에 적용되며, 일별 상세 수량은 판매갤린더에서 변경 가능합니다.",
            color: "blue",
        }
    ],
    EASY_TIME_COUPON_PAGE: [
        {
            content: "※ 설정한 쿠폰은 즉시 반영됩니다.",
            color: "red",
        },
        // {
        //   content: "※ 사용하지 않은 쿠폰은 소멸되며 비용이 지출되지 않습니다.",
        //   color: "blue",
        // },
        {
            content: "※ 사용하지 않은 쿠폰은 소멸됩니다.",
            color: "blue",
        },
        {
            content: "※ 쿠폰 수량은 재설정된 수량으로 적용되며 재설정된 수량이 이미 사용된 수량보다 많을 경우 삭제되지 않습니다.",
            color: "blue",
        },
        {
            content: "※ 대실 쿠폰은 주말 쿠폰으로 설정할 경우 [토/일 및 공휴일]에 적용되며, 일별 상세 수량은 판매갤린더에서 변경 가능합니다.",
            color: "blue",
        }
    ],
    LIMITED_COUPON_PAGE: [
        {
            content: "※ 설정한 쿠폰은 즉시 반영됩니다.",
            color: "red",
        },
        {
            content: "※ 숙박 무제한 쿠폰은 5,000원 이상부터, 대실 무제한 쿠폰은 3,000원 이상부터 설정 가능합니다.",
            color: "red",
        },
        {
            content: "※ 사용하지 않은 쿠폰은 소멸됩니다.",
            color: "blue",
        },

    ],
    PRICE_LIMIT: [
        {
            content:
                "할인율이 아무리 높아도 숙박쿠폰은 최대 1만원을 넘어가지 않습니다.",
            color: "red",
        },
        {
            content:
            // "할인율이 아무리 높아도 대실쿠폰은 최대 5천원을 넘어가지 않습니다.",
                "할인율이 아무리 높아도 겸용쿠폰, 대실쿠폰은 최대 5천원을 넘어가지 않습니다.",
            color: "red",
        },
    ],
};

const CONST_COLOR = [
    {name: "blue", code: "#008AFF"},
    {name: "pink", code: "#F34788"},
    {name: "red", code: "#FF4A4A"},
];

/** name을 받아 색상 코드 반환 */
function getColorCode(name) {
    return CONST_COLOR.find((color) => color.name === name).code;
}

// 2021.06 : 6월말 개발건부터 미사용 예정(PLATFORM-747)
/** 쿠폰간편발행설정, 오늘쿠폰추가발행 가장 하단의 붉은색 도움말 컴포넌트*/
function RedHelperText(props) {
    const {needStyle, fontSize} = props;
    const textStyle = !needStyle
        ? null
        : {
            textDecoration: "underline",
            fontWeight: "bold",
        };
    return (
        <Box style={{margin: "3px  0px 0px 16px"}}>
            <div
                style={{
                    margin: "0",
                    color: "#FF4A4A",
                    fontSize: fontSize || "13px",
                }}
            >
                할인율이 아무리 높아도{" "}
                <span style={textStyle}>숙박쿠폰은 최대 1만원</span>을 넘어가지
                않습니다.
            </div>
            <div
                style={{
                    margin: "0",
                    color: "#FF4A4A",
                    fontSize: fontSize || "13px",
                }}
            >
                할인율이 아무리 높아도{" "}
                {/* <span style={textStyle}>겸용쿠폰, 대실쿠폰은 최대 5천원</span>을
        넘어가지 않습니다. */}
                <span style={textStyle}>대실쿠폰은 최대 5천원</span>을 넘어가지
                않습니다.
            </div>
        </Box>
    );
}

export default function CouponHelperText(props) {
    const {code, fontSize} = props;

    return (
        <Box mt={2}>
            {HELPER_TEXT[code].map((val, key) => {
                const fontColor = getColorCode(val.color);
                return (
                    <p
                        key={key}
                        style={{
                            fontWeight: "bold",
                            margin: "0",
                            color: fontColor,
                            fontSize: fontSize || "13px",
                        }}
                    >
                        {val.content}
                    </p>
                );
            })}
        </Box>
    );
}

export {RedHelperText};
