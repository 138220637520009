import { makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { getIsMaster } from "lib/service-storage";
const useStyles = makeStyles((theme) => ({
  badgeContainer: {
    width: "80px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: "20px",
    fontSize: "15px",
  },
  visible: {
    backgroundColor: "#4585FF",
  },
  invisible: {
    backgroundColor: "#FF4841",
  },
}));
/**
 * @desc 해당 제휴점이 노출중/비노출중 여부를 나타내는 Badge입니다.
 * @param {boolean} visible - 노출중 여부
 * @param {ReactNode} style - Badge에 적용할 스타일
 */
const AppVisibleBadge = ({ visibleYn, style }) => {
  const classes = useStyles();
  const isVisible = visibleYn;
  const isMaster = getIsMaster();
  // 예외1 : 마스터 권한의 계정만 노출중/비노출중 Badge를 볼 수 있다
  // 예외2 : visibleYn이 null일 경우(초기값) 화면에 표출하지 않는다
  if (!isMaster || visibleYn === null) return null;
  return (
    <div
      className={clsx(
        classes.badgeContainer,
        isVisible ? classes.visible : classes.invisible
      )}
      style={{
        ...style,

      }}
    >
      {isVisible ? "노출 중" : "비노출 중"}
    </div>
  );
};

export default AppVisibleBadge;
