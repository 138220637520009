/**
 * 팝업 출력 헬퍼 모듈 입니다<p/>
 *
 * @module lib/helper-popup
 */

import axios from "axios";
import * as configApp from "config/config-app";
import { openAlert, openNotice, closeNotice } from "lib/service-redux-alerts";
import * as utils from "utils";

const TAG = "[lib/helper-popup]";
// console.log(TAG, "Create");

let __isProvided = false;
let __store = null;
let __disableKey = false;

export const POPUP_ACTION_OK = "OK";
export const POPUP_ACTION_NO = "NO";
export const POPUP_ACTION_CANCEL = "CANCEL";

const SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";
// const DEFAULT_POPUP_TITLE = "[파트너센터 안내]";
const DEFAULT_POPUP_TITLE = "파트너센터 안내";
const DEFAULT_UNDEFINED_POPUP_MESSAGE = `일시적으로 서비스 이용이\n원활하지 않습니다\n(${configApp.COOLSTAY_TEL})`;
const DEFAULT_POPUP_MESSAGE = !SERVICE_TYPE_PC
  ? "연결 상태가\n원활하지 않습니다."
  : "연결 상태가 원활하지 않습니다.";

/**
 * 초기 설정을 처리하는 메소드 입니다
 *
 * @param {Redux.Store} store - Redux Store 객체
 */
export function provider(store) {
  if (__isProvided) return;
  __isProvided = false;
  // console.log(TAG, "Provider");
  __store = store;

  utils.addEventListener(document, "keydown", (evt) => {
    // 서버 연동 중에 'keydown' 이벤트가 발생 합니다
    if (__disableKey) {
      blockEvent(evt);
    }
  });
}

export function openConfirmPopupWithPromise(
  model,
  message,
  lblBtnYES,
  lblBtnNO
) {
  let title, description, lblYES, lblNO;
  if (model !== null && typeof model === "object") {
    lblYES = model.buttonConfirm;
    lblNO = model.buttonCancel;
    description = model.desc;
    title = model.title;
  } else {
    lblYES = lblBtnYES;
    lblNO = lblBtnNO;
    description = message;
    title = model;
  }

  return new Promise(function (resolve, reject) {
    if (!__store) return reject();
    let actionType = POPUP_ACTION_CANCEL;
    __store.dispatch(
      openAlert(
        // startedListener
        (clickListener, key) => {
          return {
            maxWidth: "xs",
            title: title || DEFAULT_POPUP_TITLE,
            description: description,
            actions: [
              {
                label: lblYES || "확인",
                dataType: "YES",
                onClick: clickListener,
              },
              {
                label: lblNO || "취소",
                dataType: "NO",
                onClick: clickListener,
              },
            ],
          };
        },
        // clickListener
        (event, close) => {
          if (!close) return;
          if (event.currentTarget.getAttribute("data-type") === "YES") {
            actionType = POPUP_ACTION_OK;
            __disableKey = true;
          } else if (event.currentTarget.getAttribute("data-type") === "NO") {
            actionType = POPUP_ACTION_NO;
          }
          close();
        },
        // closeListener
        () => {
          // 20210623, jhkim : 확인 버튼을 선택하고 다른 동작이 실행되기 전에 엔터키를 눌러서 팝업이 다시 열리는 것을 방지
          setTimeout(() => {
            __disableKey = false;
          }, 500);

          resolve(actionType);
          resolve = reject = null;
        }
      )
    );
  });
}

export function openHttpRejectionPopupWithPromise(error) {
  console.error(error);
  if (axios.isCancel(error)) {
    return Promise.resolve(POPUP_ACTION_CANCEL);
  }

  if (
    !!error &&
    typeof error === "object" &&
    error.hasOwnProperty("isDisableAlert") &&
    error.isDisableAlert === true
  ) {
    return Promise.resolve(POPUP_ACTION_CANCEL);
  }

  let title = DEFAULT_POPUP_TITLE;
  let message = DEFAULT_POPUP_MESSAGE;
  // let isErrorServer = false;

  if (!!error) {
    // https://github.com/axios/axios
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // message = `${error.response.data.desc}\n(${error.response.data.code})`;0
      const { code , desc } = error.response.data;
      if (code === "40099004") {
        message = "1원 단위는 입력할 수 없습니다.";
      } else if(code === "40099007"){
        message = "입력하신 정보와 일치하는 유저 검색 결과가 없습니다."
      }else message = `일시적으로 서비스 이용이\n원활하지 않습니다\n(${desc ?? code})`;
      // isErrorServer = true;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else if (error.desc) {
      // Something happened in setting up the request that triggered an Error
      message = error.desc;
    } else if (typeof error === "string") {
      message = error;
    } else {
      // error.desc === undefined (알수 없는 오류일 경우)
      message = DEFAULT_UNDEFINED_POPUP_MESSAGE;
    }
  }

  // if (isErrorServer) {
  //   return openConfirmPopupWithPromise(title, message, "재시도", "닫기").then(
  //     (actionType) => {
  //       if (actionType !== POPUP_ACTION_OK) return;
  //       window.location.reload();
  //     }
  //   );
  // } else {
  //   return openAlertPopupWithPromise(title, message);
  // }

  return openAlertPopupWithPromise(title, message, "닫기");
}

//채널아웃 확인 openAlert
export function openAlertPopupChannelWithPromise(
  model,
  message,
  lblBtnOK,
  isHiddenClose,
  onTabChange
) {
  let title, description, lblOK;
  if (model !== null && typeof model === "object") {
    lblOK = model.button;
    description = model.desc;
    title = model.title;
  } else {
    lblOK = lblBtnOK;
    description = message;
    title = model;
  }

  // 예외처리
  if (!!description && typeof description === "object") {
    return Promise.resolve(POPUP_ACTION_CANCEL);
  }

  return new Promise(function (resolve, reject) {
    if (!__store) return reject();
    let actionType = POPUP_ACTION_CANCEL;
    __store.dispatch(
      openAlert(
        // startedListener
        (clickListener, key) => {
          return {
            maxWidth: "xs",
            title: title || DEFAULT_POPUP_TITLE,
            description: description,
            isHiddenClose: !!isHiddenClose,
            actions: [
              {
                label: lblOK || "확인",
                dataType: "YES",
                onClick: clickListener,
              },
              {
                label: "설정하기",
                dataType: "CHANNEL",
                onClick: clickListener,
              },
            ],
          };
        },
        // clickListener
        (event, close) => {
          if (!close) return;
          if (event.currentTarget.getAttribute("data-type") === "YES") {
            actionType = POPUP_ACTION_OK;
          }
          if (event.currentTarget.getAttribute("data-type") === "CHANNEL") {
            actionType = "CHANNEL";
          }

          close();
        },
        // closeListener
        () => {
          resolve(actionType);
          resolve = reject = null;
          if (actionType === "CHANNEL") {
            onTabChange(null, 3); //탭 전환 - 연동 채널
          }
        }
      )
    );
  });
}

export function openAlertPopupWithPromise(
  model,
  message,
  lblBtnOK,
  isHiddenClose
) {
  let title, description, lblOK;
  if (model !== null && typeof model === "object") {
    lblOK = model.button;
    description = model.desc;
    title = model.title;
  } else {
    lblOK = lblBtnOK;
    description = message;
    title = model;
  }

  // 예외처리
  if (!!description && typeof description === "object") {
    return Promise.resolve(POPUP_ACTION_CANCEL);
  }

  return new Promise(function (resolve, reject) {
    if (!__store) return reject();
    let actionType = POPUP_ACTION_CANCEL;
    __store.dispatch(
      openAlert(
        // startedListener
        (clickListener, key) => {
          return {
            maxWidth: "xs",
            title: title || DEFAULT_POPUP_TITLE,
            description: description || DEFAULT_UNDEFINED_POPUP_MESSAGE,
            isHiddenClose: !!isHiddenClose,
            actions: [
              {
                label: lblOK || "확인",
                dataType: "YES",
                onClick: clickListener,
              },
            ],
          };
        },
        // clickListener
        (event, close) => {
          if (!close) return;
          if (event.currentTarget.getAttribute("data-type") === "YES") {
            actionType = POPUP_ACTION_OK;
          }
          close();
        },
        // closeListener
        () => {
          resolve(actionType);
          resolve = reject = null;
        }
      )
    );
  });
}

export function openNoticePopupWithPromise(model, handleStartedListener) {
  let title, description;
  if (!!model && typeof model === "object") {
    if (model.hasOwnProperty("title")) title = model.title;
    if (model.hasOwnProperty("description")) description = model.description;
  }

  // 출력 할 내용이 없어 리젝 처리 함
  if (!description) {
    return Promise.resolve(POPUP_ACTION_CANCEL);
  }

  return new Promise(function (resolve, reject) {
    if (!__store) return reject();
    let actionType = POPUP_ACTION_CANCEL;
    let data = {};
    let popupKey;
    __store.dispatch(
      openNotice(
        (clickListener, key) => {
          popupKey = key;
          !!handleStartedListener && handleStartedListener(key);
          handleStartedListener = null;
          // startedListener
          return {
            title: title || DEFAULT_POPUP_TITLE,
            description: description,
            onDataWatcher: (nowData) => {
              data = nowData;
            },
            actions: [
              {
                label: "닫기",
                dataType: "YES",
                onClick: clickListener,
              },
            ],
          };
        },
        // clickListener
        (event, close) => {
          if (!close) return;
          if (event.currentTarget.getAttribute("data-type") === "YES") {
            actionType = POPUP_ACTION_OK;
          }
          close();
        },
        // closeListener
        () => {
          resolve({ actionType, data, popupKey });
          resolve = reject = data = null;
        }
      )
    );
  });
}

export function closeNoticePopup(popupKey) {
  !!__store && __store.dispatch(closeNotice(popupKey));
}

// Browser Event를 Blocking 처리 합니다
function blockEvent(evt) {
  console.log(TAG, `Blocking ${evt.type}`);
  utils.preventDefault(evt);
  utils.stopPropagation(evt);
}
