/**
 * 예약내역, 정산내역 등에서 사용하는 매출을 계산합니다.
 *
 * @module utils/salesCalculator
 */

/**
 * makeSummaryData에서 호출하는 메서드입니다.
 * orderSummary를 받아 판매수, 매출 , 정산금액을 반환해줍니다.
 * 숙박/대실이 아닌 전체로 계산할때는 해당 메서드를 두번 호출해야합니다.
 *
 * @param {Object} summary - API 연동규격서의 OrderSummary 객체
 * @return {Object} reservationObj
 */

export function calculateSales(summary) {
  const reservationObj = {
    item_type: summary.item_type || "", // 상품 유형 (대실/숙박)
    total_count: summary.total_count || 0, // 판매 건수
    cancel_count: summary.cancel_count || 0, // 취소 건수
    total_price: summary.total_price || 0, // 전체 결제 금액(결제가)
    cancel_price: summary.cancel_price || 0, // 예약 취소 금액 (결제가)
    adjustment_finished_price: summary.adjustment_finished_price || 0, // 정산 완료 금액(결제가)
    adjujstment_ready_price: summary.adjujstment_ready_price || 0, // 정산 대기 금액(결제가)
    preservation_finished_price: summary.preservation_finished_price || 0, // 보전 완료 금액
    preservation_ready_price: summary.preservation_ready_price || 0, // 보전 대기 금액
    adjujstment_fee_ready_price: summary.adjujstment_fee_ready_price || 0, // 정산 대기 수수료 금액
    adjujstment_fee_finished_price: summary.adjujstment_fee_finished_price || 0, // 정산 완료 수수쇼 금액
    site_sale_price: summary.site_sale_price || 0, // 현장 결제 매출 금액
    discount_price: summary.discount_price || 0, // 총 판매가

    // 매출 = 전체 결제액  + 보전 대기 금액 + 보전 완료 금액
    _sales_price:
      summary.total_price +
      summary.preservation_ready_price +
      summary.preservation_finished_price +
      summary.support_ready_price +
      summary.support_finished_price,

    // 정산금액 = 정산 완료 + 정산 대기 + 보전 완료 + 보전 대기 - 수수료 완료 - 수수료 대기
    _settlement_price:
      summary.adjustment_finished_price +
      summary.adjustment_ready_price +
      summary.preservation_finished_price +
      summary.preservation_ready_price -
      summary.adjustment_fee_ready_price -
      summary.adjustment_fee_finished_price,

    //정산대기금액 = 정산대기 + 보전대기 - 수수료 대기(PG) - 수수료(꿀스테이) + 본사이벤트 대기 - 정산대기 수수료(외부 채널 연동)
    _settlement_ready_price:
      summary.adjustment_ready_price +
      summary.preservation_ready_price -
      summary.adjustment_fee_ready_price -
      summary.coolstay_fee_ready_price +
      summary.support_ready_price -
      summary.interlock_fee_ready_price,

    //정산완료금액 = 정산완료 + 보전완료 - 수수료 완료(PG) - 수수료(꿀스테이) + 본사이벤트 완료 - 정산완료 수수료(외부 채널 연동)
    _settlement_finished_price:
      summary.adjustment_finished_price +
      summary.preservation_finished_price -
      summary.adjustment_fee_finished_price -
      summary.coolstay_fee_finished_price +
      summary.support_finished_price -
      summary.interlock_fee_finished_price,
  };

  return reservationObj;
}

export function parsedSummary(summary) {
  const reservationObj = {
    item_type: summary.itemType ?? "", // 상품 유형 (대실/숙박)
    total_count: summary.totalCount ?? 0, // 판매 건수
    cancel_count: summary.cancelCount ?? 0, // 취소 건수
    total_price: summary.totalPrice ?? 0, // 전체 결제 금액(결제가)
    // cancel_price: summary.cancel_price || 0, // 예약 취소 금액 (결제가)
    adjustment_finished_price: summary.adjustmentFinishedPrice ?? 0, // 정산 완료 금액(결제가)
    adjujstment_ready_price: summary.adjustmentReadyPrice ?? 0, // 정산 대기 금액(결제가)
    // preservation_finished_price: summary.preservation_finished_price || 0, // 보전 완료 금액
    // preservation_ready_price: summary.preservation_ready_price || 0, // 보전 대기 금액
    // adjujstment_fee_ready_price: summary.adjujstment_fee_ready_price || 0, // 정산 대기 수수료 금액
    // adjujstment_fee_finished_price: summary.adjujstment_fee_finished_price || 0, // 정산 완료 수수쇼 금액
    site_sale_price: summary.siteSalePrice ?? 0, // 현장 결제 매출 금액
    // discount_price: summary.discount_price || 0, // 총 판매가

    // // 매출 = 전체 결제액  + 보전 대기 금액 + 보전 완료 금액
    // _sales_price:
    //     summary.total_price +
    //     summary.preservation_ready_price +
    //     summary.preservation_finished_price +
    //     summary.support_ready_price +
    //     summary.support_finished_price,
    //
    // // 정산금액 = 정산 완료 + 정산 대기 + 보전 완료 + 보전 대기 - 수수료 완료 - 수수료 대기
    // _settlement_price:
    //     summary.adjustment_finished_price +
    //     summary.adjustment_ready_price +
    //     summary.preservation_finished_price +
    //     summary.preservation_ready_price -
    //     summary.adjustment_fee_ready_price -
    //     summary.adjustment_fee_finished_price,
    //
    // //정산대기금액 = 정산대기 + 보전대기 - 수수료 대기(PG) - 수수료(꿀스테이) + 본사이벤트 대기 - 정산대기 수수료(외부 채널 연동)
    // _settlement_ready_price:
    //     summary.adjustment_ready_price +
    //     summary.preservation_ready_price -
    //     summary.adjustment_fee_ready_price -
    //     summary.coolstay_fee_ready_price +
    //     summary.support_ready_price -
    //     summary.interlock_fee_ready_price,
    //
    // //정산완료금액 = 정산완료 + 보전완료 - 수수료 완료(PG) - 수수료(꿀스테이) + 본사이벤트 완료 - 정산완료 수수료(외부 채널 연동)
    // _settlement_finished_price:
    //     summary.adjustment_finished_price +
    //     summary.preservation_finished_price -
    //     summary.adjustment_fee_finished_price -
    //     summary.coolstay_fee_finished_price +
    //     summary.support_finished_price -
    //     summary.interlock_fee_finished_price,
  };

  return reservationObj;
}
