/**
 * Authentication Redux 기능을 제공하는 모듈 입니다
 *
 * @author Taesung Park <pts@pineone.com>
 * @module system/redux-ducks/authentication
 */

import * as serviceAPI from "lib/service-api";
import * as serviceStorage from "lib/service-storage";
import * as serviceBrowser from "lib/service-browser";
import * as serviceNotice from "lib/service-notice";
import * as serviceScheduler from "lib/service-scheduler";
import * as serviceRefresh from "lib/service-refresh";
import {getLogoutFailure, getLogoutRequest} from "./actions/authenticationAction";
import {postAuthRefresh, postRefreshToken} from "lib/service-api";
import {removeIdentity, resetTempData} from "lib/service-storage";
import {getCookie} from "../../utils";
import {history} from "../../lib/service-history-browser";


const KEY_IDENTITY = "_1";
const KEY_ACCESS_TOKEN = "_5";
const KEY_REFRESH_TOKEN = "_6";
const COOKIE_NAME_AUTO_LOGIN = "auto_login_yn";

const TAG = "[system/redux-ducks/authentication.js]";
// console.log(TAG, "Create");

/*#############################################################
 *  Actions
 *###########################################################*/

/** 로그인 시작 시 발생 되는 Action Type 입니다 */
export const AUTH_LOGIN = "@authentication/AUTH_LOGIN";

/** 로그인 성공 시 발생 되는 Action Type 입니다 */
export const AUTH_LOGIN_SUCCESS = "@authentication/AUTH_LOGIN_SUCCESS";

/** 로그인 실패 시 발생 되는 Action Type 입니다 */
export const AUTH_LOGIN_FAILURE = "@authentication/AUTH_LOGIN_FAILURE";

/** 사용자 인증 시작 시 발생 되는 Action Type 입니다 */
export const AUTH_GET_STATUS = "@authentication/AUTH_GET_STATUS";

/** 사용자 인증 성공 시 발생 되는 Action Type 입니다 */
export const AUTH_GET_STATUS_SUCCESS =
  "@authentication/AUTH_GET_STATUS_SUCCESS";

/** 사용자 인증 실패 시 발생 되는 Action Type 입니다 */
export const AUTH_GET_STATUS_FAILURE =
  "@authentication/AUTH_GET_STATUS_FAILURE";

/** 로그아웃 시 발생 되는 Action Type 입니다 */
export const AUTH_LOGOUT = "@authentication/AUTH_LOGOUT";


// 임시토큰 발급 action
export const GET_TEMP_TOKEN_REQUEST = '@authentication/GET_TEMP_TOKEN_REQUEST';
export const GET_TEMP_TOKEN_SUCCESS = '@authentication/GET_TEMP_TOKEN_SUCCESS';
export const GET_TEMP_TOKEN_FAILURE = '@authentication/GET_TEMP_TOKEN_FAILURE';


/*#############################################################
 *  Action Creator
 *###########################################################*/

/**
 * 로그인 요청을 수행하는 Action 함수 입니다
 *
 * @param {object} params - 로그인 정보
 * @param {boolean} isAutoLogin - 자동 로그인 여부
 * @returns {Promise} Promise 객체
 */
export function loginRequest(params) {

  const {token,
    data,
    isAutoLogin,
    isSaveID ,} = params;

  console.log(
    TAG,
    `Called loginRequest(userId=${data.id}, userPassword=${data.rsaEncPwd}, isAutoLogin=${isAutoLogin})`
  );

  // 로그인 시도를 너무 빠르게 하면 브라우저 라우팅이 막힌다
  serviceBrowser.reset();

  // AppContainer 로그인 상태 변경에 따라 자동 라우팅이 실행 되므로
  // loginRequest를 호출 한 코드에서 라우팅을 제어 할 수 있도록 처리 함
  serviceBrowser.disableBackNavigation();

  return (dispatch) => {
    dispatch(login());

    // [서버연동] 박태성C - 사용자세션토큰발급. POST /sessions/users
    return serviceAPI
      .postLogin(params)
      .then((response) => {
        serviceStorage.clearIdentity();
        isSaveID && serviceStorage.setTempUserId(data.id);
        !isSaveID && serviceStorage.removeTempUserId();
        // 브라우저 저장소에 사용자 식별 정보를 저장 한다
        return serviceStorage.setIdentity(response?.data, isAutoLogin);
      })
      .then((identity) => {
        dispatch(loginSuccess());
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(loginFailure());
        return Promise.reject(error);
      })
      .finally(() => {
        resetTempData();
        serviceBrowser.enableBackNavigation();
      });
  };
}

/**
 * 로그인 요청을 수행하는 Action 함수 입니다
 *
 * @param {object} params - 로그인 정보
 * @returns {Promise} Promise 객체
 */
export function loginRequestByToken(identity) {
  serviceBrowser.disableBackNavigation();

  return (dispatch) => {
    dispatch(login());

    // 서버 통신을 할 수 있도록 임시로 토큰 설정
    serviceStorage.getTempData().tmpAccessToken = serviceStorage.getAccessToken(
      identity
    );

    // serviceStorage.resetTempData();

    return serviceAPI
      .postAuthRefresh()
      .then((response) => {
        if(response?.data?.result !== undefined) {
          serviceStorage.clearIdentity();

          let id = JSON.parse(window.localStorage.getItem(KEY_IDENTITY));
          const data = response.data;

          data['result']['accessToken'] =  { token: id[KEY_ACCESS_TOKEN]};
          data['result']['refreshToken'] = { token : id[KEY_REFRESH_TOKEN]};
          const value =  getCookie(COOKIE_NAME_AUTO_LOGIN)
          return serviceStorage.setIdentity(data, value === 'Y');
        } else {
          return Promise.reject({isDisableAlert : true});
        }
      })
      .then((identity) => {
        dispatch(loginSuccess());
        return Promise.resolve();
      })
      .catch((error) => {
        dispatch(loginFailure());
        return Promise.reject(error);
      })
      .finally(() => {
        serviceStorage.resetTempData();
        serviceBrowser.enableBackNavigation();
      });
  };
}

/**
 * AUTH_LOGIN 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} AUTH_LOGIN 타입을 정의한 객체
 */
export function login() {
  console.log(TAG, `Called login()`);

  return {
    type: AUTH_LOGIN,
  };
}

/**
 * AUTH_LOGIN_SUCCESS 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} AUTH_LOGIN_SUCCESS 타입을 정의한 객체
 */
export function loginSuccess() {
  console.log(TAG, `Called loginSuccess()`);

  return {
    type: AUTH_LOGIN_SUCCESS,
  };
}

/**
 * AUTH_LOGIN_FAILURE 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} AUTH_LOGIN_FAILURE 타입을 정의한 객체
 */
export function loginFailure() {
  console.log(TAG, `Called loginFailure()`);

  return {
    type: AUTH_LOGIN_FAILURE,
  };
}

/**
 * 사용자 인증 요청을 수행하는 Action 함수 입니다
 *
 * @returns {Promise} Promise 객체
 */
export function getStatusRequest() {
  console.log(TAG, `Called getStatusRequest()`);

  // 쿠키에 저장 된 자동로그인 여부에 따라 사용자 Identity를 삭제 함 (PC/모바일에 따라 다르게 처리 될 수 있음)
  serviceStorage.removeIdentityByCookie();

  return (dispatch) => {
    dispatch(getStatus());

    let identity = serviceStorage.getIdentity();
    const accessToken = serviceStorage.getAccessToken(identity); // 서버 연동 시 header에 필수 포함
    const refreshToken = serviceStorage.getRefreshToken(identity); // 토큰 갱신 시 필요
    const motelKey = serviceStorage.getConnectedMotelKey(); // 모텔 접근 시 필요


    if (!accessToken || !refreshToken || !motelKey) {
      console.log(
        TAG,
        `저장 된 사용자 식별 정보(${accessToken}, ${refreshToken})나 제휴점 키(${motelKey})가 없음`
      );
      return Promise.resolve().then(() => dispatch(getStatusFailure()));
    }

    serviceStorage.getTempData().tmpAccessToken = serviceStorage.getAccessToken(
        identity
    );
    // serviceStorage.clearIdentity();
    // identity = null;

    // [서버연동] 박태성C - 사용자세션토큰 갱신. POST /sessions/users/refresh


    return serviceAPI
      .postAuthRefresh()
      .then((response) => {
        // 브라우저 저장소에 사용자 식별 정보를 저장 한다
        let id = JSON.parse(window.sessionStorage.getItem(KEY_IDENTITY));
        const data = response?.data;

        data['result']['accessToken'] =  { token: id[KEY_ACCESS_TOKEN]};
        data['result']['refreshToken'] = { token : id[KEY_REFRESH_TOKEN]};
        const value =  getCookie(COOKIE_NAME_AUTO_LOGIN)

        return serviceStorage.setIdentity(data, value === 'Y');
      })
      .then(() => {
        // 모텔의 상세 정보를 조회 한다
        if(motelKey){
          return serviceStorage.connectMotelWithPromise(motelKey);
        } else {
          return Promise.resolve();
        }

      })
      .then(() => {
        dispatch(getStatusSuccess());
        return Promise.resolve();
      })
      .catch((error) => {
        // 실패 (ex : 네트워크 단절, WAS 에러 처리 등) 시
        console.log(TAG, "\n", error);
        serviceStorage.removeIdentity();
        dispatch(getStatusFailure());
        return Promise.reject(error);
      });
  };
}

/**
 * AUTH_GET_STATUS 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} AUTH_GET_STATUS 타입을 정의한 객체
 */
export function getStatus() {
  console.log(TAG, `Called getStatus()`);

  return {
    type: AUTH_GET_STATUS,
  };
}

/**
 * AUTH_GET_STATUS_SUCCESS 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} AUTH_GET_STATUS_SUCCESS 타입을 정의한 객체
 */
export function getStatusSuccess() {
  console.log(TAG, `Called getStatusSuccess()`);

  return {
    type: AUTH_GET_STATUS_SUCCESS,
  };
}

/**
 * AUTH_GET_STATUS_FAILURE 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} AUTH_GET_STATUS_FAILURE 타입을 정의한 객체
 */
export function getStatusFailure() {
  console.log(TAG, `Called getStatusFailure()`);

  return {
    type: AUTH_GET_STATUS_FAILURE,
  };
}

/**
 * 로그아웃 요청을 수행하는 Action 함수 입니다
 *
 * @returns {Promise} Promise 객체
 */
export function logoutRequest() {

  return (dispatch) => {
    console.log(TAG, `Called logoutRequest()`);
    dispatch(getLogoutRequest());


    let identity = serviceStorage.getIdentity();

    const accessToken = serviceStorage.getAccessToken(identity); // 서버 연동 시 header에 필수 포함
    const refreshToken = serviceStorage.getRefreshToken(identity);

    // 토큰 갱신 시 필요
    if(!accessToken || !refreshToken) {
      serviceBrowser.reset();

      serviceNotice.stopService(); // 공지 팝업 서비스 중지
      serviceScheduler.stopService(); // 스케쥴러 서비스 중지
      serviceRefresh.stopService(); // 갱신 서비스 중지
      dispatch(logout())
      history.push("/login");
      return Promise.resolve();

    }

    const data = {
      accessToken: accessToken,
      refreshToken: refreshToken
    }


    return serviceAPI
        .postLogout(data)
        .then(() => {
          serviceStorage.removeIdentity();

          serviceBrowser.reset();

          serviceNotice.stopService(); // 공지 팝업 서비스 중지
          serviceScheduler.stopService(); // 스케쥴러 서비스 중지
          serviceRefresh.stopService(); // 갱신 서비스 중지
          dispatch(logout())

          return  Promise.resolve();

        })
        .catch(error => {
          dispatch(getLogoutFailure(error))
          return Promise.reject(error);
        });
  };
}

/**
 * AUTH_LOGOUT 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} AUTH_LOGOUT 타입을 정의한 객체
 */
export function logout() {
  console.log(TAG, `Called logout()`);

  return {
    type: AUTH_LOGOUT,
  };
}


/**
 * TEMP_TOKEN 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} TEMP_TOKEN 타입을 정의한 객체
 */
export const getTempTokenRequest = () => ({
  type: GET_TEMP_TOKEN_REQUEST,
});
export const getTempTokenSuccess = (tempToken) => ({
  type: GET_TEMP_TOKEN_SUCCESS,
  tempToken,
});

export const getTempTokenFailure = (error) => ({
  type: GET_TEMP_TOKEN_FAILURE, error
});



/**
 * TEMP_TOKEN 타입의 액션을 생성 (Action Creator) 하는 함수 입니다
 *
 * @returns {object} TEMP_TOKEN  타입을 정의한 객체
 */
export const postTempToken = (params) => {
  return (dispatch) => {
    dispatch(getTempTokenRequest());

    return serviceAPI.postTempToken(params)
        .then(response => {
          return response?.data?.result;
        })
        .then(data => dispatch(getTempTokenSuccess(data?.token?.token)))
        .catch(error => dispatch(getTempTokenFailure(error)));
  };
};


/*#############################################################
 *  Reducer
 *###########################################################*/

const initialState = {
  login: {
    status: "INIT",
  },
  // error: {
  //   errorType: null,
  // },
  status: {
    isLoggedIn: false,
    // valid: false, undefined 여부로 화면 비노출을 처리하고 있음
  },
  tempToken:  null
};

export default function reducer(state = initialState, action = {}) {

  switch (action.type) {
      // LOGIN
    case AUTH_LOGIN:
      return {
        ...state,
        login: {
          status: "WAITING",
        },
      };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        login: {
          status: "SUCCESS",
        },
        status: {
          ...state.status,
          isLoggedIn: true,
          valid: true,
        },
      };
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        login: {
          status: "FAILURE",
        },
      };
      // CHECK SESSIONS
    case AUTH_GET_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          isLoggedIn: true,
        },
      };
    case AUTH_GET_STATUS_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
          valid: true,
        },
      };
    case AUTH_GET_STATUS_FAILURE:
      return {
        ...state,
        status: {
          ...state.status,
          valid: false,
          isLoggedIn: false,
        },
      };
      // LOGOUT
    case AUTH_LOGOUT:
      return {
        ...state,
        status: {
          ...state.status,
          isLoggedIn: false,
        },
      };
    case GET_TEMP_TOKEN_REQUEST:
      return {
        ...state,
        status: {
          ...state.status,
        }
      };
    case GET_TEMP_TOKEN_SUCCESS:
      return {
        ...state,
        status: {
          ...state.status,
        },
        tempToken: action.tempToken
      };
    case GET_TEMP_TOKEN_FAILURE:
      return  {
        ...state,
        status: {
          ...state.status,
        },
        tempToken: null
      };
    default:
      return state;
  }
}
