/**
 * WAS와 연동 할 수 있도록 기능을 제공하는 Service 모듈 입니다<p/>
 *
 * @module lib/service-api
 * @see module:lib/provider-api
 * @see module:lib/service-form-data-transform
 */

import axios from "axios";
import * as configApp from "config/config-app";
import { formDataTransform } from "lib/service-form-data-transform";

const ALIAS_SVR = configApp.ALIAS_SVR;
const TIMEOUT_FILE_UPLOAD = configApp.TIMEOUT_FILE_UPLOAD;

// const TAG = "[lib/service-api.js]";
// console.log(TAG, "Create");

/**
* multipart/form-data 시뮬레이션  API<p/>
*
* multipart/form-data 전송을 시뮬레이션 할 수 있도록<br/>
* 지원하는 테스트 API 입니다.<p/>
*
* <h3>주의 사항</h3>
* WAS에서 정식으로 제공하는 API가 아닙니다.<br/>
*
* config-app.js의 URL_WAS를 시뮬레이션 용 프록시 서버 주소로 변경하고<br/>
* 프록시 서버를 구동 하세요.
*
* @param {Object} params Request Parameter
* @returns {Promise} Axio에서 제공하는 Promise 객체
* @see module:config/config-app
* @example
    serviceAPI.TEST_multipart(
      {
        ... JSON 형식의 전송 Palyload ...
      }
    );
*/
export function TEST_multipart(params) {
  return axios({
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/test/multipart",
    data: params,
    headers: {
      "Content-Type": undefined,
    },
    timeout: TIMEOUT_FILE_UPLOAD,
    transformRequest: [formDataTransform],
  });
}

/*##################################################################################
API 목록
##################################################################################*/

/**
 * GET /regions - 지역정보 목록을 조회하는 API
 *
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getItemsBoards();
 */

export function getRegions(isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/regions",
    params: {},
  });
}

/**
 * GET /regions - 지역 별 모텔 목록을 조회하는 API
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getMotelsRegions({
 *      "region_code": "string",  // 필수. 지역코드
 *    });
 */

export function getMotelsRegions(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/regions/motels",
    params: params,
  });
}

/**
 * POST /authcode/send, authcode/send-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createSendAuthcode({
 *      "phone_number": "string",
 *    });
 */

export function createSendAuthcode(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/authcode/send",
    data: params,
  });
}

/**
 * POST /authcode/verify, authcode/verify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createVerifyAuthcode({
 *      "phone_number": "string",
 *      "sms_auth_code": "string",
 *      "sms_auth_key": "string",
 *    });
 */

export function createVerifyAuthcode(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/authcode/verify",
    data: params,
  });
}

/**
 * GET /boards/items, boards/items-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getItemsBoards({
 *      "board_type": "string",  //필수
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",  //필수
 *      "sort": "string",
 *    });
 */

export function getItemsBoards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/boards/items",
    params: params,
  });
}

/**
 * POST /boards/items, boards/items-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createItemsBoards({
 *      "board_type": "string",
 *      "description": "string",
 *      "image_keys": [],
 *      "motel_key": 999,
 *      "option_value": "string",
 *      "title": "string",
 *    });
 */

export function createItemsBoards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/boards/items",
    data: params,
  });
}

/**
 * POST /boards/items/delete, boards/items/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteItemsBoards({
 *      "board_item_key": 999,
 *    });
 */

export function createDeleteItemsBoards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/boards/items/delete",
    data: params,
  });
}

/**
 * POST /boards/items/modify, boards/items/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyItemsBoards({
 *      "board_item_key": 999,
 *      "board_type": "string",
 *      "description": "string",
 *      "image_keys": [],
 *      "motel_key": 999,
 *      "option_value": "string",
 *      "title": "string",
 *    });
 */

export function createModifyItemsBoards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/boards/items/modify",
    data: params,
  });
}

/**
 * GET /coupons, coupons-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getCoupons({
 *      "motel_key": "string",  //필수
 *    });
 */

export function getCoupons(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/coupons",
    params: params,
  });
}

/**
 * POST /coupons, coupons-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createCoupons({
 *      "coupon": [],
 *      "coupon_template": "string",
 *      "coupons": [],
 *      "everyone_yn": "string",
 *      "motel_key": 999,
 *      "users": [],
 *    });
 */

export function createCoupons(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/coupons",
    data: params,
  });
}

/**
 * POST /files/upload, files/upload-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createUploadFiles({
 *      "files": [],  //필수
 *    });
 */

export function createUploadFiles(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/files/upload",
    data: params,
    headers: { "Content-Type": undefined },
    timeout: TIMEOUT_FILE_UPLOAD,
    transformRequest: [formDataTransform],
  });
}

/**
 * GET /holiday, holiday-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getHoliday({
 *      "search_extra": "string",
 *      "search_type": "string",
 *    });
 */

export function getHoliday(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/holiday",
    params: params,
  });
}

/**
 * GET /motels/admins, motels/admins-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getAdminsMotels({
 *      "motel_key": "string",
 *    });
 */

export function getAdminsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/admins",
    params: params,
  });
}

/**
 * POST /motels/admins, motels/admins-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createAdminsMotels({
 *      "motel_key": 999,
 *      "user_id": "string",
 *    });
 */

export function createAdminsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/admins",
    data: params,
  });
}

/**
 * POST /motels/admins/delete, motels/admins/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteAdminsMotels({
 *      "admin_key": 999,
 *      "motel_key": 999,
 *    });
 */

export function createDeleteAdminsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/admins/delete",
    data: params,
  });
}

/**
 * GET /motels/alarms/message, motels/alarms/message-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getMessageAlarmsMotels({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",
 *    });
 */

export function getMessageAlarmsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/alarms/message",
    params: params,
  });
}

/**
 * POST /motels/alarms/message/delete, motels/alarms/message/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteMessageAlarmsMotels({
 *      "alarm_key": 999,
 *      "delete_all_yn": "string",
 *      "motel_key": 999,
 *    });
 */

export function createDeleteMessageAlarmsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/alarms/message/delete",
    data: params,
  });
}

/**
 * GET /motels/alarms/setting, motels/alarms/setting-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getSettingAlarmsMotels({
 *      "motel_key": "string",  //필수
 *    });
 */

export function getSettingAlarmsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/alarms/setting",
    params: params,
  });
}

/**
 * POST /motels/alarms/setting, motels/alarms/setting-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createSettingAlarmsMotels({
 *      "alarm_book_yn": "string",
 *      "alarm_notice_yn": "string",
 *      "alarm_reply_yn": "string",
 *      "alarm_review_yn": "string",
 *      "allday_yn": "string",
 *      "end_time": "string",
 *      "motel_key": 999,
 *      "name": "string",
 *      "phone_number": "string",
 *      "start_time": "string",
 *    });
 */

export function createSettingAlarmsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/alarms/setting",
    data: params,
  });
}

/**
 * POST /motels/alarms/setting/delete, motels/alarms/setting/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteSettingAlarmsMotels({
 *      "motel_key": 999,
 *      "phone_number": "string",
 *    });
 */

export function createDeleteSettingAlarmsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/alarms/setting/delete",
    data: params,
  });
}

/**
 * POST /motels/alarms/setting/modify, motels/alarms/setting/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifySettingAlarmsMotels({
 *      "motel_alarms": [],
 *      "motel_key": 999,
 *    });
 */

export function createModifySettingAlarmsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/alarms/setting/modify",
    data: params,
  });
}

/**
 * GET /motels/boards/items, motels/boards/items-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getItemsBoardsMotels({
 *      "board_type": "string",
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",
 *      "sort": "string",
 *    });
 */

export function getItemsBoardsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/boards/items",
    params: params,
  });
}

/**
 * POST /motels/boards/items, motels/boards/items-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createItemsBoardsMotels({
 *      "board_type": "string",
 *      "day_codes": [],
 *      "description": "string",
 *      "end_dt": type is undefined,
 *      "motel_key": 999,
 *      "option_value": "string",
 *      "priority": 999,
 *      "start_dt": type is undefined,
 *      "title": "string",
 *      "visible_yn": "string",
 *    });
 */

export function createItemsBoardsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/boards/items",
    data: params,
  });
}

/**
 * POST /motels/boards/items/delete, motels/boards/items/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteItemsBoardsMotels({
 *      "board_item_key": 999,
 *    });
 */

export function createDeleteItemsBoardsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/boards/items/delete",
    data: params,
  });
}

/**
 * POST /motels/boards/items/modify, motels/boards/items/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyItemsBoardsMotels({
 *      "board_item_key": "string",
 *      "board_type": "string",
 *      "day_codes": [],
 *      "description": "string",
 *      "end_dt": type is undefined,
 *      "motel_key": 999,
 *      "option_value": "string",
 *      "priority": 999,
 *      "start_dt": type is undefined,
 *      "title": "string",
 *      "visible_yn": "string",
 *    });
 */

export function createModifyItemsBoardsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/boards/items/modify",
    data: params,
  });
}

/**
 * GET /motels/books, motels/books-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getBooksMotels({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",
 *      "sort": "string",
 *    });
 */

export function getBooksMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/books",
    params: params,
  });
}

/**
 * GET /pms/books, motels/books-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axios에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getPMSBooksMotels({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",
 *      "sort": "string",
 *    });
 */

export function getPMSBooksMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/pms/book",
    data: params,
  });
}

/**
 * GET /pms/books/detail, motels/books-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axios에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getPMSBooksMotelsDetail({
 *      "storeKey": "string",
 *      "orderKey": "string",
 *    });
 */

export function getPMSBooksMotelsDetail(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/pms/book/detail",
    params: params,
  });
}

/**
 * POST /motels/books/modify, motels/books/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyBooksMotels({
 *      "book_id": "string",
 *      "status": "string",
 *    });
 */

export function createModifyBooksMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/books/modify",
    data: params,
  });
}

/**
 * GET /motels/charges, motels/charges-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getChargesMotels({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",
 *      "sort": "string",
 *    });
 */

export function getChargesMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/charges",
    params: params,
  });
}

/**
 * POST /motels/charges, motels/charges-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createChargesMotels({
 *      "charge": type is undefined,
 *      "motel_key": 999,
 *    });
 */

export function createChargesMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/charges",
    data: params,
  });
}

/**
 * POST /motels/charges/delete, motels/charges/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteChargesMotels({
 *      "charge_key": 999,
 *      "motel_key": 999,
 *    });
 */

export function createDeleteChargesMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/charges/delete",
    data: params,
  });
}

/**
 * POST /motels/charges/modify, motels/charges/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyChargesMotels({
 *      "charge": type is undefined,
 *      "motel_key": 999,
 *    });
 */

export function createModifyChargesMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/charges/modify",
    data: params,
  });
}

/**
 * GET /motels/detail, motels/detail-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getDetailMotels({
 *      "motel_key": "string",
 *    });
 */

export function getDetailMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/detail",
    params: params,
  });
}

/**
 * GET /motels/images, motels/images-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getImagesMotels({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",
 *      "sort": "string",
 *    });
 */

export function getImagesMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/images",
    params: params,
  });
}

/**
 * POST /motels/images, motels/images-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createImagesMotels({
 *      "category_code": "string",
 *      "image_f_keys": [],
 *      "motel_key": 999,
 *      "room_key": 999,
 *    });
 */

export function createImagesMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/images",
    data: params,
  });
}

/**
 * POST /motels/images/delete, motels/images/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteImagesMotels({
 *      "category_code": "string",
 *      "image_f_keys": [],
 *      "motel_key": 999,
 *      "room_key": 999,
 *    });
 */

export function createDeleteImagesMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/images/delete",
    data: params,
  });
}

/**
 * POST /motels/modify, motels/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyMotels({
 *      "cancel_policy": "string",
 *      "consecutive_yn": "string",
 *      "convenience_codes": [],
 *      "description": "string",
 *      "greeting_msg": "string",
 *      "installation_front": "string",
 *      "installation_inside": "string",
 *      "location_description": "string",
 *      "motel_key": 999,
 *      "parking_count": 999,
 *      "parking_description": "string",
 *      "parking_yn": "string",
 *    });
 */

export function createModifyMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/modify",
    data: params,
  });
}

/**
 * GET /motels/operationhours, motels/operationhours-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getOperationhoursMotels({
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",
 *    });
 */

export function getOperationhoursMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/operationhours",
    params: params,
  });
}

/**
 * POST /motels/operationhours/delete, motels/operationhours/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteOperationhoursMotels({
 *      "item_type": "string",
 *      "motel_key": 999,
 *    });
 */

export function createDeleteOperationhoursMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/operationhours/delete",
    data: params,
  });
}

/**
 * POST /motels/operationhours/modify, motels/operationhours/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyOperationhoursMotels({
 *      "motel_key": 999,
 *      "operation_hours": type is undefined,
 *    });
 */

export function createModifyOperationhoursMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/operationhours/modify",
    data: params,
  });
}

/**
 * GET /motels/reviews, motels/reviews-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getReviewsMotels({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",  //필수
 *      "search_extra": "string",
 *      "search_type": "string",
 *      "sort": "string",
 *    });
 */

export function getReviewsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/reviews",
    params: params,
  });
}

/**
 * POST /motels/reviews/delete, motels/reviews/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteReviewsMotels({
 *      "reply_key": 999,
 *      "review_key": 999,
 *    });
 */

export function createDeleteReviewsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/reviews/delete",
    data: params,
  });
}

/**
 * POST /motels/reviews/modify, motels/reviews/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyReviewsMotels({
 *      "comment": "string",
 *      "reply_key": 999,
 *      "review_key": 999,
 *    });
 */

export function createModifyReviewsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/reviews/modify",
    data: params,
  });
}

/**
 * POST /motels/reviews/status/modify, motels/reviews/status/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyStatusReviewsMotels({
 *      "best_yn": "string",
 *      "delete_yn": "string",
 *      "review_key": 999,
 *    });
 */

export function createModifyStatusReviewsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/reviews/status/modify",
    data: params,
  });
}

/**
 * GET /motels/rooms, motels/rooms-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getRoomsMotels({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",
 *      "sort": "string",
 *    });
 */

export function getRoomsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/rooms",
    params: params,
  });
}

/**
 * POST /motels/rooms, motels/rooms-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createRoomsMotels({
 *      "motel_key": 999,
 *      "room": type is undefined,
 *    });
 */

export function createRoomsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/rooms",
    data: params,
  });
}

/**
 * POST /motels/rooms/delete, motels/rooms/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteRoomsMotels({
 *      "motel_key": 999,
 *      "room_key": 999,
 *    });
 */

export function createDeleteRoomsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/rooms/delete",
    data: params,
  });
}

/**
 * POST /motels/rooms/modify, motels/rooms/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyRoomsMotels({
 *      "motel_key": 999,
 *      "rooms": [],
 *    });
 */

export function createModifyRoomsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/rooms/modify",
    data: params,
  });
}

/**
 * GET /motels/rooms/sales, motels/rooms/sales-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getSalesRoomsMotels({
 *      "item_type": "string",
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",  //필수
 *    });
 */

export function getSalesRoomsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/rooms/sales",
    params: params,
  });
}

export function getSalesRoomsMotelsCall(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/rooms/sales/call",
    params: params,
  });
}

/**
 * POST /motels/rooms/sales, motels/rooms/sales-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createSalesRoomsMotels({
 *      "daily_room_sales": [],
 *      "motel_key": 999,
 *    });
 */

export function createSalesRoomsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/rooms/sales",
    data: params,
  });
}

/**
 * POST /motels/sales, motels/sales-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createSalesMotels({
 *      "dates": [],
 *      "item_type": "string",
 *      "motel_key": 999,
 *      "sale_yn": "string",
 *    });
 */

export function createSalesMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/sales",
    data: params,
  });
}

/**
 * GET /motels/service, motels/service-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getServiceMotels({
 *      "motel_key": "string",  //필수
 *    });
 */

export function getServiceMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/service",
    params: params,
  });
}

/**
 * GET /motels/settings, motels/settings-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getSettingsMotels({
 *      "motel_key": "string",
 *    });
 */

export function getSettingsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/settings",
    params: params,
  });
}

/**
 * POST /motels/settings/modify, motels/settings/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifySettingsMotels({
 *      "close_yn": "string",
 *      "consecutive_yn": "string",
 *      "full_parking_yn": "string",
 *      "motel_key": 999,
 *      "point_rate": 999,
 *    });
 */

export function createModifySettingsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/settings/modify",
    data: params,
  });
}

/**
 * POST /payment/complete, payment/complete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createCompletePayment({
 *      "merchant_uid": "string",
 *      "payment_imp_uid": "string",
 *    });
 */

export function createCompletePayment(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/payment/complete",
    data: params,
  });
}

/**
 * POST /payment/ready, payment/ready-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createReadyPayment({
 *      "motel_key": 999,
 *      "payment_method": "string",
 *      "payment_pg": "string",
 *      "total_price": 999,
 *    });
 */

export function createReadyPayment(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/payment/ready",
    data: params,
  });
}

/**
 * GET /points/coupon, points/coupon-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getCouponPoints({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",
 *    });
 */

export function getCouponPoints(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/points/coupon",
    params: params,
  });
}

/**
 * GET /points/rate, points/rate-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getRatePoints({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",
 *      "sort": "string",
 *    });
 */

export function getRatePoints(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/points/rate",
    params: params,
  });
}

/**
 * GET /saleboards/items, saleboards/items-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getItemsSaleboards({
 *      "count": "string",
 *      "cursor": "string",
 *      "search_extra": "string",
 *      "search_type": "string",  //필수
 *      "sort": "string",
 *    });
 */

export function getItemsSaleboards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/saleboards/items",
    params: params,
  });
}

/**
 * POST /saleboards/items, saleboards/items-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createItemsSaleboards({
 *      "address": "string",
 *      "area1": "string",
 *      "area2": "string",
 *      "bank_loan": "string",
 *      "buyout": "string",
 *      "convenience_codes": [],
 *      "deposit": "string",
 *      "description": "string",
 *      "detail_address": "string",
 *      "feature_commercial_yn": "string",
 *      "feature_food_yn": "string",
 *      "feature_heating": "string",
 *      "feature_loan": "string",
 *      "feature_month_max_sales": "string",
 *      "feature_month_min_sales": "string",
 *      "feature_motel_yn": "string",
 *      "feature_park_yn": "string",
 *      "feature_room_count": "string",
 *      "feature_subway_yn": "string",
 *      "feature_university_yn": "string",
 *      "floor1": "string",
 *      "floor2": "string",
 *      "image_keys": [],
 *      "parking_count": "string",
 *      "phone_number": "string",
 *      "premium_price": "string",
 *      "price": "string",
 *      "profit_max_sales": "string",
 *      "profit_min_sales": "string",
 *      "rent_price": "string",
 *      "sale_yn": "string",
 *      "title": "string",
 *      "type": "string",
 *      "zipcode": "string",
 *    });
 */

export function createItemsSaleboards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/saleboards/items",
    data: params,
  });
}

/**
 * POST /saleboards/items/delete, saleboards/items/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteItemsSaleboards({
 *      "board_item_key": 999,
 *    });
 */

export function createDeleteItemsSaleboards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/saleboards/items/delete",
    data: params,
  });
}

/**
 * POST /saleboards/items/modify, saleboards/items/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyItemsSaleboards({
 *      "address": "string",
 *      "area1": "string",
 *      "area2": "string",
 *      "bank_loan": "string",
 *      "board_item_key": 999,
 *      "buyout": "string",
 *      "convenience_codes": [],
 *      "deposit": "string",
 *      "description": "string",
 *      "detail_address": "string",
 *      "feature_commercial_yn": "string",
 *      "feature_food_yn": "string",
 *      "feature_heating": "string",
 *      "feature_loan": "string",
 *      "feature_month_max_sales": "string",
 *      "feature_month_min_sales": "string",
 *      "feature_motel_yn": "string",
 *      "feature_park_yn": "string",
 *      "feature_room_count": "string",
 *      "feature_subway_yn": "string",
 *      "feature_university_yn": "string",
 *      "floor1": "string",
 *      "floor2": "string",
 *      "image_keys": [],
 *      "parking_count": "string",
 *      "phone_number": "string",
 *      "premium_price": "string",
 *      "price": "string",
 *      "profit_max_sales": "string",
 *      "profit_min_sales": "string",
 *      "rent_price": "string",
 *      "sale_yn": "string",
 *      "title": "string",
 *      "type": "string",
 *      "zipcode": "string",
 *    });
 */

export function createModifyItemsSaleboards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/saleboards/items/modify",
    data: params,
  });
}

/**
 * GET /saleboards/items/replies, saleboards/items/replies-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getRepliesItemsSaleboards({
 *      "board_item_key": "string",
 *      "count": "string",
 *      "cursor": "string",
 *    });
 */

export function getRepliesItemsSaleboards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/saleboards/items/replies",
    params: params,
  });
}

/**
 * POST /saleboards/items/replies, saleboards/items/replies-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createRepliesItemsSaleboards({
 *      "board_item_key": 999,
 *      "reply_text": "string",
 *    });
 */

export function createRepliesItemsSaleboards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/saleboards/items/replies",
    data: params,
  });
}

/**
 * POST /saleboards/items/replies/delete, saleboards/items/replies/delete-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDeleteRepliesItemsSaleboards({
 *      "board_item_key": 999,
 *      "reply_key": 999,
 *      "reply_text": "string",
 *    });
 */

export function createDeleteRepliesItemsSaleboards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/saleboards/items/replies/delete",
    data: params,
  });
}

/**
 * POST /saleboards/items/replies/modify, saleboards/items/replies/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyRepliesItemsSaleboards({
 *      "board_item_key": 999,
 *      "reply_key": 999,
 *      "reply_text": "string",
 *    });
 */

export function createModifyRepliesItemsSaleboards(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/saleboards/items/replies/modify",
    data: params,
  });
}

/**
 * POST /sessions/users, sessions/users-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createUsersSessions({
 *      "password": "string",
 *      "user_id": "string",
 *    });
 */

export function createUsersSessions(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/sessions/users",
    data: params,
  });
}

/** 
 * GET /sessions/users/detail, sessions/users/detail-GET
 *
 
 * @param {boolean} isHideLoading 로딩바 숨김 여부 
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getDetailUsersSessions();
 */

export function getDetailUsersSessions(isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/sessions/users/detail",
  });
}

/**
 * POST /sessions/users/refresh, sessions/users/refresh-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createRefreshUsersSessions({
 *      "refresh_token": "string",
 *    });
 */

export function createRefreshUsersSessions(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/sessions/users/refresh",
    data: params,
  });
}

/**
 * GET /statistics, statistics-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getStatistics({
 *      "motel_key": "string",
 *      "search_extra": "string",
 *      "search_type": "string",  //필수
 *    });
 */

export function getStatistics(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/statistics",
    params: params,
  });
}

/** 
 * GET /temp, deleteUserSetting
 *
 
 * @param {boolean} isHideLoading 로딩바 숨김 여부 
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getTemp();
 */

export function getTemp(isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/temp",
  });
}

/**
 * GET /terms, terms-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getTerms({
 *      "term_code": "string",
 *      "term_version": "string",
 *    });
 */

export function getTerms(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/terms",
    params: params,
  });
}

/**
 * POST /users, users-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createUsers({
 *      "email": "string",
 *      "name": "string",
 *      "password": "string",
 *      "phone_number": "string",
 *      "sms_auth_code": "string",
 *      "sms_auth_key": "string",
 *      "term_agree_codes": [],
 *      "type": "string",
 *      "user_id": "string",
 *    });
 */

export function createUsers(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/api/v2/partner/auth/join",
    data: params,
  });
}

/**
 * GET /users/adjustments, users/adjustments-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getAdjustmentsUsers({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",  //필수
 *      "search_extra": "string",
 *      "search_type": "string",  //필수
 *      "sort": "string",
 *    });
 */

// export function getAdjustmentsUsers(params, isHideLoading) {
//   return axios({
//     hideLoading: !!isHideLoading || false,
//     method: "GET",
//     url: ALIAS_SVR + "/coolstay/ownerweb/users/adjustments",
//     params: params,
//   });
// }

/**
 * GET /pms/book/adjustments, -GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getAdjustmentsUsers({
 *      "searchCase": "string",
 *      "adjustmentDate": "string",
 *      "count": "string",
 *      "cursor": "string",
 *      "storeKey": "string",  //필수
 *      "periodType": "string",//필수
 *      "startDate": "string",
 *      "endDate": "string",
 *      "keyword": "string",
 *      "periodType": "string",
 *      "APP-TOKEN": "string",
 *    });
 */

export function getAdjustments(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/pms/book/adjustments",
    data: params,
  });
}

/**
 * GET /users/adjustments/excel, users/adjustments/excel-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getExcelAdjustmentsUsers({
 *      "motel_key": "string",  //필수
 *      "search_extra": "string",
 *      "search_type": "string",
 *      "sort": "string",
 *    });
 */

export function getExcelAdjustmentsUsers(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/pms/book/adjustments/excel-download",
    params: params,
  });
}

/**
 * GET /api/v2/partner/auth/corp-number/duplication
 * 사업자번호 중복 체크
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 */

export function corpNumberDuplication(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/api/v2/partner/auth/corp-number/duplication",
    data: params,
  });
}

/**
 * POST /users/id/duplication, users/id/duplication-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDuplicationIdUsers({
 *      "user_id": "string",
 *    });
 */


export function createAffiliateUsers(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/api/v2/partner/auth/affiliate",
    data: params,
  });
}

/**
 * POST /users/id/duplication, users/id/duplication-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDuplicationIdUsers({
 *      "user_id": "string",
 *    });
 */

export function createDuplicationIdUsers(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/users/id/duplication",
    data: params,
  });
}

/**
 * POST /users/id/forget, users/id/forget-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createForgetIdUsers({
 *      "email": "string",
 *      "phone_number": "string",
 *      "sms_auth_code": "string",
 *      "sms_auth_key": "string",
 *      "user_name": "string",
 *    });
 */

export function createForgetIdUsers(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/users/id/forget",
    data: params,
  });
}

/**
 * POST /users/modify, users/modify-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createModifyUsers({
 *      "email": "string",
 *      "new_password": "string",
 *      "old_password": "string",
 *      "phone_number": "string",
 *      "sms_auth_code": "string",
 *      "sms_auth_key": "string",
 *    });
 */

export function createModifyUsers(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/api/v2/partner/auth/modify",
    data: params,
  });
}

/**
 * POST /users/password/forget, users/password/forget-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createForgetPasswordUsers({
 *      "email": "string",
 *      "phone_number": "string",
 *      "sms_auth_code": "string",
 *      "sms_auth_key": "string",
 *      "user_id": "string",
 *      "user_name": "string",
 *    });
 */

export function createForgetPasswordUsers(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/users/password/forget",
    data: params,
  });
}

/**
 * POST /users/phonenumber/duplication, users/phonenumber/duplication-POST
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.createDuplicationPhonenumberUsers({
 *      "phone_number": "string",
 *    });
 */

export function createDuplicationPhonenumberUsers(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/users/phonenumber/duplication",
    data: params,
  });
}

/**
 * GET /users/vat, users/vat-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getVatUsers({
 *      "count": "string",
 *      "cursor": "string",
 *      "motel_key": "string",  //필수
 *      "search_extra": "string",
 *      "search_type": "string",
 *      "sort": "string",
 *    });
 */

export function getVatUsers(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/users/vat",
    params: params,
  });
}

/**
 * GET /users/vat/excel, users/vat/excel-GET
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *    serviceAPI.getExcelVatUsers({
 *      "motel_key": "string",  //필수
 *      "search_extra": "string",
 *      "search_type": "string",  //필수
 *    });
 */

export function getExcelVatUsers(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/users/vat/excel",
    params: params,
  });
}

/**
 * POST /motels/coupon/reward
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function createRewardCoupon(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/coupon/reward",
    data: params,
  });
}

/**
 * POST /coupons/daily/cancel
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function createCancelDailyCoupons(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/coupons/daily/cancel",
    data: params,
  });
}

/**
 * GET /auto/issue/setting
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function getSettingIssueAuto(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/coupons/auto/issue/setting",
    params: params,
  });
}

// auto/issue/setting

/**
 * GET /motels/rooms/channels
 * 2.78	룸 외부 채널 판매 설정 조회
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function getChannelsMotelsRooms(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/rooms/channels",
    params: params,
  });
}

/**
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function getLowestPriceMotel(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/rooms/channels",
    params: params,
  });
}
/**
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function getCouponSalesList(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/coupons/first-come/calendar",
    params: params,
  });
}

/**
 * POST /motels/rooms/channels/modify
 * 2.79	룸 외부 판매설정 정보 수정
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function createModifyChannelsRoomsMotels(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/rooms/channels/modify",
    data: params,
  });
}

/**
 * GET /coolstay/ownerweb/motels/cheapest
 * 국내 최저가 조회
 *
 * @param {Object} params Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function getMotelCheapest(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/cheapest",
    params: params,
  });
}

/**
 * POST /coolstay/ownerweb/motels/cheapest
 * 국내 최저가 조회
 *
 * @data {Object} data Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function postMotelCheapest(data, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/motels/cheapest",
    data: data,
  });
}

/**
 * GET /coolstay/ownerweb/coupons/first-come
 * 선착순 쿠폰 조회
 *
 * @params {Object} data Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function getFirstComeCoupon(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/coupons/first-come",
    params: params,
  });
}

/**
 * POST /coolstay/ownerweb/coupons/first-come
 * 선착순 쿠폰 수정
 *
 * @params {Object} data Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function postFirstComeCoupon(data, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/coupons/first-come",
    data : data
  });
}


/**
 * GET /coolstay/ownerweb/coupons/unlimited
 * 무제한 쿠폰 조회
 *
 * @params {Object} data Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function getUmlitedCoupon(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/ownerweb/coupons/unlimited",
    params : params
  });
}

/**
 * POST /coolstay/ownerweb/coupons/unlimited
 * 무제한 쿠폰 수정
 *
 * @params {Object} data Request Parameter
 * @param {boolean} isHideLoading 로딩바 숨김 여부
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 */
export function postUmlitedCoupon(data, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/ownerweb/coupons/unlimited",
    data : data
  });
}



/**
 * POST /api/v2/partner/auth/temp/token
 *
 * @HEADER APP-TOKEN APP-TOKEN
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *
 */

export function postTempToken(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    headers:{
      'APP-TOKEN' : params?.tempToken ?? ''
    },
    url: ALIAS_SVR + "/api/v2/partner/auth/temp/token",
  });
}


/**
 * POST /api/v2/partner/auth/rsa RSA 키 요청
 *
 * @HEADER APP-TOKEN APP-TOKEN
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *
 */

export function postRSA(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    headers:{
      'APP-TOKEN' : params?.tempToken ?? ''
    },
    url: ALIAS_SVR +"/api/v2/partner/auth/rsa",
  });
}

/**
 * POST /api/v2/partner/auth/login 로그인 요청
 *
 * @HEADER APP-TOKEN APP-TOKEN
 * @returns {Promise} Axio에서 제공하는 Promise 객체
 * @example
 *
 */

export function postLogin(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    headers:{
      'APP-TOKEN' : params?.token?.tempToken ?? ''
    },
    data: params?.data,
    url: ALIAS_SVR + "/api/v2/partner/auth/login",
  });
}

export function postRefreshToken( data, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    data: data,
    url: ALIAS_SVR + "/api/v2/partner/auth/refresh/token",
    noToken: true
  });
}

export function postAuthRefresh( isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/api/v2/partner/auth/refresh",
  });
}

export function postLogout(data, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/api/v2/partner/auth/logout",
    data: data,
    noToken: true
  });
}
export function postGcpPath(data, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/api/v2/partner/file/gcp/path",
    data :data
  });
}

/**
 * 240625
 * 7월 개선건 (검색 고도화 ) - 검색 키워드
 * */
export function getKeyword(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "GET",
    url: ALIAS_SVR + "/coolstay/pms/store/search/keyword",
    params
  });
}

export function deleteKeyword(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/pms/store/search/keyword/delete",
    params
  });
}

export function postKeyword(params, isHideLoading) {
  return axios({
    hideLoading: !!isHideLoading || false,
    method: "POST",
    url: ALIAS_SVR + "/coolstay/pms/store/search/keyword",
    params
  });
}