/**
 * 브라우저 제어 기능을 제공하는 서비스 모듈 입니다<p/>
 *
 * @module lib/service-browser
 */

import * as utils from "utils";
import * as configApp from "config/config-app";

const TAG = "[lib/service-browser.js]";
// console.log(TAG, "Create");

let __isProvided = false;
let __isSupportedPassive = false;
let __isAddedTouchMoveListener = false;

let __disableCountRoute = 0;
let __enableCountRoute = 0;

let __disableCountScroll = 0;
let __enableCountScroll = 0;
let __isPauseDisableScroll = false;

let __memHash = "";
let __memURL = "";

let __prevScrollTo = 0;
let __requestID = null;

let __isUseBrowserRouter = true;
if (configApp.ROUTER_TYPE === "hash") {
  __isUseBrowserRouter = false;
}

let __isMobile = null; // 모바일 여부
let __isIOS = null; // IOS 여부
let __isAndroid = null; // Android 여부
let __isIE = null; // IE 8, 9, 10 여부
let __isIE11 = null; // IE 11 여부
let __isChrome = null; // 크롬 여부
let __isFirefox = null; //파이어폭스 여부

const Polyfill_requestAnimationFrame =
  window.requestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.msRequestAnimationFrame ||
  function (func) {
    if (!func.__raf__) {
      func.__raf__ = function () {
        func();
      };
    }
    return setTimeout(func.__raf__, 1000 / 60);
  };

const Polyfill_cancelAnimationFrame =
  window.cancelAnimationFrame ||
  window.mozCancelAnimationFrame ||
  window.webkitCancelAnimationFrame ||
  window.msCancelAnimationFrame ||
  clearTimeout;

function onURLChange(evt) {
  if (isBlockingRoute()) {
    if (!!__memURL && window.location.href !== __memURL) {
      __prevScrollTo = document.documentElement.scrollTop;
      console.log(
        TAG,
        `Blocking Back Navigation. now scrollTop is ${__prevScrollTo}`
      );
      __requestID !== null && Polyfill_cancelAnimationFrame(__requestID);
      __requestID = Polyfill_requestAnimationFrame(() => {
        __requestID && Polyfill_cancelAnimationFrame(__requestID);

        if (document.documentElement.scrollTop !== __prevScrollTo) {
          window.scroll(0, __prevScrollTo);
        }

        __requestID = null;
        __prevScrollTo = 0;
        createCustomEvent();
      });

      // window.history.replaceState(null, null, __memURL);
      window.history.pushState(null, null, __memURL);
    }
  }
}

function onHashChange(evt) {
  if (isBlockingRoute()) {
    if (!!__memHash && window.location.hash !== __memHash) {
      console.log(TAG, "Blocking Back Navigation");
      window.location.hash = __memHash;
    }
  }
}

/** 테스트 양태욱 추가 이벤트 전파 */
function createCustomEvent(bookId, bookState) {
  document.dispatchEvent(new CustomEvent("Review_PopState", {}));
}

// 오른쪽 메뉴 막기
// function onContextmenu() {
//   window.event.returnValue = false;
// }

// 화면 이동의 Blocking 여부를 반환하는 메소드 입니다
function isBlockingRoute() {
  return __enableCountRoute < __disableCountRoute;
}

// 스크롤의 Blocking 여부를 반환하는 메소드 입니다
export function isBlockingScroll() {
  return __enableCountScroll < __disableCountScroll;
}

function isSupportsPassiveOption() {
  try {
    window.addEventListener(
      "test",
      null,
      Object.defineProperty({}, "passive", {
        get: function () {
          __isSupportedPassive = true;
        },
      })
    );
  } catch (e) {}
}

/**
 * 브라우저 제어 기능을 제공하는 서비스 모듈의 초기 설정을 처리하는 메소드 입니다
 */
export function provider() {
  if (__isProvided) return;
  __isProvided = false;

  // 브라우저 주소창의 변경을 감시하도록 리스너를 등록 합니다
  if (__isUseBrowserRouter === true) {
    utils.addEventListener(window, "popstate", onURLChange, true);
  } else {
    utils.addEventListener(window, "hashchange", onHashChange, false);
  }

  // 마우스 오른쪽 메뉴 막기 처리
  // utils.addEventListener(document, "contextmenu", onContextmenu, false);

  isSupportsPassiveOption();
}

export function isSupportedPassive() {
  return __isSupportedPassive;
}

/**
 * Browser Back Navigation의 비활성 여부를 반환하는 메소드 입니다
 *
 * @returns {boolean} Browser Back Navigation의 비활성 여부
 */
export function isDisableBackNavigation() {
  return isBlockingRoute();
}

/**
 * Browser Back Navigation을 비활성 시키는 메소드 입니다
 */
export function disableBackNavigation() {
  if (!isBlockingRoute()) {
    if (__isUseBrowserRouter === false) {
      var idx = window.location.href.indexOf("#");
      if (-1 < idx) __memHash = window.location.href.substr(idx);
    } else {
      __memURL = window.location.href;
    }
  }
  __disableCountRoute++;
  console.log(TAG, "Called disableBackNavigation");
}

/**
 * Browser Back Navigation을 활성 시키는 메소드 입니다
 */
export function enableBackNavigation() {
  __enableCountRoute++;
  console.log(TAG, "Called enableBackNavigation");
}

/**
 * Browser Blocking 관련 값을 전부 초기화 합니다
 */
export function reset() {
  resetBlockingScroll();
  resetBlockingBackNavigation();
}

/**
 * Browser Back Navigation Blocking 관련 값을 초기화 합니다
 */
export function resetBlockingBackNavigation() {
  console.log(TAG, "Called resetBlockingBackNavigation");
  __disableCountRoute = 0;
  __enableCountRoute = 0;
  __memURL = "";
  __memHash = "";
}

/**
 * Browser Scroll Blocking 관련 값을 초기화 합니다
 */
export function resetBlockingScroll() {
  console.log(TAG, "Called resetBlockingScroll");
  __disableCountScroll = 0;
  __enableCountScroll = 0;
  __isPauseDisableScroll = false;
}

/**
 * 모바일 여부를 반환하는 메소드 입니다
 *
 * @returns {boolean} 모바일 여부
 */
export function isMobile() {
  if (__isMobile != null) return __isMobile;

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    __isMobile = true;
  } else {
    __isMobile = false;
  }
  return __isMobile;
}

/**
 * Android 여부를 반환하는 메소드 입니다
 *
 * @returns {boolean} Android 여부
 */
export function isAndroid() {
  if (__isAndroid != null) return __isAndroid;

  if (/Android/i.test(navigator.userAgent)) {
    __isAndroid = true;
  } else {
    __isAndroid = false;
  }
  return __isAndroid;
}

/**
 * IOS 여부를 반환하는 메소드 입니다
 *
 * @returns {boolean} IOS 여부
 */
export function isIOS() {
  if (__isIOS != null) return __isIOS;

  if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    __isIOS = true;
  } else {
    __isIOS = false;
  }
  return __isIOS;
}

/**
 * ie 8, 9, 10 여부를 반환하는 메소드 입니다
 *
 * @returns {boolean} ie 8, 9, 10 여부
 */
export function isIE() {
  if (__isIE != null) return __isIE;

  if (/MSIE/i.test(navigator.userAgent)) {
    __isIE = true;
  } else {
    __isIE = false;
  }
  return __isIE;
}

/**
 * ie11 여부를 반환하는 메소드 입니다
 *
 * @returns {boolean} ie11 여부
 */
export function isIE11() {
  if (__isIE11 != null) return __isIE11;

  if (
    navigator.appName === "Netscape" &&
    navigator.userAgent.search("Trident") !== -1
  ) {
    __isIE11 = true;
  } else {
    __isIE11 = false;
  }
  return __isIE11;
}

/**
 * 크롬 브라우저 여부를 반환하는 메소드 입니다
 *
 * @returns {boolean} 크롬 브라우저 여부
 */
export function isChrome() {
  if (__isChrome != null) return __isChrome;

  if (/Chrome/i.test(navigator.userAgent)) {
    __isChrome = true;
  } else {
    __isChrome = false;
  }
  return __isChrome;
}

/**
 * 파이어폭스 브라우저 여부를 반환하는 메소드 입니다
 *
 * @returns {boolean} 파이어폭스 브라우저 여부
 */
export function isFirefox() {
  if (__isFirefox != null) return __isFirefox;

  if (/Firefox/i.test(navigator.userAgent)) {
    __isFirefox = true;
  } else {
    __isFirefox = false;
  }
  return __isFirefox;
}

/**
 * Input Element에 텍스트가 입력 되거나 Copy&Paste 될 때, 문자열의 공백을 제거 하는 메소드 입니다
 *
 * @param {Event} evt 브라우저 이벤트 객체
 */
export function blockerInputBlankWhenOnChange(evt) {
  switch (evt.type) {
    case "change":
      if (isMobile()) {
        // IE11에서는 해당 방식으로 적용할 경우 한글 정상이 입력 되지 않아 모바일에서만 동작 되도록 처리
        // 모바일의 경우 가상 키패드를 통해 한글이 입력 되어 해당 지점에서 공백 처리를 하도록 함.
        evt.target.value = evt.target.value.replace(/ /gi, "");
      }
      break;
    case "paste":
      if (!isMobile()) {
        evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);

        if (window.clipboardData) {
          /*
          20200711 박태성C - IE11에서 에러가 발생 함. 추후 적용 예정

          let sel, range, node;
          try {
            // 참조) https://gdtbgl93.tistory.com/175
            // 참조) https://stackoverflow.com/questions/22935320/uncaught-indexsizeerror-failed-to-execute-getrangeat-on-selection-0-is-not
            // 참조) https://stackoverflow.com/questions/2176861/javascript-get-clipboard-data-on-paste-event-cross-browser/6804718#28528763
            sel = window.getSelection && window.getSelection();
            if (sel && sel.rangeCount > 0) {
              range = sel.getRangeAt(0);
              node = document.createTextNode(
                (window.clipboardData.getData("Text") || "").replace(/ /gi, "")
              );
              range.deleteContents();
              range.insertNode(node);
            }
          } catch (err) {
            console.log(err);
          }
          sel = range = node = null;
          */
        } else if (
          evt.clipboardData ||
          evt.originalEvent.clipboardData !== undefined
        ) {
          let t = evt || evt.originalEvent;
          try {
            document.execCommand(
              "insertText",
              false,
              (t.clipboardData.getData("text/plain") || "").replace(/ /gi, "")
            );
          } catch (err) {
            console.log(err);
          }
          t = null;
          return false;
        }
      }
      break;
  }
}

/**
 * 키입력을 통해 Input Element에 공백이 입력 될 때 차단 하는 메소드 입니다
 *
 * @param {Event} evt 브라우저 이벤트 객체
 */
export function blockerInputBlankWhenOnKeyPress(evt) {
  const keyCode = evt.which ? evt.which : evt.keyCode;
  if (keyCode === 32) {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    evt.stopPropagation ? evt.stopPropagation() : (evt.cancelBubble = true);
    return false;
  }
}

/**
 * 숫자 형식의 문자열만 Input Element에 입력 되도록 처리하는 메소드 입니다
 *
 * @param {Event} evt 브라우저 이벤트 객체
 * @param {Boolean} isCasting  Integer 타입으로 캐스팅 하여 처리 합니다
 */
export function inputNumberWhenOnChange(evt, isCasting) {
  let str = evt.target.value;
  if (-1 < str.indexOf(" ") || isNaN(str)) {
    // IE11에서 Back키 고려하여 숫자가 포함되어 있지 않을 경우에 대한 처리
    str = str.trim();
    if (!!str && isNaN(str)) {
      str = str.replace(/[^0-9]/g, "");
    }
    if (!!isCasting) {
      evt.target.value = parseInt(str) || "0";
    } else {
      evt.target.value = str;
    }
  } else {
    // 숫자만 있더라도 2자리 이하는 처리
    if (!!isCasting) {
      if (str.charAt(0) === "0" || !str.trim()) {
        evt.target.value = parseInt(str) || "0";
      }
    }
  }
}

export function addClassNameToBody(className) {
  if (
    !!document.body &&
    !!document.body.classList &&
    !!document.body.classList.add
  ) {
    document.body.classList.add(className);
  }
}

export function removeClassNameToBody(className) {
  if (
    !!document.body &&
    !!document.body.classList &&
    !!document.body.classList.remove
  ) {
    document.body.classList.remove(className);
  }
}

export function getWindowWidth() {
  let orientation = window.orientation;
  if (orientation !== undefined) {
    if (orientation === 0 || orientation === 180) {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    } else {
      return (
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      );
    }
  }
}

export function getWindowHeight() {
  let orientation = window.orientation;
  if (orientation !== undefined) {
    if (orientation === 0 || orientation === 180) {
      return (
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      );
    } else {
      return (
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      );
    }
  }
}

// 참고1) http://help.dottoro.com/ljcjcwuo.php
// 참고2) https://mommoo.tistory.com/85
export function getZoomFactor() {
  let factor = 1;
  if (!!document.body.getBoundingClientRect) {
    let rect = document.body.getBoundingClientRect();
    const physicalW = rect.right - rect.left;
    const logicalW = document.body.offsetWidth;
    factor = Math.round((physicalW / logicalW) * 100) / 100;
    rect = null;
  }
  return factor;
}

export function windowScrollByElem(elem, marginTop) {
  if (!!elem && !!elem.getBoundingClientRect) {
    const zoomFactor = getZoomFactor();
    let absoluteTop = window.pageYOffset + elem.getBoundingClientRect().top;
    if (!!marginTop) {
      absoluteTop = absoluteTop + marginTop;
    }
    // 현재 화면 스케일에 맞게 보정하여 window의 scroll을 설정한다.
    window.scrollTo(0, absoluteTop * zoomFactor);
  }
}

export function disableScroll() {
  if (!__isAddedTouchMoveListener) {
    __isAddedTouchMoveListener = true;
    document.body.addEventListener(
      "touchmove",
      (evt) => {
        if (isBlockingScroll() && !__isPauseDisableScroll) {
          if (evt.cancelable) {
            evt.preventDefault();
            evt.stopPropagation();
          }
        }
      },
      __isSupportedPassive ? { passive: false } : false
    );
  }

  __disableCountScroll++;
  console.log(TAG, "Called disableScroll");
}

export function enableScroll() {
  __enableCountScroll++;
  console.log(TAG, "Called enableScroll");
}

export function pauseDisableScroll() {
  console.log(TAG, "Called pauseDisableScroll");
  __isPauseDisableScroll = true;
}

export function resumeDisableScroll() {
  console.log(TAG, "Called resumeDisableScroll");
  __isPauseDisableScroll = false;
}
