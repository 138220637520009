import React, {useEffect, useState} from "react";
import {Box, Button, Paper, Tab, TableBody, TableContainer, Tabs,} from "@material-ui/core";
import {BoxWrap, TabPanel} from "components";
import CouponHelperTextTemplate from "./CouponHelperTextTemplate";
import TableHeader from "../TableHeader";
import * as serviceStorage from "../../../../lib/service-storage";
import COMMON_CONSTS from "constant/CommonConsts";
import FirstComeCouponTable from "../../FirstComeCouponTable";
import {StyledTable} from "components/styled/StyledComponents";
import {Link} from "react-router-dom";
import { history } from "lib/history";


/**
 * 선착순 쿠폰 설정 현황 템플릿
 * 꿀스테이 2.0 반영
 */
export default function FirstComeCouponTemplate ({model}) {

  const [userType, setUserType] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState([]);


  useEffect(() => {
    const identity = serviceStorage.getIdentity();
    setUserType(serviceStorage.getUserType(identity));
    setData(model['staySettings']);
  }, []);

  const handleClickButton = () => {
    history.replace("/couponCalendar")
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    if(newValue === 0) {
      setData(model['staySettings']);
    } else {
      setData(model['timeSettings']);
    }
  }


  return (
      <BoxWrap
          title={`선착순 쿠폰 설정 현황`}
          userType={userType}
          buttonTitle={"선착순 쿠폰 캘린더"}
          buttonOnClick={handleClickButton}
          buttonAvailable={true}
          buttonColor="primary"
          customStyle={{
            backgroundColor : '#FFFFFF'
          }}
      >
        <Box>
          <Tabs
              value={tabIndex}
              indicatorColor="primary"
              onChange={handleTabChange}
              style={{marginBottom: "10px"}}
          >
            <Tab value={0} className={"tab color-saleday"} label="숙박"/>
            <Tab value={1} className={"tab color-salehour"} label="대실"/>
          </Tabs>


          {
            Object.entries(COMMON_CONSTS.MOTEL_PROD_TYPE).map(([key, value], index) => (
                <TabPanel key={key} value={tabIndex} index={index}>
                  <TableContainer component={Paper}>
                    <StyledTable>
                      <TableHeader columns={columns}/>
                      <TableBody>
                        <FirstComeCouponTable data={data.filter((item) => item.totalCount !== 0 )}/>
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                </TabPanel>
            ))
          }
        </Box>

        <CouponHelperTextTemplate code="EASY_COUPON"/>
        <Box mt={2}>
          <Link to={'/firstComeCoupon'}>
          <Button
              variant="contained"
              color="primary"
              fullWidth
          >
            <strong>선착순 쿠폰 설정으로 가기</strong>
          </Button>
          </Link>
        </Box>
      </BoxWrap>
  );
}


const columns = [
  {
    title: "적용일",
    align: "center",
    width: "13%",
  },
  {
    title: "할인",
    align: "center",
    width: "13%",
  },
  {
    title: "수량",
    align: "center",
    width: "13%",
  },
  {
    title: "유형",
    align: "center",
    width: "13%",
  }
];
