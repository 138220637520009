import React, { createRef } from "react";
import { BoxWrap, ModalWrap, TextFieldLengthReg } from "components";
import { TableStyle } from "components/TableStyle";
import {
  Box,
  FormControlLabel,
  Button,
  IconButton,
  Checkbox,
  // TextField,
  MenuItem,
  FormGroup,
  FormControl,
  // InputLabel,
  Select,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DataTable from "react-data-table-component";
import {
  createSettingAlarmsMotels,
  createDeleteSettingAlarmsMotels,
  getSettingAlarmsMotels,
  createModifySettingAlarmsMotels,
} from "lib/service-api";
import {
  openConfirmPopupWithPromise,
  POPUP_ACTION_OK,
  openAlertPopupWithPromise,
} from "lib/helper-popup";
import CommonConsts from "constant/CommonConsts";
import { getServiceCodeUserType } from "constant/ServiceCode";
import PopupConsts from "constant/PopupConsts";
import { objectDeepClone } from "utils/ObjectHelper";
import phoneFormatter from "utils/phoneFormatter";
import InputLength from "constant/InputLength";
import {STORE_SETTINGS_MESSAGE_NO_DATA} from "../pages/surtax/surtaxUtil";
import NoDataComponent from "./NoDataComponentPC";

const numReg = /\d+/g;
const defaultAlarmOptions = {
  phoneHead: "010",
  alarmStartTime: "00",
  alarmEndTime: "00",
  allDayAlarm: false,
  reserve: false,
  review: false,
  answer: false,
  notice: false,
};

class MessageAlarmSetting extends React.Component {
  constructor(props) {
    super(props);

    this.messageAlarmNameRef = createRef();
    this.messageAlarmPhoneMidRef = createRef();
    this.messageAlarmPhoneFinRef = createRef();

    this.state = {
      messageAlarmList: props.messageList,
      // addAlarm: {
      //   phoneHead: "010",
      //   alarmStartTime: "00",
      //   alarmEndTime: "00",
      //   allDayAlarm: false,
      //   reserve: false,
      //   review: false,
      //   answer: false,
      //   notice: false,
      // },
      addAlarm: {
        ...defaultAlarmOptions,
      },
      modalOpen1: false,
      motelKey: props.motelKey,
      nameCount: 0,
    };
  }

  componentDidMount() {
    // this.getMotelAlarmList()
  }

  /** 이름 항목 변경 이벤트 */
  handleChangeName = (e) => {
    // 2021.06.09 [COOL-173]
    // 기존 uncontrolled 방식으로 동작하였으나
    // 글자수를 추가해달라는 요청으로 인하여 change이벤트를 추가하여
    // 글자수만 카운팅하여 state에 반영
    this.setState({
      nameCount: e.target.value.length,
    });
  };

  getMotelAlarmList = () => {
    getSettingAlarmsMotels({
      motel_key: this.state.motelKey,
    })
      .then((res) => {
        if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
          openAlertPopupWithPromise(null, res.data.desc);
        }
        this.setState({
          messageAlarmList: res.data.result.motel_alarms,
        });
      })
      .catch(this.commonCatch);
  };

  handleAlarmListAddCheckBox = (e, value) => {
    //[e.currentTarget.name]:value
    this.setState({
      addAlarm: {
        ...this.state.addAlarm,
        [e.currentTarget.name]: value,
      },
    });
  };

  alarmColumns = [
    {
      name: "권한",
      // selector: "type",
      center: true,
      width: "7%",
      cell: ({ type }) => {
        return CommonConsts.USER_TYPE[type];
      },
    },
    {
      name: "성명",
      selector: "name",
      center: true,
      width: "25%", // 9%
      cell: ({ name }) => {
        return name;
      },
    },
    {
      name: "휴대폰번호",
      selector: "phone_number",
      cell: ({ phone_number }) => {
        return phoneFormatter(phone_number);
      },
      center: true,
      width: "12%",
    },
    {
      cell: (row) => {
        return (
          <>
            <Select
              onChange={(e, { props: { value } }) => {
                e.target = null;
                this.alarmSettingChange(value, row, "start_time");
              }}
              variant="standard"
              disabled={row.allday_yn === "Y"}
              value={row.start_time ? row.start_time : "00"}
            >
              <MenuItem value="00">00</MenuItem>
              <MenuItem value="01">01</MenuItem>
              <MenuItem value="02">02</MenuItem>
              <MenuItem value="03">03</MenuItem>
              <MenuItem value="04">04</MenuItem>
              <MenuItem value="05">05</MenuItem>
              <MenuItem value="06">06</MenuItem>
              <MenuItem value="07">07</MenuItem>
              <MenuItem value="08">08</MenuItem>
              <MenuItem value="09">09</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="11">11</MenuItem>
              <MenuItem value="12">12</MenuItem>
              <MenuItem value="13">13</MenuItem>
              <MenuItem value="14">14</MenuItem>
              <MenuItem value="15">15</MenuItem>
              <MenuItem value="16">16</MenuItem>
              <MenuItem value="17">17</MenuItem>
              <MenuItem value="18">18</MenuItem>
              <MenuItem value="19">19</MenuItem>
              <MenuItem value="20">20</MenuItem>
              <MenuItem value="21">21</MenuItem>
              <MenuItem value="22">22</MenuItem>
              <MenuItem value="23">23</MenuItem>
              <MenuItem value="24">24</MenuItem>
            </Select>
            &nbsp;~&nbsp;
            <Select
              onChange={(e, { props: { value } }) => {
                e.target = null;
                this.alarmSettingChange(value, row, "end_time");
              }}
              disabled={row.allday_yn === "Y"}
              variant="standard"
              value={row.end_time ? row.end_time : "00"}
            >
              <MenuItem value="00">00</MenuItem>
              <MenuItem value="01">01</MenuItem>
              <MenuItem value="02">02</MenuItem>
              <MenuItem value="03">03</MenuItem>
              <MenuItem value="04">04</MenuItem>
              <MenuItem value="05">05</MenuItem>
              <MenuItem value="06">06</MenuItem>
              <MenuItem value="07">07</MenuItem>
              <MenuItem value="08">08</MenuItem>
              <MenuItem value="09">09</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="11">11</MenuItem>
              <MenuItem value="12">12</MenuItem>
              <MenuItem value="13">13</MenuItem>
              <MenuItem value="14">14</MenuItem>
              <MenuItem value="15">15</MenuItem>
              <MenuItem value="16">16</MenuItem>
              <MenuItem value="17">17</MenuItem>
              <MenuItem value="18">18</MenuItem>
              <MenuItem value="19">19</MenuItem>
              <MenuItem value="20">20</MenuItem>
              <MenuItem value="21">21</MenuItem>
              <MenuItem value="22">22</MenuItem>
              <MenuItem value="23">23</MenuItem>
              <MenuItem value="24">24</MenuItem>
            </Select>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={row.allday_yn === "Y"}
                  onChange={(e, value) => {
                    this.alarmSettingChange(
                      value ? "Y" : "N",
                      row,
                      "allday_yn"
                    );
                  }}
                />
              }
              label="종일"
            />
          </>
        );
      },
      name: "알림시간",
      selector: "sel4",
      center: true,
      width: "20%", // 25%
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => {
        return (
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={row.alarm_book_yn === "Y"}
                  onChange={(e, value) => {
                    this.alarmSettingChange(
                      value ? "Y" : "N",
                      row,
                      "alarm_book_yn"
                    );
                  }}
                />
              }
              label="예약"
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={row.alarm_review_yn === "Y"}
                  onChange={(e, value) => {
                    this.alarmSettingChange(
                      value ? "Y" : "N",
                      row,
                      "alarm_review_yn"
                    );
                  }}
                />
              }
              label="후기"
            />

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={row.alarm_reply_yn === "Y"}
                  onChange={(e, value) => {
                    this.alarmSettingChange(
                      value ? "Y" : "N",
                      row,
                      "alarm_reply_yn"
                    );
                  }}
                />
              }
              label="답변"
            />
            {/*
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={row.alarm_notice_yn === 'Y'}
                  onChange={(e, value) => {
                    this.alarmSettingChange(value?'Y':'N', row, 'alarm_notice_yn')
                  }}
                />
              }
              label="공지사항"
            /> */}
          </FormGroup>
        );
      },
      name: "알림선택항목",
      // selector: "sel5",
      center: true,
    },
    {
      cell: (row) => {
        return row.type !== getServiceCodeUserType("사장님 Web - 사장님") ? (
          <IconButton
            onClick={() => {
              this.removeAlarmList(row.motel_key, row.phone_number, row.type);
            }}
            style={{ padding: "0" }}
          >
            <DeleteForeverIcon style={{ fontSize: 24, padding: "0" }} />
          </IconButton>
        ) : null;
      },
      name: "삭제",
      center: true,
      width: "50px",
    },
  ];

  alarmSettingChange = (value, row, fieldName) => {
    const targetIdx = this.findIndexInMessageList(row);
    let targetList = [...this.state.messageAlarmList];
    let targetObj = targetList[targetIdx];
    targetObj[fieldName] = value;
    targetList.splice(targetIdx, 1, targetObj);
    this.setState({
      messageAlarmList: objectDeepClone(targetList),
    });
  };

  handleModalClose = (modalNum) => {
    if (modalNum === 1) {
      this.setState({
        modalOpen1: !this.state.modalOpen1,
        nameCount: 0,
        addAlarm: {
          ...defaultAlarmOptions,
        },
      });
    }
  };

  findIndexInMessageList = (row) => {
    let uniqueKey = `${row.phone_number}|${row.reg_dt}`;
    let returnIdx = 0;
    this.state.messageAlarmList.some((data, idx) => {
      const isEquals = uniqueKey === `${data.phone_number}|${data.reg_dt}`;
      if (isEquals) {
        returnIdx = idx;
      }
      return isEquals;
    });
    return returnIdx;
  };

  handleClickOpen = (modalNum) => {
    if (this.state.messageAlarmList.length >= 5) {
      return openAlertPopupWithPromise(
        null,
        "최대 5명까지 등록하실 수 있습니다."
      );
    }
    if (modalNum === 1) {
      this.setState({ modalOpen1: !this.state.modalOpen1 });
    }
  };

  handleAddAlarmList = () => {
    const addObj = {
      alarm_book_yn: this.state.addAlarm.reserve ? "Y" : "N",
      alarm_notice_yn: this.state.addAlarm.notice ? "Y" : "N",
      alarm_reply_yn: this.state.addAlarm.answer ? "Y" : "N",
      alarm_review_yn: this.state.addAlarm.review ? "Y" : "N",
      allday_yn: this.state.addAlarm.allDayAlarm ? "Y" : "N",
      start_time: this.state.addAlarm.alarmStartTime
        ? this.state.addAlarm.alarmStartTime
        : "00",
      end_time: this.state.addAlarm.alarmEndTime
        ? this.state.addAlarm.alarmEndTime
        : "00",
      name: this.messageAlarmNameRef.current.value,
      phone_number:
        this.state.addAlarm.phoneHead +
        this.messageAlarmPhoneMidRef.current.value +
        this.messageAlarmPhoneFinRef.current.value,
      motel_key: this.state.motelKey,
    };

    if (addObj.allday_yn === "Y") {
      // delete addObj.start_time
      // delete addObj.end_time
    }

    if (
      !addObj.name ||
      !this.messageAlarmPhoneMidRef.current.value ||
      !this.messageAlarmPhoneFinRef.current.value
    ) {
      return openAlertPopupWithPromise(PopupConsts.A1);
    }

    if (
      this.messageAlarmPhoneMidRef.current.value.length < 3 ||
      this.messageAlarmPhoneFinRef.current.value.length < 4
    ) {
      return openAlertPopupWithPromise(
        null,
        "잘못된 휴대폰번호를 입력하셨습니다.\n다시 입력해 주세요."
      );
    }

    if (
      this.state.messageAlarmList.some((data, idx) => {
        return data.phone_number === addObj.phone_number;
      })
    ) {
      return openAlertPopupWithPromise(
        null,
        "입력하신 휴대폰번호는 등록된 휴대폰 번호입니다."
      );
    }

    createSettingAlarmsMotels(addObj)
      .then((res) => {
        if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
          return openAlertPopupWithPromise(null, res.data.desc);
        }
        this.setState(
          {
            nameCount: 0,
            modalOpen1: false,
            addAlarm: {
              ...this.state.addAlarm,
              phoneHead: "010",
            },
          },
          this.getMotelAlarmList
        );

        // this.setState({
        //   messageAlarmList: [
        //     ...this.state.messageAlarmList,
        //     addObj
        //   ],
        //   modalOpen1: false
        // })
      })
      .catch(this.commonCatch);
  };

  commonCatch = (err) => {
    if (err.response) {
      openAlertPopupWithPromise(null, err.response.data.desc);
    } else {
      openAlertPopupWithPromise(null, err);
    }
  };

  removeAlarmList = (key, phoneNumber, type) => {
    if (type === getServiceCodeUserType("사장님 Web - 사장님")) {
      return;
    }
    openConfirmPopupWithPromise(null, "선택된 사용자를 삭제하시겠습니까?")
      .then((res) => {
        if (res === POPUP_ACTION_OK) {
          return createDeleteSettingAlarmsMotels({
            motel_key: key,
            phone_number: phoneNumber,
          });
        }
      })
      .then((res) => {
        if (!res) {
          return;
        }
        if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
          return openAlertPopupWithPromise(null, res.data.desc);
        }

        //20200909 박태성C - 메시지 알림 설정에서 사용자 삭제 후 서버 연동을 통한 재갱신 처리 적용
        this.getMotelAlarmList();

        // this.setState({
        //   messageAlarmList: [
        //     ...this.state.messageAlarmList.filter(
        //       (data) => data.phone_number !== phoneNumber
        //     ),
        //   ],
        // });
      })
      .catch(this.commonCatch);
  };

  saveSetting = () => {

    const list = this.state.messageAlarmList;
    if(list.length === 0){
        return openAlertPopupWithPromise(null, "수신자를 등록해주세요.");
    }

    const check = list.some((data) => {
        return ( data.type !== getServiceCodeUserType("사장님 Web - 사장님") ) && (data.phone_number === "" || data.phone_number === null || data.phone_number === undefined)
    });
    if(check){
      return openAlertPopupWithPromise(null, "휴대폰 번호가 등록되지 않은 사용자가 있습니다.\n휴대폰 번호를 등록해 주세요.");
    }

    createModifySettingAlarmsMotels({
      motel_key: this.state.motelKey,
      motel_alarms: this.state.messageAlarmList,
    })
      .then((res) => {
        if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
          return openAlertPopupWithPromise(null, res.data.desc);
        }
      })
      .catch(this.commonCatch);
  };

  render() {
    const { nameCount } = this.state;
    return (
      <>
        <BoxWrap title={"메시지 알림 설정"}>
          <Box>
            {this.state.messageAlarmList && (
              <DataTable
                noHeader={true}
                highlightOnHover={true}
                columns={this.alarmColumns}
                data={this.state.messageAlarmList}
                customStyles={TableStyle.styleNormal()}
                noDataComponent={<NoDataComponent content={STORE_SETTINGS_MESSAGE_NO_DATA} />}
              />
            )}
          </Box>

          <Box mt={2} align="right">
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={"submit"}
              onClick={() => {
                this.handleClickOpen(1);
              }}
              size="small"
              style={{ marginRight: "12px" }}
            >
              <strong>수신자등록</strong>
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className={"submit"}
              onClick={this.saveSetting}
              size="small"
            >
              <strong>적용</strong>
            </Button>
          </Box>
        </BoxWrap>
        <ModalWrap
          //title={"수신자등록"}
          onClose={() => {
            this.handleModalClose(1);
          }}
          onButtons={[
            {
              title: "저장",
              callback: this.handleAddAlarmList,
            },
            {
              title: "취소",
              callback: () => {
                this.handleModalClose(1);
              },
            },
          ]}
          open={this.state.modalOpen1}
          PaperProps={{
            style: {
              width: "840px",
              height: "500px",
            },
          }}
        >
          <Box>
            <h2>메시지 알림 설정</h2>
          </Box>
          <Box style={{ color: "#008aff" }}>
            사업장을 담당하고 있는 직원을 추가, 삭제할 수 있습니다.
          </Box>
          <Box id="CommonFormList-PC">
            <Box mt={2}>
              <div className={"title"}>
                이름
                <span style={{ color: "#ff4a4a" }}>(필수)</span>
              </div>
              <div className={"contentBody"}>
                {/* <TextFieldLengthReg
                  variant="outlined"
                  margin="normal"
                  required
                  //fullWidth
                  id="userName"
                  name="userName"
                  autoComplete="off"
                  style={{ width: "100%" }}
                  size="small"
                  maxLength={InputLength.userName.max}
                  inputRef={this.messageAlarmNameRef}
                /> */}
                <OutlinedInput
                  fullWidth
                  size="small"
                  autoComplete="off"
                  style={{
                    padding: "0px",
                    maxHeight: "40px",
                  }}
                  endAdornment={
                    <InputAdornment
                      position="end"
                      style={{ opacity: 0.4, paddingRight: "15px" }}
                    >
                      {/* (0/25) */}({nameCount}/{InputLength.userName.max})
                    </InputAdornment>
                  }
                  inputRef={this.messageAlarmNameRef}
                  inputProps={{
                    maxLength: InputLength.userName.max,
                    style: {
                      padding: "11px 10px",
                    },
                  }}
                  onChange={this.handleChangeName}
                />
              </div>
            </Box>
            <Box>
              <div className={"title"}>
                휴대폰번호
                <span style={{ color: "#ff4a4a" }}>(필수)</span>
              </div>
              <div className={"contentBody"}>
                <FormControl
                  variant="outlined"
                  className={"textField items"}
                  style={{ width: "28%" }}
                  size="small"
                >
                  <Select
                    value={this.state.addAlarm.phoneHead}
                    // onChange={this.handlePhoneNum}
                    onChange={(evt) => {
                      const { value } = evt.target;
                      this.setState({
                        addAlarm: {
                          ...this.state.addAlarm,
                          phoneHead: value,
                        },
                      });
                    }}
                  >
                    <MenuItem value={"010"}>010</MenuItem>
                    <MenuItem value={"011"}>011</MenuItem>
                    <MenuItem value={"016"}>016</MenuItem>
                    <MenuItem value={"017"}>017</MenuItem>
                    <MenuItem value={"018"}>018</MenuItem>
                    <MenuItem value={"019"}>019</MenuItem>
                  </Select>
                </FormControl>
                <TextFieldLengthReg
                  className="textField"
                  variant="outlined"
                  margin="normal"
                  style={{
                    width: "30%",
                    marginLeft: "1%",
                    marginRight: "1%",
                  }}
                  size="small"
                  inputRef={this.messageAlarmPhoneMidRef}
                  filterRegExp={numReg}
                  maxLength={4}
                />
                <TextFieldLengthReg
                  className={"textField"}
                  variant="outlined"
                  margin="normal"
                  filterRegExp={numReg}
                  style={{ width: "30%" }}
                  size="small"
                  inputRef={this.messageAlarmPhoneFinRef}
                  maxLength={4}
                />
              </div>
            </Box>
            <Box>
              <div className={"title"}>알림 시간</div>
              <div className={"contentBody"}>
                <FormControl
                  variant="outlined"
                  className={"textField items"}
                  size="small"
                >
                  <Select
                    name="alarmStartTime"
                    value={this.state.addAlarm.alarmStartTime}
                    onChange={(e) => {
                      this.setState({
                        addAlarm: {
                          ...this.state.addAlarm,
                          alarmStartTime: e.target.value,
                        },
                      });
                    }}
                    defaultValue={0}
                    disabled={this.state.addAlarm.allDayAlarm}
                    style={{ marginTop: "2px" }}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="24">24</MenuItem>
                  </Select>
                </FormControl>
                &nbsp;~&nbsp;
                <FormControl
                  variant="outlined"
                  className={"textField items"}
                  size="small"
                >
                  <Select
                    value={this.state.addAlarm.alarmEndTime}
                    onChange={(e) => {
                      this.setState({
                        addAlarm: {
                          ...this.state.addAlarm,
                          alarmEndTime: e.target.value,
                        },
                      });
                    }}
                    style={{ marginTop: "2px" }}
                    disabled={this.state.addAlarm.allDayAlarm}
                    defaultValue={0}
                  >
                    <MenuItem value="00">00</MenuItem>
                    <MenuItem value="01">01</MenuItem>
                    <MenuItem value="02">02</MenuItem>
                    <MenuItem value="03">03</MenuItem>
                    <MenuItem value="04">04</MenuItem>
                    <MenuItem value="05">05</MenuItem>
                    <MenuItem value="06">06</MenuItem>
                    <MenuItem value="07">07</MenuItem>
                    <MenuItem value="08">08</MenuItem>
                    <MenuItem value="09">09</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                    <MenuItem value="23">23</MenuItem>
                    <MenuItem value="24">24</MenuItem>
                  </Select>
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      name="allDayAlarm"
                      onChange={this.handleAlarmListAddCheckBox}
                      checked={this.state.allDayAlarm}
                    />
                  }
                  label="종일"
                  style={{ marginLeft: "8px" }}
                />
              </div>
            </Box>
            <Box style={{ borderBottom: "1px solid #ddd" }}>
              <div className={"title"}>알림선택항목</div>
              <div className={"contentBody"}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        //checked={state.checkedA}
                        name="reserve"
                        onChange={this.handleAlarmListAddCheckBox}
                      />
                    }
                    label="예약"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        //checked={state.checkedA}
                        name="review"
                        onChange={this.handleAlarmListAddCheckBox}
                      />
                    }
                    label="후기"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        //checked={state.checkedA}
                        name="answer"
                        onChange={this.handleAlarmListAddCheckBox}
                      />
                    }
                    label="답변"
                  />
                  {/* 
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      //checked={state.checkedA}
                      name="notice"
                      onChange={this.handleAlarmListAddCheckBox}
                    />
                  }
                  label="공지사항"
                /> */}
                </FormGroup>
              </div>
            </Box>
          </Box>
        </ModalWrap>
      </>
    );
  }
}

export { MessageAlarmSetting };
