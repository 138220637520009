import React, { createRef } from "react";
import { BoxWrap } from "components";
import { Box, FormControlLabel, Switch } from "@material-ui/core";
import { getSettingsMotels, createModifySettingsMotels } from "lib/service-api";
import CommonConsts from "constant/CommonConsts";
import PopupConsts from "constant/PopupConsts";
import {
  POPUP_ACTION_OK,
  openAlertPopupWithPromise,
  openConfirmPopupWithPromise,
} from "lib/helper-popup";

class MotelStateSetting extends React.Component {
  constructor(props) {
    super(props);

    this.adminIdRef = createRef();
    this.state = {
      motelKey: props.motelKey,
      stay_close_yn: props.isStayClose,
      rent_close_yn: props.isRentClose,
      full_parking_yn: props.fullParking,
      consecutive_yn: props.consecutive,
    };
  }

  componentDidMount() {
    // this.getSetting()
  }

  getSetting = () => {
    getSettingsMotels({
      motel_key: this.state.motelKey,
    })
      .then((res) => {
        if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
          return openAlertPopupWithPromise(res.data.desc);
        }
        this.setState({
          stay_close_yn: res.data.result.motel_setting.stay_close_yn === "Y",
          rent_close_yn: res.data.result.motel_setting.rent_close_yn === "Y",
          full_parking_yn:
            res.data.result.motel_setting.full_parking_yn === "Y",
          consecutive_yn: res.data.result.motel_setting.consecutive_yn === "Y",
        });
      })
      .catch(this.commonCatch);
  };

  handleChangeSettinng = ({ currentTarget: { name } }, value) => {
    let modelC = {};
    // let modelA = {};
    if (name === "stay_close_yn") {
      modelC = value ? PopupConsts.C5 : PopupConsts.C10;
      // modelA = value ? PopupConsts.A61 : PopupConsts.A62;
    } else if (name === "rent_close_yn") {
      modelC = value ? PopupConsts.C5_1 : PopupConsts.C10_1;
      // modelA = value ? PopupConsts.A61 : PopupConsts.A62;
    } else {
      modelC = value ? PopupConsts.C6 : PopupConsts.C9;
      // modelA = value ? PopupConsts.A63 : PopupConsts.A62;
    }

    openConfirmPopupWithPromise(modelC)
      .then((res) => {
        if (res === POPUP_ACTION_OK) {
          return createModifySettingsMotels({
            motel_key: this.state.motelKey,
            [name]: value ? "Y" : "N",
          });
        }
      })
      .then((res) => {
        if (res) {
          if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
            return openAlertPopupWithPromise(null, res.data.desc);
          }
          // this.setState({ [name]: value });
          // openAlertPopupWithPromise(modelA);
          this.getSetting();
        }
      })
      .catch(this.commonCatch);
  };

  commonCatch = (err) => {
    if (err.response) {
      openAlertPopupWithPromise(null, err.response.data.desc);
    } else {
      openAlertPopupWithPromise(null, err);
    }
  };

  render() {
    return (
      <>
        <BoxWrap title="제휴점 현황 설정">
          <Box display="flex">
            <Box style={{ flex: "auto" }} align="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.stay_close_yn}
                    onChange={this.handleChangeSettinng}
                    name="stay_close_yn"
                    color="primary"
                  />
                }
                label="오늘 숙박 만실(오늘 숙박 영업 종료)"
                labelPlacement="end"
              />
            </Box>
            <Box style={{ flex: "auto" }} align="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.rent_close_yn}
                    onChange={this.handleChangeSettinng}
                    name="rent_close_yn"
                    color="primary"
                  />
                }
                label="오늘 대실 만실(오늘 대실 영업 종료)"
                labelPlacement="end"
              />
            </Box>
            <Box style={{ flex: "auto" }} align="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.full_parking_yn}
                    onChange={this.handleChangeSettinng}
                    name="full_parking_yn"
                    color="primary"
                  />
                }
                label="오늘 주차장 만차"
                labelPlacement="end"
              />
            </Box>
            {/* <Box style={{ flex: "auto" }} align="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.consecutive_yn}
                    onChange={this.handleChangeSettinng}
                    name="consecutive_yn"
                    color="primary"
                  />
                }
                label="연박 설정"
                labelPlacement="end"
              />
            </Box> */}
          </Box>
        </BoxWrap>
      </>
    );
  }
}

export { MotelStateSetting };
