import React, { PureComponent, createRef } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import {
  RoomCoastInputComponent,
  BoxWrap,
  // , RedButton
} from "components";
import { withStyles, withTheme } from "@material-ui/core/styles";
import { history } from "lib/history";
import {
  createModifyChargesMotels,
  createChargesMotels,
} from "lib/service-api";
import CommonConsts from "constant/CommonConsts";
import {
  openAlertPopupWithPromise,
  openConfirmPopupWithPromise,
  POPUP_ACTION_OK,
  openHttpRejectionPopupWithPromise,
} from "lib/helper-popup";
import PopupConsts from "constant/PopupConsts";
import { objectDeepClone } from "utils/ObjectHelper";
import commaFormater from "utils/commaFormater";

const styles = (theme) => ({
  table: {
    // border: '1px solid red',
    border: "1px solid #ddd",
    borderCollapse: "collapse",
    "& th": {
      background: "#f8f8f9",
      fontWeight: "bold",
    },
    "& td": {
      border: "1px solid #ddd",
    },
  },
  noRoomContentsWrapper: {
    height: "330px",
    borderBottom: "1px solid #ddd",
    textAlign: "center",
    display: "table",
    width: "100%",
  },
  noRoomContents: {
    display: "table-cell",
    verticalAlign: "middle",
    fontSize: "14px",
    fontWeight: "normal",
  },
  noRoomButtonWrapper: {
    textAlign: "center",
    marginTop: "20px",
    "& button": {
      fontSize: "14px",
      fontWeight: "bold",
      width: "290px",
    },
  },
});

class StandardChargeInfoRaw extends PureComponent {
  constructor(props) {
    super(props);
    let payListObj = {};
    this.payRef = [];
    props.payList.forEach((value, idx) => {
      payListObj[value.key] = { ...value, idx };
      this.payRef.push([
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
        createRef(),
      ]);
    });
    this.state = {
      payListObj,
      originPayListObj: objectDeepClone(payListObj),
      chargeKey: this.props.chargeKey,
    };
  }

  // defaultPayInfo = [
  //   {code:'0', value: 0},
  //   {code:'1', value: 0},
  //   {code:'2', value: 0},
  //   {code:'3', value: 0},
  //   {code:'4', value: 0},
  //   {code:'5', value: 0},
  //   {code:'6', value: 0},
  // ]

  numreg = /^\d+$/;

  costChange = (value, dayCode, roomKey) => {
    this.setState({
      payListObj: {
        ...this.state.payListObj,
        [roomKey]: {
          ...this.state.payListObj[roomKey],
          room_item_prices: {
            ...this.state.payListObj[roomKey].room_item_prices,
            [dayCode]: value,
          },
        },
      },
    });
  };
  changeDayObjToArr = (dayObj) => {
    let returnArr = [];
    // let returnArr = []
    dayObj &&
      Object.keys(dayObj).forEach((key, idx) => {
        returnArr.splice(key, 1, {
          code: key,
          value: parseInt(dayObj[key]),
        });
      });

    return returnArr;
  };
  changePriceObjToArr = () => {
    let returnArr = new Array(this.props.payList.length).fill({});

    Object.keys(this.state.payListObj).forEach((key, idx) => {
      let targetObj = {
        ...this.state.payListObj[key],
        room_item_prices: this.changeDayObjToArr(
          this.state.payListObj[key].room_item_prices
        ),
      };
      returnArr.splice(targetObj.idx, 1, targetObj);
    });

    return returnArr;
  };

  editStandardCharge = () => {
    let deferenceFlag = true;
    let zeroFlag = false;
    let valueSum = 0;
    let zeroValueRoomArr = [];
    try {
      Object.keys(this.state.payListObj).forEach((roomKey) => {
        Object.keys(this.state.payListObj[roomKey].room_item_prices).forEach(
          (payKey) => {
            // console.error(this.state.payListObj[roomKey].room_item_prices[payKey])
            if (
              this.state.payListObj[roomKey].room_item_prices[payKey] === ""
            ) {
              throw Error(PopupConsts.A49.desc);
            }
            if (
              !this.numreg.test(
                this.state.payListObj[roomKey].room_item_prices[payKey]
              )
            ) {
              throw Error(PopupConsts.A43.desc);
            }
            if (
              Number(
                this.state.payListObj[roomKey].room_item_prices[payKey]
              ) !==
              Number(
                this.state.originPayListObj[roomKey].room_item_prices[payKey]
              )
            ) {
              deferenceFlag = false;
            }
            let tempNum = parseInt(
              this.state.payListObj[roomKey].room_item_prices[payKey]
            );
            if (tempNum <= 0) {
              zeroFlag = true;
            }
            valueSum += tempNum;
          }
        );

        if (valueSum > 0 && zeroFlag) {
          zeroValueRoomArr.push(this.state.payListObj[roomKey].name);
        }
        zeroFlag = false;
        valueSum = 0;
      });
      // console.error(valueSum)
      if (zeroValueRoomArr.length > 0) {
        // console.error(zeroValueRoomArr)
        throw Error(
          zeroValueRoomArr.length > 1
            ? `${zeroValueRoomArr[0]} 외 ${
                zeroValueRoomArr.length - 1
              }건의 객실 일부 요일\n기준요금이 ‘0원＇상태입니다.\n확인하시고 입력해 주세요.`
            : `${zeroValueRoomArr[0]} 객실 일부 요일\n기준요금이 ‘0원＇상태입니다.\n확인하시고 입력해 주세요.`
        );
      }
    } catch (e) {
      // console.error(e.message)
      return openAlertPopupWithPromise(null, e.message);
    }

    // return console.error(deferenceFlag)
    // if(deferenceFlag){
    //   return
    // }
    let reqObj = {
      motel_key: this.props.motelKey,
      charge: {
        charge_key: this.state.chargeKey,
        item_type: this.props.chargeCode,
        type: "BASE",
        room_charges: this.changePriceObjToArr(),
      },
    };

    //

    openConfirmPopupWithPromise(PopupConsts.C3).then((res) => {
      if (res === POPUP_ACTION_OK) {
        if (this.state.chargeKey) {
          this.modifyCharge(reqObj);
        } else {
          this.createCharge(reqObj);
        }
      }
    });
  };

  modifyCharge = (reqObj) => {
    createModifyChargesMotels(reqObj)
      .then((res) => {
        if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
          openAlertPopupWithPromise(null, res.data.desc);
        }
      })
      .catch(openHttpRejectionPopupWithPromise);
  };

  createCharge = (reqObj) => {
    createChargesMotels(reqObj)
      .then((res) => {
        if (res.data.code !== CommonConsts.SERVER_API_CODE.SUCCESS) {
          openAlertPopupWithPromise(null, res.data.desc);
        }
        this.setState({
          chargeKey: res.data.result.charge_key,
        });
      })
      .catch(openHttpRejectionPopupWithPromise);
  };

  resetPayInfo = () => {
    // alert('resetPayInfo')
    this.payRef.forEach((innerPayInfo, idxX) => {
      // console.error( this.props.payList[idxX])
      innerPayInfo.forEach((payRef, idxY) => {
        payRef.current.value = commaFormater(
          this.props.payList[idxX].room_item_prices[idxY]
        );
      });
    });
  };

  render() {
    const { classes, title, payList } = this.props;

    return (
      <>
        <BoxWrap>
          <Box>
            <h4 style={{ margin: 0 }}>{title}</h4>
          </Box>
          <Box align="right">
            <Box style={{ justifyContent: "flex-end" }} display="flex">
              {/* <Box>
                <div
                  className={"grade-box-sqaure"}
                  style={{ background: "#ccc" }}
                ></div>
                기준가
              </Box> */}

              <Box ml={3}>(단위 : 원)</Box>
            </Box>
          </Box>
          <Box mt={2}>
            <TableContainer component={Paper}>
              <Table style={{ tableLayout: "fixed" }} className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      style={{
                        borderRight: "1px solid #ddd",
                        width: "200px",
                      }}
                    >
                      <strong>객실명</strong>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      <strong>일</strong>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      <strong>월</strong>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      <strong>화</strong>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      <strong>수</strong>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      <strong>목</strong>
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ borderRight: "1px solid #ddd" }}
                    >
                      <strong>금</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>토</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {payList.map((roomData, index) => {
                    return (
                      <TableRow key={`room_pay_${roomData.key}_${index}`}>
                        <TableCell
                          align="left"
                          style={{ wordWrap: "break-word" }}
                        >
                          {roomData.name}
                        </TableCell>
                        <TableCell align="center">
                          <RoomCoastInputComponent
                            dayCode="0"
                            roomKey={roomData.key}
                            changeFunc={this.costChange}
                            inputRef={this.payRef[index][0]}
                            noMember={
                              roomData.room_item_prices &&
                              roomData.room_item_prices["0"]
                                ? roomData.room_item_prices["0"]
                                : 0
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <RoomCoastInputComponent
                            dayCode="1"
                            roomKey={roomData.key}
                            changeFunc={this.costChange}
                            inputRef={this.payRef[index][1]}
                            noMember={
                              roomData.room_item_prices &&
                              roomData.room_item_prices["1"]
                                ? roomData.room_item_prices["1"]
                                : 0
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <RoomCoastInputComponent
                            dayCode="2"
                            roomKey={roomData.key}
                            changeFunc={this.costChange}
                            inputRef={this.payRef[index][2]}
                            noMember={
                              roomData.room_item_prices &&
                              roomData.room_item_prices["2"]
                                ? roomData.room_item_prices["2"]
                                : 0
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <RoomCoastInputComponent
                            dayCode="3"
                            roomKey={roomData.key}
                            changeFunc={this.costChange}
                            inputRef={this.payRef[index][3]}
                            noMember={
                              roomData.room_item_prices &&
                              roomData.room_item_prices["3"]
                                ? roomData.room_item_prices["3"]
                                : 0
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <RoomCoastInputComponent
                            dayCode="4"
                            roomKey={roomData.key}
                            changeFunc={this.costChange}
                            inputRef={this.payRef[index][4]}
                            noMember={
                              roomData.room_item_prices &&
                              roomData.room_item_prices["4"]
                                ? roomData.room_item_prices["4"]
                                : 0
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <RoomCoastInputComponent
                            dayCode="5"
                            roomKey={roomData.key}
                            changeFunc={this.costChange}
                            inputRef={this.payRef[index][5]}
                            noMember={
                              roomData.room_item_prices &&
                              roomData.room_item_prices["5"]
                                ? roomData.room_item_prices["5"]
                                : 0
                            }
                          />
                        </TableCell>
                        <TableCell align="center">
                          <RoomCoastInputComponent
                            dayCode="6"
                            roomKey={roomData.key}
                            changeFunc={this.costChange}
                            inputRef={this.payRef[index][6]}
                            noMember={
                              roomData.room_item_prices &&
                              roomData.room_item_prices["6"]
                                ? roomData.room_item_prices["6"]
                                : 0
                            }
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {payList.length ? (
            <Box align="right" style={{ marginTop: "10px" }}>
              {/* <RedButton
                variant="contained"
                color="primary"
                //onClick={this.doSearch}
                style={{ marginLeft:'10px', width: "100px" }}
              >
                <strong>초기화</strong>
              </RedButton> */}
              <Button
                variant="contained"
                color="primary"
                onClick={this.editStandardCharge}
                style={{ marginLeft: "10px", width: "100px" }}
              >
                <strong>저장</strong>
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.resetPayInfo();
                }}
                style={{ marginLeft: "10px", width: "100px" }}
              >
                <strong>취소</strong>
              </Button>
            </Box>
          ) : (
            <>
              <Box className={classes.noRoomContentsWrapper}>
                <Box className={classes.noRoomContents}>
                  제휴점정보관리&gt;객실관리 화면에서 객실을 등록하셔야
                  <br />
                  기준요금을 설정하실 수 있습니다.
                </Box>
              </Box>
              <Box className={classes.noRoomButtonWrapper}>
                <Button
                  onClick={() => {
                    history.push("/businessInfo?tab_index=1");
                  }}
                  variant="contained"
                  color="primary"
                >
                  {/* /businessInfo?tab_index=1 */}
                  객실 등록 바로 가기
                </Button>
              </Box>
            </>
          )}
        </BoxWrap>
      </>
    );
  }
}
const StandardChargeInfo = withTheme(withStyles(styles)(StandardChargeInfoRaw));
export { StandardChargeInfo };
