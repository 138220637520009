import React, {useEffect, useState} from "react";

import { BoxWrap } from "components";
import {Button, FormControlLabel, Switch} from "@material-ui/core";
import {Settings} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {
    openConfirmPopupWithPromise,
    openHttpRejectionPopupWithPromise,
    POPUP_ACTION_OK
} from "../../../../lib/helper-popup";
import PopupConsts from "../../../../constant/PopupConsts";
import * as serviceStorage from "../../../../lib/service-storage";


/**
 * 국내 최저가 설정 템플릿
 * 꿀스테이 2.0 반영
 */

const CheapestTemplate = ({ cheapest, onRequestAjax, onRefresh}) => {

    const [isWebMaster, setIsWebMaster] = useState(false);

    useEffect(() => {
        const identity = serviceStorage.getIdentity();
        const userType = serviceStorage.getUserType(identity);

        setIsWebMaster(userType === 'WEBMASTER');
    }, []);

  const handleChangeCheapest = (event) => {
    const { checked } = event.target;

      openConfirmPopupWithPromise(checked ? PopupConsts.C14 : PopupConsts.C15).then(
          (actionType) => {
              if (actionType !== POPUP_ACTION_OK) return;
              onRequestAjax("REQUEST_postMotelCheapest", { isAvailable: checked })
                  .then(() => {
                      return onRefresh();
                  })
                  .catch(openHttpRejectionPopupWithPromise);
          }
      );
  }

    return (
      <BoxWrap>
        <div style={{ float: "left", fontSize: "17px", fontWeight: "bold" }}>
          국내 최저가
          <FormControlLabel
              style={{ paddingLeft: "20px", marginBottom: "2px" }}
              control={
                <div>
                  <Switch
                      disabled={!isWebMaster && !cheapest}
                      checked={cheapest}
                      onClick={handleChangeCheapest}
                      name="isFullParkingYN"
                      color="primary"
                  />
                </div>
              }
          />

        </div>
        <Link to={`/lowestPrice`}>
          <Button
              startIcon={<Settings />}
              variant="contained"
              color="primary"
              style={{ float: "right", fontWeight: "bold" }}
          >
            국내 최저가 설정
          </Button>
        </Link>
        <div style={{ clear: "both" }}></div>
        <div style={{ marginTop: "10px" }}>
          <h5 style={{ margin: "0", color: "#008aff" }}>
            ※ 국내 최저가 표출을 원하시면 꿀스테이 고객센터(1833-4123)로 문의하시길 바랍니다.
          </h5>
        </div>
      </BoxWrap>
    );
}

export default CheapestTemplate;