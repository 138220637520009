import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Button, FormControlLabel, Switch } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

import { Link } from "react-router-dom";

import { BoxWrap } from "components";
// import { history } from "lib/history";
import { format } from "date-fns";

import {
  POPUP_ACTION_OK,
  // openAlertPopupWithPromise,
  openConfirmPopupWithPromise,
  openHttpRejectionPopupWithPromise,
} from "lib/helper-popup";
import PopupConsts from "constant/PopupConsts";

const styles = (theme) => ({
  linkBtnWrapper: {
    top: "16px",
    right: "16px",
    zIndex: "1",
  },
  textDecoNone: {
    textDecoration: "none",
  },
});

const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
}))(Button);

/**
 * 빠른 설정 Template Component 입니다.<p/>
 *
 * @author Taesung Park <pts@pineone.com>
 * @name QuickSettingTemplate
 * @component
 */
class QuickSettingTemplate extends React.PureComponent {
  handleSwitchingCloseYN = (evt) => {
    evt.preventDefault();
    const { name, checked } = evt.target;

    // const msg = `${
    //   checked ? "만실 설정 하시겠습니까?" : "만실 설정을 해제하시겠습니까?"
    // }`;

    const isYN = checked ? "Y" : "N";

    openConfirmPopupWithPromise(
      checked
        ? name === "stay"
          ? PopupConsts.C5
          : PopupConsts.C5_1
        : name === "stay"
        ? PopupConsts.C10
        : PopupConsts.C10_1
    ).then((actionType) => {
      if (actionType !== POPUP_ACTION_OK) return;
      // const dates = [format(new Date(), "yyyyMMdd")];
      Promise.all([
        this.props.onRequestAjax(
          "createModifySettingsMotels",
          Object.assign(
            {},
            name === "stay" ? { stay_close_yn: isYN } : { rent_close_yn: isYN }
          )
        ),
      ])
        .then((res) => {
          // openAlertPopupWithPromise(
          //   checked ? PopupConsts.A61 : PopupConsts.A62
          // );
          return this.props.onRefresh();
        })
        .catch(openHttpRejectionPopupWithPromise);
    });
  };

  handleSwitchingFullParkingYN = (evt) => {
    evt.preventDefault();
    const {
      // name,
      checked,
    } = evt.target;

    // const msg = `${
    //   checked ? "만차 설정 하시겠습니까?" : "만차 설정을 해제하시겠습니까?"
    // }`;
    const isYN = checked ? "Y" : "N";

    openConfirmPopupWithPromise(checked ? PopupConsts.C6 : PopupConsts.C9).then(
      (actionType) => {
        if (actionType !== POPUP_ACTION_OK) return;
        const dates = [format(new Date(), "yyyyMMdd")];
        Promise.all([
          this.props.onRequestAjax("createModifySettingsMotels", {
            full_parking_yn: isYN,
          }),
        ])
          .then((res) => {
            // openAlertPopupWithPromise(
            //   checked ? PopupConsts.A63 : PopupConsts.A62
            // );
            return this.props.onRefresh();
          })
          .catch(openHttpRejectionPopupWithPromise);
      }
    );
  };

  render() {
    const { classes, model, modelDetail } = this.props;
    const { isStayCloseYN, isRentCloseYN, isFullParkingYN } = model;
    // const parkingYn = !!modelDetail && modelDetail.detailData.parking_yn;

    return (
      <BoxWrap title="빠른 설정">
        <Box position="absolute" className={classes.linkBtnWrapper}>
          <Link to={`/settings`} className={classes.textDecoNone}>
            <WhiteButton variant="contained" color="primary" size="small">
              <strong>제휴점 설정 바로가기</strong>
            </WhiteButton>
          </Link>
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={isStayCloseYN}
                onClick={this.handleSwitchingCloseYN}
                name="stay"
                color="primary"
              />
            }
            label={<Box ml={2}>오늘 숙박 만실(오늘 숙박 영업 종료)</Box>}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={isRentCloseYN}
                onClick={this.handleSwitchingCloseYN}
                name="rent"
                color="primary"
              />
            }
            label={<Box ml={2}>오늘 대실 만실(오늘 대실 영업 종료)</Box>}
          />
        </Box>
        <Box>
          <FormControlLabel
            control={
              <div>
                <Switch
                  checked={isFullParkingYN}
                  onClick={this.handleSwitchingFullParkingYN}
                  name="isFullParkingYN"
                  color="primary"
                />
              </div>
            }
            label={<Box ml={2}>오늘 주차장 만차</Box>}
          />
          {/* PLATFORM-768 */}
          {/* 추후 오픈 예정 - 2021.08.18 양태욱 */}
          {/* {parkingYn === "Y" ? (
            <FormControlLabel
              control={
                <div>
                  <Switch
                    checked={isFullParkingYN}
                    onClick={this.handleSwitchingFullParkingYN}
                    name="isFullParkingYN"
                    color="primary"
                  />
                </div>
              }
              label={<Box ml={2}>오늘 주차장 만차</Box>}
            />
          ) : (
            <FormControlLabel
              style={{ cursor: "default" }}
              control={
                <div style={{ visibility: "hidden" }}>
                  <Switch />
                </div>
              }
              label={<Box ml={2}>주차장 미보유</Box>}
            />
          )} */}
        </Box>
      </BoxWrap>
    );
  }
}

// 초기 props 설정
QuickSettingTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default withTheme(withStyles(styles)(QuickSettingTemplate));
