/**
 * 팝업 String
 *
 * @version 0.0.1
 * @author <max5500@pineone.com>
 * @module constants/PopupConsts
 */

const POPUP_CONSTS_PC = {
  DAYCHARGE_TODAY: {
    title: null,
    desc: "오늘부터 특정일 요금이 적용되며,\n 기존 판매가는 변경됩니다. \n계속 진행하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  DELETED_BOARD: {
    title: null,
    desc: "존재하지 않는 게시물입니다.",
    button: null,
  },
  DIFFERENT_MOTEL_KEY: {
    title: null,
    desc: "잘못된 접근입니다.",
    button: null,
  },
  PAST_DATE: {
    title: null,
    desc: "이미 지난 날짜입니다.",
    button: null,
  },
  SAME_DAYCHARGE: {
    title: null,
    desc: "해당 기간에 특별일 요금이 존재합니다.",
    button: null,
  },
  INPUT_CHARACTER_VALUE: {
    title: null,
    desc: "이용 요금은 숫자만 입력가능합니다.",
    button: null,
  },
  INPUT_ZERO_VALUE: {
    title: null,
    desc: "입력하신 금액을 확인해 주세요.\n판매가는 ‘0원’을 입력하실 수 없습니다.",
    button: null,
  },
  LOGOUT: {
    title: null,
    desc: "로그아웃 하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  A1: {
    title: null,
    desc: "필수 사항은 기재해주셔야 합니다.",
    button: null,
  },
  A2: {
    title: null,
    desc: "이미지는 jpg, jpeg, png의 확장자만\n등록 가능합니다.",
    button: "재설정",
  },
  A3: {
    title: null,
    desc: "파일은 doc, xls, xlsx, zip, pdf의 확장자만\n등록 가능합니다.",
    button: null,
  },
  A4: {
    title: "null",
    desc: "초기화 되었습니다.",
    button: null,
  },
  A5: {
    title: "null",
    desc: "비밀번호 5회 오류로 사용이\n제한되었습니다. 비밀번호 재설정 후\n이용해 주세요.",
    button: null,
  },
  A6: {
    title: null,
    desc: "입력하신 휴대폰번호로 수정되었습니다.",
    button: null,
  },
  A7: {
    title: null,
    desc: "비밀번호가 재설정되었습니다.\n새 비밀번호로 로그인 해주세요.",
    button: null,
  },
  A8: {
    title: null,
    desc: "최대 5명까지 등록하실 수 있습니다.",
    button: null,
  },

  A9: {
    title: null,
    desc: "최대 10명까지 등록하실 수 있습니다.",
    button: null,
  },

  A10: {
    title: null,
    desc: "검색한 아이디의 직원 정보가 없습니다.",
    button: null,
  },
  A11: {
    title: null,
    desc: "새로운 비밀번호를 입력하신\n휴대폰번호로 발송하였습니다.",
    button: null,
  },
  A12: {
    title: null,
    desc: "아이디와 휴대폰번호를 확인해주세요.",
    button: null,
  },
  A13: {
    title: null,
    desc: "새로운 비밀번호를 입력하신\n이메일주소로 발송하였습니다.",
    button: null,
  },
  A14: {
    title: null,
    desc: "필수 약관을 동의하셔야 다음 단계로\n진행 가능합니다.",
    button: null,
  },
  A15: {
    title: null,
    desc: "적합하지 않은 이메일 형식입니다.\n다시 입력해 주세요.",
    button: null,
  },
  A16: {
    title: null,
    desc: "회원가입된 휴대폰번호입니다.\n확인하시고 기입해주세요.",
    button: null,
  },
  A17: {
    title: null,
    desc: "정상 회원가입이 되셨습니다.\n사장님께서는 제휴신청 단계를\n진행해주세요.",
    button: null,
  },
  A18: {
    title: null,
    desc: "정상 회원가입이 되었습니다.\n사장님이 직원 등록 후 로그인하실 수\n있습니다.",
    button: null,
  },
  A19: {
    title: null,
    desc: "파일 용량이 초과되었습니다.\n10MB 이하로 업로드 해주세요.",
    button: null,
  },

  A20: {
    title: null,
    desc: "기존 등록된 아이디가 있습니다.\n확인해주세요.",
    button: null,
  },
  A21: {
    title: null,
    desc: "해당 아이디는 사용 가능합니다.",
    button: null,
  },
  A22: {
    title: null,
    desc: "아이디 중복 확인을 진행해주세요.",
    button: null,
  },
  A23: {
    title: null,
    desc: "본사 승인 요청을 하였습니다.",
    button: null,
  },
  A24: {
    title: null,
    desc: "최대 5개까지 발행할 수 있습니다.",
    button: null,
  },
  A25: {
    title: null,
    desc: "날짜를 선택하고 판매 버튼을\n눌러주세요.",
    button: null,
  },
  A26: {
    title: null,
    desc: "날짜를 선택하고 마감 버튼을\n눌러주세요.",
    button: null,
  },
  A27: {
    title: null,
    desc: "날짜를 선택하고 판매설정 버튼을\n눌러 주세요.",
    button: null,
  },
  A28: {
    title: null,
    desc: "입력하신 판매가를 확인해 주세요.\n10,000원 이하의 판매가는 입력할 수 없습니다.",
    button: null,
  },
  A29: {
    title: null,
    desc: "해당글은 베스트 후기로 선정되었습니다.",
    button: null,
  },
  A30: {
    title: null,
    desc: "객실수, 최소인원, 최대인원 입력은\n숫자만 입력가능합니다.",
    button: null,
  },
  A31: {
    title: null,
    desc: "사진 용량이 초과되었습니다\n.5MB 이하로 업로드 해주세요.",
    button: null,
  },
  A32: {
    title: null,
    desc: "카테고리별 업로드 가능수량은\n40장입니다.",
    button: null,
  },
  A33: {
    title: null,
    desc: "이벤트명을 입력해 주세요.",
    button: null,
  },
  A34: {
    title: null,
    desc: "요일을 선택해주세요.",
    button: null,
  },
  A35: {
    title: null,
    desc: "쿠폰을 선물할 고객을 선택해주세요.",
    button: null,
  },
  A36: {
    title: null,
    desc: "찾으시는 닉네임이 존재하지 않습니다.",
    button: null,
  },
  A37: {
    title: null,
    desc: "찾으시는 자료가 없습니다.",
    button: null,
  },
  A38: {
    title: null,
    desc: "쿠폰 발행은 주 1회 발급 가능합니다.",
    button: null,
  },
  A39: {
    title: null,
    desc: "사진 용량이 초과되었습니다.\n5MB 이하로 업로드 해주세요.",
    button: null,
  },
  A40: {
    title: null,
    desc: "이메일주소를 입력해 주세요.",
    button: null,
  },
  A41: {
    title: null,
    desc: "동일한 이메일주소가 있습니다.\n다시 입력해 주세요.",
    button: null,
  },
  A42: {
    title: null,
    desc: "파일 업로드 가능 수량은 3개까지 입니다.\n그 이상은 등록하실 수 없습니다.",
    button: null,
  },
  A43: {
    title: null,
    desc: "기준 요금은 숫자만 입력하실 수 \n있습니다.",
    button: null,
  },
  A47: {
    title: null,
    desc: "파일 업로드 가능 수량은 10개까지 입니다.\n그 이상은 등록하실 수 없습니다.",
    button: null,
  },

  A49: {
    title: null,
    desc: "기준 요금을 입력해 주세요.\n기준 요금 가격이 없으면 저장할 수\n없습니다.",
    button: null,
  },
  A50: {
    title: null,
    desc: "(객실명 외 0건)의 객실 일부 요일\n기준요금이 ‘0원＇상태입니다.\n확인하시고 입력해 주세요. ",
    button: null,
  },
  A51: {
    title: null,
    desc: "인증번호가 일치하지 않습니다.\n확인하시고 다시 입력해 주세요.",
    button: null,
  },
  A52: {
    title: null,
    desc: "인증시간이 만료되었습니다.\n다시 입력해 주세요.",
    button: null,
  },
  A53: {
    title: null,
    desc: "전송요청횟수가 초과되었습니다.\n다시 인증요청을 진행해 주세요.",
    button: null,
  },
  A54: {
    title: null,
    desc: "제휴점관리 메뉴로 이동하셔서\n객실정보와 판매가를 모두 입력하신 후\n판매설정 버튼을 눌러주세요.",
    button: null,
  },
  A55: {
    title: null,
    desc: "제휴점 관리 메뉴로 이동하셔서\n객실 정보와 판매가를 모두 입력하신 후\n쿠폰 설정 버튼을 눌러주세요.",
    button: null,
  },
  A61: {
    title: null,
    desc: "만실 설정되었습니다.",
    button: null,
  },
  A62: {
    title: null,
    desc: "설정 해제되었습니다.",
    button: null,
  },
  A63: {
    title: null,
    desc: "만차 설정되었습니다.",
    button: null,
  },
  A164: {
    title: null,
    desc: "작성된 내용이 없습니다.",
    button: null,
  },
  A165: {
    title: null,
    desc: "날짜를 선택하고 쿠폰 적용 버튼을\n눌러주세요.",
    button: null,
  },
  A166: {
    title: null,
    desc: "날짜를 선택하고 쿠폰 마감 버튼을\n눌러주세요.",
    button: null,
  },
  A167: {
    title: null,
    desc: "날짜를 선택하고 쿠폰설정 버튼을\n눌러주세요.",
    button: null,
  },
  A168: {
    title: null,
    desc: "검색어를 입력해 주세요.",
    button: null,
  },
  A169: {
    title: null,
    desc: "특정일이 포함되어 있습니다.\n쿠폰 변경 시 주의가 필요합니다.",
    button: null,
  },
  A170: {
    title: null,
    desc: "변경 사항이 없습니다.",
    button: null,
  },
  A171: {
    title: null,
    desc: "쉼표(,) 앰퍼샌드(&) 를 제외한 검색어를 입력해 주세요.",
    button: null,
  },
  A172: {
    title: null,
    desc: "판매 링크명과 판매링크 주소는 필수 사항입니다.",
    button: null,
  },
  A173: {
    title: null,
    desc: "정상적으로 처리되었습니다.",
    button: null,
  },
  A174: {
    title: null,
    desc: "입력된 값 중 중복된 키워드가 있습니다.",
    button: null,
  },
  A175: {
    title: null,
    desc: "이미 등록된 키워드입니다.",
    button: null,
  },
  A176: {
    title: null,
    desc: "키워드는 최대 10자까지 등록가능합니다",
    button: null,
  },
  A177: {
    title: null,
    desc: "요청 키워드가 없습니다.",
    button: null,
  },
  A178: {
    title: null,
    desc: '조회하신 일자의 데이터가 존재하지 않습니다. \n조회 일자를 확인해 주세요.',
    button: null
  },
  ENTER_COMPLETED: {
    title: null,
    desc: "입실이 맞는지 확인 후 변경해 주세요. \n 잘못 체크하시면 상태값 변경이 불가하며\n매출, 정산금액, 마일리지에 오류가\n발생할 수 있습니다.",
    button: null,
  },
  ENTER_NO_SHOW: {
    title: null,
    desc: "No Show가 맞는지 확인 후 변경해 주세요.\n 잘못 체크하시면 상태값 변경이 불가합니다.\n예약자에게도 No Show 확인 알림톡이\n발송되며, 예약자는 앞으로 한달 간 현장결제를\n이용할 수 없게 됩니다.",
    button: null,
  },
  C1: {
    title: null,
    desc: "설정한 값으로 적용하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C2: {
    title: null,
    desc: "전체 초기화 하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C3: {
    title: null,
    desc: "위 내용으로 저장하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C4: {
    title: null,
    desc: "선택한 항목을 삭제하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C5: {
    title: null,
    desc: "전 객실을 숙박 마감하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C5_1: {
    title: null,
    desc: "전 객실을 대실 마감하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C6: {
    title: null,
    desc: "주차장 만차로 설정 하시겠습니까?\n고객이 차량 방문으로 예약할 수 없으며, 도보로만 예약 가능합니다. 설정하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C7: {
    title: null,
    desc: "연박 설정 하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C8: {
    title: null,
    desc: "이벤트 기간이 남아 있습니다. 목록에서\n삭제되므로 유의해주세요.\n이벤트를 종료하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C9: {
    title: null,
    desc: "주차장 만차 설정을 해제하시겠습니까?\n고객은 차량 방문으로 예약할 수 있습니다.",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C10: {
    title: null,
    desc: "전 객실을 숙박 판매하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C10_1: {
    title: null,
    desc: "전 객실을 대실 판매하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C11: {
    title: null,
    desc: "선택한 이미지를 등록하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C12: {
    title: null,
    desc: "선택한 이미지를 삭제하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C13: {
    title: null,
    desc: "연박 설정을 해제하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C14: {
    title: null,
    desc: "국내 최저가를 노출상태로 변경하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C15: {
    title: null,
    desc: "국내 최저가를 비노출상태로 변경하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C16: {
    title: null,
    desc: "요청하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C17: {
    title: null,
    desc: "키워드 요청을 취소하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C18: {
    title: null,
    desc: "키워드를 삭제하시겠습니까?",
    buttonConfirm: null,
    buttonCancel: null,
  },
  C19: {
    title: null,
    desc: "이미 삭제된 키워드 입니다.",
    buttonConfirm: null,
    buttonCancel: null,
  }
};

export default POPUP_CONSTS_PC;
