/**
 * 본 모듈은 사업자 등록 번호 형식이 유효한지 체크 하는 모듈 입니다<p/>
 *
 * <h3>사업자 등록번호 구조</h3>
 *
 * <pre>
 * 사업자 등록번호는 123-45-67890 의 10 자리 구조입니다.
 *
 * 123 : 국세청 / 세무서별 코드
 * 45 : 개인 법인 구분코드
 * 6789 : 과세/면세/법인 사업자 등록/지정일자 일련번호
 * 0 : 검증번호
 * </pre>
 *
 * @module utils/checkBusinessNumber
 */

/**
 * 전달 된 사업자 등록 번호 형식을 검사하고, 유효 여부를 true/false로 반환 합니다
 *
 * @param {string} bussinessNum - 사업자 등록 번호
 * @return {boolean} 유효 여부
 */
export default function checkBusinessNumber(bussinessNum) {
  if (!bussinessNum) return false;

  // 참고) https://tyson.tistory.com/116

  // 넘어온 값의 정수만 추츨하여 문자열의 배열로 만들고 10자리 숫자인지 확인합니다.
  if ((bussinessNum = (bussinessNum + "").match(/\d{1}/g)).length !== 10)
    return false;

  // 합 / 체크키
  let sum = 0;
  let key = [1, 3, 7, 1, 3, 7, 1, 3, 5];

  // 0 ~ 8 까지 9개의 숫자를 체크키와 곱하여 합에 더합니다.
  for (var i = 0; i < 9; i++) {
    sum += key[i] * Number(bussinessNum[i]);
  }

  // 각 8번배열의 값을 곱한 후 10으로 나누고 내림하여 기존 합에 더합니다.
  // 다시 10의 나머지를 구한후 그 값을 10에서 빼면 이것이 검증번호 이며 기존 검증번호와 비교하면됩니다.
  // 체크섬구함

  let chkSum = 0;
  chkSum = Math.floor((key[8] * Number(bussinessNum[8])) / 10);

  // 체크섬 합계에 더해줌
  sum += chkSum;

  let reminder = (10 - (sum % 10)) % 10;

  //값 비교
  if (reminder === Number(bussinessNum[9])) return true;

  return false;
}
