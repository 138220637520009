import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

/**
 * 별점 Component 입니다.<p/>
 *
 * @author Drst3in <drst3in9196@gmail.com>
 * @name StarRate
 * @class
 * @component
 */

const styles = (theme) => ({
  starRatings: {
    unicodeBidi: "bidi-override",
    color: "#c5c5c5",
    fontSize: "1.6em",
    margin: "0 auto",
    position: "relative",
    padding: "0",
    textShadow: "0px 1px 0 #a2a2a2",
  },
  starRatingsTop: {
    color: "#ffc903",
    padding: "0",
    position: "absolute",
    zIndex: "1",
    display: "block",
    top: "0",
    left: "0",
    overflow: "hidden",
  },
  starRatingsBottom: {
    padding: "0",
    display: "block",
    zIndex: "0",
  },
});

class StarRate extends Component {
  constructor(props) {
    super(props);
    console.log("StarRate component ; ", props);

    this.state = {
      error: false,
    };

    this.startPrefetch = this.startPrefetch.bind(this);
  }

  startPrefetch(starPoint) {
    return (starPoint * 100) / 5 + "%";
  }

  render() {
    const { classes, starPoint = "0", fontSize = "1.8em" } = this.props;

    return (
      <Box display="inline-block">
        <Box className={classes.starRatings} style={{ fontSize: fontSize }}>
          <Box
            className={classes.starRatingsTop}
            width={this.startPrefetch(starPoint)}
          >
            <span>★</span>
            <span>★</span>
            <span>★</span>
            <span>★</span>
            <span>★</span>
          </Box>
          <Box className={classes.starRatingsBottom}>
            <span>★</span>
            <span>★</span>
            <span>★</span>
            <span>★</span>
            <span>★</span>
          </Box>
        </Box>
      </Box>
    );
  }
}

const styledStarRate = withStyles(styles)(StarRate);

export { styledStarRate as StarRate };
