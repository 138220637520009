import React from "react";

import { format } from "date-fns";

import * as serviceStorage from "lib/service-storage";
import {
  getBooksMotels,
  getSalesRoomsMotels,
  getSettingsMotels,
  getDetailMotels,
  getReviewsMotels,
  getItemsBoards,
  getServiceMotels,
  getCoupons,
  createModifySettingsMotels,
  createCoupons,
  createSalesMotels,
  createSalesRoomsMotels,
  getChargesMotels,
  createCancelDailyCoupons,
  getChannelsMotelsRooms,
  getMotelCheapest,
  postMotelCheapest
} from "lib/service-api";

/**
 * 메인페이지의 Module Component 입니다.<p/>
 *
 * 해당 Component는 서버 연동 및 공통 기능을 제공 합니다.
 *
 * @author Taesung Park <pts@pineone.com>
 * @name MainPageContainer
 * @class
 * @component
 */
class MainPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.requestTodayTimeStamp = 0;
    this.modelKey = serviceStorage.getConnectedMotelKey();
  }

  getTodayDate = (params) => {
    if (!!params && !!params.todayDate) {
      return params.todayDate;
    }
    this.requestTodayTimeStamp = Date.now() - 3600 * 3 * 1000;
    return format(new Date(this.requestTodayTimeStamp), "yyyyMMdd");
  };

  handleOnRequestAjax = (type, params, isHideLoading) => {
    /* 20201202 박태성C
     숙박앱은 기본적으로 `서버 연동` 시 공통 모듈(provider-api.js)에서
     로딩바의 출력 여부를 제어 함.

     일부 시나리오에서 로딩바 없이 서버연동이 처리가 필요한 경우가 있어
     서버통신 서비스 모듈(service-api.js)에서 제공하는 인터페이스에
     hideLoading를 true로 주면 로딩바가 출력 되지 않도록 처리 함
    */
    const hideLoading = isHideLoading || false; // 로딩바 숨김 여부
    // const hideLoading = true;
    switch (type) {
      // 12시간 내 입실 예정 내역
      case "REQUEST_modelReservation":
        let booksData = [];
        return getBooksMotels(
          {
            motel_key: this.modelKey,
            search_type: "ST601",
            search_extra: "",
          },
          hideLoading
        )
          .then((res) => {
            if (!!res.data && !!res.data.result && !!res.data.result.orders) {
              booksData = res.data.result.orders;
            }
            return Promise.resolve({
              modelReservation: { booksData },
            });
          })
          .catch(() => Promise.resolve({ modelReservation: { booksData } }))
          .finally(() => {
            booksData = null;
          });
      // break;
      case "REQUEST_modelRoomStatus":
        let dataSalesRoomRental = [];
        let dataSalesRoom = [];
        let todaySalesPriceRental = 0;
        let todaySalesPrice = 0;
        let todaySalesDate = this.getTodayDate(params);
        return Promise.all([
          getSalesRoomsMotels(
            {
              motel_key: this.modelKey,
              search_type: "ST1602",
              search_extra: todaySalesDate, //룸 판매 설정 목록 특정일 검색	검색일 (yyyyMMdd)
              item_type: "010101", //룸:010100, 대실: 010101, 숙박: 010102
            },
            hideLoading
          ),
          getSalesRoomsMotels(
            {
              motel_key: this.modelKey,
              search_type: "ST1602",
              search_extra: todaySalesDate, //룸 판매 설정 목록 특정일 검색	검색일 (yyyyMMdd)
              item_type: "010102", //룸:010100, 대실: 010101, 숙박: 010102
            },
            hideLoading
          ),
          // 기준요금 조회
          getChargesMotels({
            motel_key: this.modelKey,
            search_type: "ST905",
            search_extra: "BASE",
          }),
        ])
          .then((res) => {
            let resultCharges = res[2].data.result.charges;
            // 설정 된 기준 요금
            let chargeLen = resultCharges.filter(
              (charge) => charge.charge_key === null
            ).length;

            // 설정된 기준요금이 있을 경우
            if (1 > chargeLen) {
              let keysRental = "|";
              let keysRentalUnsold = "|"; // 기준 요금이 0원인 [대실 객실]
              let keysStay = "|";
              let keysStayUnsold = "|"; // 기준 요금이 0원인 [숙박 객실]

              // 대실 - 기준 요금이 0원, 기준 요금이 설정 된 객실 키 추출
              (
                resultCharges.find((charge) => charge.item_type === "010101")
                  .room_charges || []
              ).forEach((roomCharges) => {
                if (!!roomCharges.room_item_prices) {
                  const unsoldRentalSize = roomCharges.room_item_prices.filter(
                    (price) => parseInt(price.value) === 0
                  ).length;
                  if (unsoldRentalSize > 1) {
                    keysRentalUnsold = keysRentalUnsold + `${roomCharges.key}|`;
                  } else {
                    keysRental = keysRental + `${roomCharges.key}|`;
                  }
                }
              });

              // 숙박 - 기준 요금이 0원, 기준 요금이 설정 된 객실 키 추출
              (
                resultCharges.find((charge) => charge.item_type === "010102")
                  .room_charges || []
              ).forEach((roomCharges) => {
                if (!!roomCharges.room_item_prices) {
                  const unsoldRentalSize = roomCharges.room_item_prices.filter(
                    (price) => parseInt(price.value) === 0
                  ).length;
                  if (unsoldRentalSize > 1) {
                    keysStayUnsold = keysStayUnsold + `${roomCharges.key}|`;
                  } else {
                    keysStay = keysStay + `${roomCharges.key}|`;
                  }
                }
              });

              let result;
              //오늘 객실 현황 - 대실
              if (!!res[0].data && !!res[0].data.result) {
                result = res[0].data.result;
                if (result.daily_room_sales.length !== 0) {
                  if (!!result.daily_room_sales[0].room_sales) {
                    dataSalesRoomRental =
                      result.daily_room_sales[0].room_sales.filter(
                        (roomSales) =>
                          0 >
                            keysRentalUnsold.indexOf(
                              `|${roomSales.room_key}|`
                            ) &&
                          -1 < keysRental.indexOf(`|${roomSales.room_key}|`)
                      );
                  }
                  todaySalesPriceRental =
                    result.daily_room_sales[0].sales_price || 0;
                }
              }

              //오늘 객실 현황 - 숙박
              if (!!res[1].data && !!res[1].data.result) {
                result = res[1].data.result;
                if (result.daily_room_sales.length !== 0) {
                  if (!!result.daily_room_sales[0].room_sales) {
                    dataSalesRoom =
                      result.daily_room_sales[0].room_sales.filter(
                        (roomSales) =>
                          0 >
                            keysStayUnsold.indexOf(`|${roomSales.room_key}|`) &&
                          -1 < keysStay.indexOf(`|${roomSales.room_key}|`)
                      );
                  }
                  todaySalesPrice = result.daily_room_sales[0].sales_price || 0;
                }
              }
              result = null;
              keysRental = null;
              keysRentalUnsold = null;
              keysStay = null;
              keysStayUnsold = null;
            }

            resultCharges.splice(0, resultCharges.length);
            resultCharges = null;

            return Promise.resolve({
              modelRoomStatus: {
                dataSalesRoomRental,
                todaySalesPriceRental,
                dataSalesRoom,
                todaySalesPrice,
                todaySalesDate,
              },
            });
          })
          .catch(() =>
            Promise.resolve({
              modelRoomStatus: {
                dataSalesRoomRental,
                todaySalesPriceRental,
                dataSalesRoom,
                todaySalesPrice,
                todaySalesDate,
              },
            })
          )
          .finally(() => {
            dataSalesRoomRental = null;
            dataSalesRoom = null;
          });

      // break;
      case "REQUEST_modelReview":
        let reviewData = []; //최근 후기
        let reviewCount = 0; //최근 후기 갯수
        return getReviewsMotels(
          {
            motel_key: this.modelKey,
            search_type: "",
            search_extra: "", //고객후기 - 기간	시작일(YYYYMMDD)|종료일(YYYYMMDD) 기간 사이의 구분 값은 "|"
            sort: "LATEST",
            count: 3,
            cursor: "0",
          },
          hideLoading
        )
          .then((res) => {
            let result;
            if (!!res.data && !!res.data.result) {
              result = res.data.result;
              if (!!result.reviews) {
                reviewData = result.reviews;
              }
              reviewCount = result.total_count || 0;
            }
            result = null;
            return Promise.resolve({
              modelReview: { reviewData, reviewCount },
            });
          })
          .catch(() =>
            Promise.resolve({
              modelReview: { reviewData, reviewCount },
            })
          )
          .finally(() => {
            reviewData = null;
          });
      // break;
      case "REQUEST_modelNotice":
        let noticeData = [];
        return getItemsBoards(
          {
            board_type: "NOTICE_O", //게시판 유형
            search_type: "ST1201", //검색 조건
            search_extra: "", //검색 조건별 데이터
            sort: "LATEST",
            count: 5,
            cursor: "0",
          },
          hideLoading
        )
          .then((res) => {
            let result;
            if (!!res.data && !!res.data.result) {
              result = res.data.result;
              if (!!result.board_items) {
                noticeData = result.board_items;
              }
            }
            result = null;
            return Promise.resolve({
              modelNotice: { noticeData },
            });
          })
          .catch(() =>
            Promise.resolve({
              modelNotice: { noticeData },
            })
          )
          .finally(() => {
            noticeData = null;
          });
      // break;
      case "REQUEST_modelService":
        let serviceData = {};
        return getServiceMotels(
          {
            motel_key: this.modelKey,
          },
          hideLoading
        )
          .then((res) => {
            let result;
            if (!!res.data && !!res.data.result) {
              result = res.data.result;
              if (!!result.motel_service) {
                serviceData = result.motel_service;
              }
            }
            result = null;
            return Promise.resolve({
              modelService: { serviceData },
            });
          })
          .catch(() =>
            Promise.resolve({
              modelService: { serviceData },
            })
          )
          .finally(() => {
            serviceData = null;
          });
      // break;
      case "REQUEST_modelCoupon":
        let firstComeCouponTodayHistory = [];
        let firstComeCouponInfo = {};
        return getCoupons(
          {
            motel_key: this.modelKey,
          },
          hideLoading
        )
          .then((res) => {
            let result;
            if (!!res.data && !!res.data.result) {
              result = res.data.result;
              if (!!result.firstComeCouponTodayHistory) {
                  firstComeCouponTodayHistory = result.firstComeCouponTodayHistory;
              }
              if (!!result.firstComeCouponInfo) {
                  firstComeCouponInfo = result.firstComeCouponInfo;
              }
            }
            result = null;
            return Promise.resolve({
              modelCoupon: {
                firstComeCouponTodayHistory,
                firstComeCouponInfo,
              },
            });
          })
          .catch(() =>
            Promise.resolve({
              modelCoupon: { firstComeCouponTodayHistory, firstComeCouponInfo },
            })
          )
          .finally(() => {
              firstComeCouponTodayHistory = null;
              firstComeCouponInfo = null;
          });
      // break;
      case "REQUEST_modelQuickSetting":
        let settingData = null;
        let isStayCloseYN = false;
        let isRentCloseYN = false;
        let isFullParkingYN = false;
        return getSettingsMotels(
          {
            motel_key: this.modelKey,
          },
          hideLoading
        )
          .then((res) => {
            let result;
            if (!!res.data && !!res.data.result) {
              result = res.data.result;
              if (!!result.motel_setting) {
                settingData = result.motel_setting;
              }
              isStayCloseYN = settingData.stay_close_yn === "Y";
              isRentCloseYN = settingData.rent_close_yn === "Y";
              isFullParkingYN = settingData.full_parking_yn === "Y";
            }
            result = null;
            return Promise.resolve({
              modelQuickSetting: {
                settingData,
                isStayCloseYN,
                isRentCloseYN,
                isFullParkingYN,
              },
            });
          })
          .catch(() =>
            Promise.resolve({
              modelQuickSetting: {
                settingData,
                isStayCloseYN,
                isRentCloseYN,
                isFullParkingYN,
              },
            })
          )
          .finally(() => {
            settingData = null;
          });
      // break;
      case "REQUEST_modelChannelOut":
        let storeChannelOutYn = "N";

        return getChannelsMotelsRooms(
          {
            motel_key: this.modelKey,
          },
          hideLoading
        )
          .then((res) => {
            let result;
            if (!!res.data && !!res.data.result) {
              result = res.data.result;
              console.log("drst3in", result);
              if (!!result.store_channel_out_yn) {
                storeChannelOutYn = result.store_channel_out_yn;
              }
            }
            result = null;
            return Promise.resolve({
              modelChannelOut: {
                storeChannelOutYn: storeChannelOutYn,
              },
            });
          })
          .catch(() =>
            Promise.resolve({
              modelChannelOut: {
                storeChannelOutYn: storeChannelOutYn,
              },
            })
          )
          .finally(() => {
            detailData = null;
          });
      // break
        case "REQUEST_modelCheapest":
            return getMotelCheapest({storeKey: this.modelKey}, hideLoading)
                .then((res) => {
                    if (!!res.data && !!res.data.result) {
                        const result = res.data.result;

                        return Promise.resolve({
                            cheapest: result.result.isAvailable
                        })
                    }
                })
        case "REQUEST_postMotelCheapest":

            const data = {
                storeKey: this.modelKey,
                isAvailable: params.isAvailable
            }

            return postMotelCheapest(data, hideLoading)
                .then((res) => {
                    if (!!res.data && !!res.data.result) {
                        return Promise.resolve({
                            cheapest: params.isAvailable
                        })
                    }
                })
      case "REQUEST_modelDetail":
        let detailData = null;
        let isSitePaymentYN = false;
        let isRegionCoupon = false;
        return getDetailMotels(
          {
            motel_key: this.modelKey,
          },
          hideLoading
        )
          .then((res) => {
            let result;
            if (!!res.data && !!res.data.result) {
              result = res.data.result;
              if (!!result.motel) {
                detailData = result.motel;
              }
              isSitePaymentYN = detailData.site_payment_yn === "Y";
              isRegionCoupon = detailData.region_coupon_yn === "Y";
            }
            result = null;
            return Promise.resolve({
              modelDetail: {
                detailData,
                isSitePaymentYN,
                isRegionCoupon,
              },
            });
          })
          .catch(() =>
            Promise.resolve({
              modelDetail: {
                detailData,
                isSitePaymentYN,
                isRegionCoupon,
              },
            })
          )
          .finally(() => {
            detailData = null;
          });
      // break;
      case "createModifySettingsMotels":
        if (!!params) {
          params.motel_key = this.modelKey;
          return createModifySettingsMotels(params, hideLoading);
        }
        break;
      case "createCoupons":
        if (!!params) {
          params.motel_key = this.modelKey;
          return createCoupons(params, hideLoading);
        }
        break;
      case "createSalesMotels":
        if (!!params) {
          params.motel_key = this.modelKey;
          return createSalesMotels(params, hideLoading);
        }
        break;
      case "createSalesRoomsMotels":
        if (!!params) {
          params.motel_key = this.modelKey;
          return createSalesRoomsMotels(params, hideLoading);
        }
        break;
      case "createDeleteDailyCoupons":
        return createCancelDailyCoupons(
          { motel_key: this.modelKey, ...params },
          hideLoading
        );
      default:
        break;
    }

    console.error(type, "\n", params);
    return Promise.reject(
      new Error("Undefined Command or Params value is none.")
    );
  };

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const Component = this.props.component;
    if (!Component) return null;

    return (
      <>
        <Component
          onRequestAjax={this.handleOnRequestAjax}
          onGetTodayDate={this.getTodayDate}
        />
      </>
    );
  }
}

export default MainPageContainer;
