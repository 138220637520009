import { makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { getIsMaster } from "lib/service-storage";
const useStyles = makeStyles((theme) => ({
  badgeContainer: {
    width: "90px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontWeight: "bold",
    borderRadius: "20px",
    fontSize: "15px",
  },
  hotel: {
    backgroundColor: "#FF4841",
  },
}));
/**
 * @desc 해당 제휴점이 호텔일 경우 나타내는 Badge입니다.
 */
const AppLowestPriceBadge = ({style}) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(
        classes.badgeContainer,
        classes.hotel
      )}
      style={{
        ...style,
      }}
    > <span>국내 최저가</span> </div>
  );
};

export default AppLowestPriceBadge;
