import React, { Component } from "react";
import { Link } from "react-router-dom";
import Parser from "html-react-parser";
import { withStyles } from "@material-ui/core/styles";
import { Box, IconButton, Divider } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { format } from "date-fns";

/**
 * 알람 Component 입니다.<p/>
 *
 * @author <max5500@pineone.com>
 * @name HeaderAlarm
 * @class
 * @component
 */

const styles = (theme) => ({
  alarmBox: {
    "&:hover": {
      background: "#f5f6f8",
    },
    "&:hover button": {
      display: "block",
    },
  },
  deleteBtn: {
    display: "none",
  },
});

class HeaderAlarm extends Component {
  render() {
    const { classes, content } = this.props;
    //console.log("HeaderAlarm content: ", content);

    let titleText = "";
    const alarmType = content.type;

    switch (alarmType) {
      case "AR101":
        titleText = "예약 완료";
        break;
      case "AR102":
        titleText = "예약 취소 완료";
        break;
      case "AR202":
        titleText = "후기 등록";
        break;
      case "AR301":
        titleText = "이벤트 알림 - 쿠폰 이벤트";
        break;
      default:
        titleText = "알림";
        break;
    }

    const resultDesc = content.description.replace(/(\n|\r\n)/g, "<br />");

    return (
      <Box
        style={{ border: "1px solid #ddd", borderRadius: "4px" }}
        my={1.5}
        py={1}
        px={2}
        className={classes.alarmBox}
      >
        <Box display="flex">
          <Box style={{ flexGrow: "1" }}>
            <Box display="inline-block">
              <h4 style={{ margin: "8px 0" }}>{titleText}</h4>
            </Box>
            <Box display="inline-block" ml={1}>
              {/* <h6 style={{ margin: "8px 0" }}>03-22 13:49</h6> */}
              <h6 style={{ margin: "8px 0" }}>
                {format(content.reg_date, "MM-dd HH:mm")}
              </h6>
            </Box>
          </Box>
          <Box>
            <IconButton
              className={classes.deleteBtn}
              onClick={() => {
                //alert("삭제");
                this.props.onDelete(content.key, "N");
              }}
              style={{ padding: "4px" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box my={0.5}>
          <Divider />
        </Box>
        {/* <Box align="center" py={2}> */}
        <Box py={2}>
          {/* 류하나님이 오늘예약을 하셨습니다. (숙박/1박/대구팰리스 – 특실(고사양PC) 2020-03-22 /50,000원) */}
          {Parser(resultDesc)}
          <br />
          {content.link && (
            <>
              바로가기:{" "}
              <Link to={content.link.target} style={{ textDecoration: "none" }}>
                <Box
                  ml={2}
                  onClick={() => {
                    this.props.onClose("PopoverAlarmBtn");
                  }}
                >
                  {content.link.target}
                </Box>
              </Link>
            </>
          )}
        </Box>
      </Box>
    );
  }
}

const styledHeaderAlarm = withStyles(styles)(HeaderAlarm);

export { styledHeaderAlarm as HeaderAlarm };
