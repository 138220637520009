import React from "react";

import { withStyles, withTheme } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";

import { BoxWrap } from "components";
import { Settings } from "@material-ui/icons";
import { Link } from "react-router-dom";

/**
 * 채널 아웃 컨포넌트
 */

const font = {
  fontSize: "17px",
  fontWeight: "bold",
  letterSpacing: "normal",
};

const badge = {
  margin: "0 0 0 16px",
  borderRadius: "6px",
};

const styles = (theme) => ({
  badgeUse: {
    ...font,
    ...badge,
    padding: "2px 17px 4px",
    color: "#372ff9",
    border: "solid 2px #372ff9",
    backgroundColor: "#ffffff",
  },
  badgeNoUse: {
    ...font,
    ...badge,
    padding: "2px 7px 4px",
    color: "#e84f4f",
    border: "solid 2px #e84f4f",
    backgroundColor: "#ffffff",
  },
  linkBtnWrapper: {
    position: "absolute",
    top: "9px",
    right: "15px",
    zIndex: 1,
    fontWeight: "bold",
  },
});

class ChannelOutTemplate extends React.PureComponent {
  handleGoChannelSetting = () => {
    alert("채널설정으로 이동");
  };

  render() {
    const { classes, model } = this.props;
    const { storeChannelOutYn } = model;
    return (
      <BoxWrap>
        <div>
          <div style={{ float: "left", fontSize: "17px", fontWeight: "bold" }}>
            채널아웃 상태
            {storeChannelOutYn === "Y" ? (
              <span className={classes.badgeUse}>판매 중</span>
            ) : (
              <span className={classes.badgeNoUse}>미판매 중</span>
            )}
          </div>
        </div>
        <Link to={`/businessInfo?tab_index=3`}>
          <Button
            startIcon={<Settings />}
            className={classes.linkBtnWrapper}
            variant="contained"
            color="primary"
          >
            채널아웃설정
          </Button>
        </Link>
        <div style={{ clear: "both" }}></div>
      </BoxWrap>
    );
  }
}

// 초기 props 설정
ChannelOutTemplate.defaultProps = {
  model: {},
  onRequestAjax: function () {},
  onRefresh: function () {},
};

export default withTheme(withStyles(styles)(ChannelOutTemplate));
