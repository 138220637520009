/**
 * 브라우저 저장소(ex : 쿠키, 로컬/세션 스토리지)에<br/>
 * 웹앱 데이타를 Read/Write 처리하는 모듈 입니다<p/>
 *
 * @module lib/service-storage
 * @see module:lib/service-api
 */

import { Base64 } from "js-base64";

import * as configApp from "config/config-app";
import * as serviceAPI from "lib/service-api";
import { urlParser, setCookie, getCookie, deleteCookie } from "utils";
import {
  getServiceCodeUserType,
  getServiceCodeUserStatus,
} from "constant/ServiceCode";

const TAG = "[lib/service-storage.js]";
// console.log(TAG, "Create");

const SERVICE_TYPE_PC = configApp.SERVICE_TYPE === "pc";

const USER_STATUS_CHANGED_INFO =
  getServiceCodeUserStatus("정상 - 사용자정보변경");
const USER_TYPE_OWNER = getServiceCodeUserType("사장님 Web - 사장님");
const USER_TYPE_FRONT = getServiceCodeUserType("사장님 Web - 직원");
const USER_TYPE_MANAGER = getServiceCodeUserType("사장님 Web - master");
const USER_TYPE_ADMIN = getServiceCodeUserType("관리자");

const { ROLE_OWNER, ROLE_FRONT, ROLE_MANAGER, ROLE_ADMIN } = configApp; // Roles (역활)

const KEY_IDENTITY = "_1";
// const KEY_TEMP_USER_ID = "_2";
const KEY_ACCEPT_TERMS = "_3";
const KEY_CONNECTED_MOTEL_KEY = "_4";
const KEY_ACCESS_TOKEN = "_5";
const KEY_REFRESH_TOKEN = "_6";

const COOKIE_EXPIRE_AUTO_LOGIN = 1000 * 60 * 60 * 24 * 30; // 30일
const COOKIE_NAME_AUTO_LOGIN = "auto_login_yn";

const COOKIE_EXPIRE_USER_ID = 1000 * 60 * 60 * 24 * 365; // 1년
const COOKIE_NAME_USER_ID = "user_id";

const TOKEN = "token";
const NAME_ACCESS_TOKEN = "accessToken";
const NAME_REFRESH_TOKEN = "refreshToken";

const NAME_USER_ID = "id";
const NAME_USER_NAME = "name";
const NAME_USER_PHONE_NUM = "phoneNumber";
const NAME_USER_EMAIL = "email";
const NAME_USER_STATUS = "status";
const NAME_USER_TYPE = "type";
const NAME_USER_KEY = "key";

const NAME_ROLE = "role";
const NAME_USER = "user";
const NAME_MOTELS = "motels";

let __tempData = {};

let __identity = null;
let __motel = null;
let __consultant = null;

let __tempUserId = null;
let __isFirstLogin = false;
let __landingPage = null;

/**
 * 사용자 아이디를 브라우저의 로컬 스토리지에 임시로 저장하는 메소드 입니다
 *
 * @param {string} tempUserId - 사용자 아이디
 * @returns {Promise} Promise 객체
 */
export function setTempUserId(tempUserId) {
  return new Promise(function (resolve, reject) {
    try {
      setCookie(
        COOKIE_NAME_USER_ID,
        Base64.encode(tempUserId),
        new Date(Date.now() + COOKIE_EXPIRE_USER_ID),
        "/"
      );
      __tempUserId = tempUserId;
      resolve(tempUserId);
    } catch (error) {
      console.error(TAG, "\n", error);
      reject(new Error(`저장 중 에러가 발생 함`));
    }
  });
}

/**
 * 임시로 저장 된 사용자 아이디를 반환하는 메소드 입니다
 *
 * @returns {string|null} 사용자 아이디
 */
export function getTempUserId() {
  if (__tempUserId !== null) return __tempUserId;
  let tempUserId;
  try {
    tempUserId = getCookie(COOKIE_NAME_USER_ID) || "";
    if (!!tempUserId) {
      tempUserId = Base64.decode(tempUserId);
    }
  } catch (error) {
    tempUserId = "";
  }
  __tempUserId = tempUserId;
  return tempUserId;
}

/**
 * 임시로 저장 된 사용자 아이디를 삭제하는 메소드 입니다
 */
export function removeTempUserId() {
  try {
    deleteCookie(COOKIE_NAME_USER_ID);
  } catch (error) {}
  __tempUserId = null;
}

// identity에 유저 정보를 적용
function updateIdentity(identity, user, motels) {
  identity[NAME_USER] = user;
  identity[NAME_MOTELS] = motels || [];

  // 권한 체크
  switch (user.type) {
    case "관리자":
    case USER_TYPE_ADMIN:
      // 관리자
      identity[NAME_ROLE] = ROLE_ADMIN;
      break;
    case USER_TYPE_MANAGER:
      // 매니저
      identity[NAME_ROLE] = ROLE_MANAGER;
      break;
    case USER_TYPE_OWNER:
      // 사업주
      identity[NAME_ROLE] = ROLE_OWNER;
      break;
    case USER_TYPE_FRONT:
    default:
      // 직원
      identity[NAME_ROLE] = ROLE_FRONT;
      break;
  }
}

/**
 * 서버에서 전달 받은 사용자 식별 정보를 브라우저의 스토리지에 저장하는 메소드 입니다
 *
 * @param {object} data - 사용자의 Identity 정보
 * @param {boolean} isAutoLogin - 자동 로그인 여부
 * @returns {Promise} Promise 객체
 */
export function setIdentity(data, isAutoLogin) {

  return new Promise(function (resolve, reject) {
    if (window.sessionStorage) {
      let access_token;
      let refresh_token;
      if (!!data && !!data.result) {
        if (!!data.result.accessToken && !!data.result.refreshToken) {
          let accessToken = data.result.accessToken;
          if (accessToken.hasOwnProperty(TOKEN)) {
            access_token = accessToken[TOKEN];
          }

          let refreshToken = data.result.refreshToken;
          if (refreshToken.hasOwnProperty(TOKEN)) {
            refresh_token = refreshToken[TOKEN];
          }

          accessToken = null;
          refreshToken = null;
        }
      }

      console.log(
        TAG,
        `Called setIdentity(). access_token=${access_token}, refresh_token=${refresh_token}, isAutoLogin=${isAutoLogin})`
      );

      if (!!access_token) {
        // 1. 브라우저 스토리지에 저장
        window.sessionStorage.setItem(
          KEY_IDENTITY,
          JSON.stringify({
            [KEY_ACCESS_TOKEN]: access_token,
            [KEY_REFRESH_TOKEN]: refresh_token,
          })
        );

        // 2. 정상 처리 되었는지 확인
        try {
          // 2.1. 브라우저에 정상 저장 되었는지 확인
          let identity =
            JSON.parse(window.sessionStorage.getItem(KEY_IDENTITY)) || null;

          if (!identity) {
            return reject(new Error(`브라우저에 저장 되지 않음`));
          }

          // 2.2. 입력 한 정보와 저장 된 정보가 동일한지 확인
          if (access_token !== identity[KEY_ACCESS_TOKEN]) {
            return reject(
              new Error(`입력 한 정보와 저장 된 정보가 동일 하지 않음`)
            );
          }

          // 최초 로그인(아이디/패스워드) 시에만 저장한다

          if (isAutoLogin !== undefined) {
            // 자동 로그인을 위해 로컬 스토리지에 access_token, refresh_token을 저장
            window.localStorage.setItem(
              KEY_IDENTITY,
              JSON.stringify({
                [KEY_ACCESS_TOKEN]: access_token,
                [KEY_REFRESH_TOKEN]: refresh_token,
              })
            );

            if (isAutoLogin === true) {
              /* ------------------------------------------------------------------
               *  1. 로그인 유지 선택 시
               * ------------------------------------------------------------------
               * 쿠키 만료 시간을 30일로 설정.(JIRA PLATFORM-41)
               * 브라우저 종료 후 재실행 되더라도 자동로그인이 수행 되도록 처리
               * -----------------------------------------------------------------*/
              setCookie(
                COOKIE_NAME_AUTO_LOGIN,
                "Y",
                new Date(Date.now() + COOKIE_EXPIRE_AUTO_LOGIN),
                "/"
              );
            } else if (isAutoLogin === false) {
              /* ------------------------------------------------------------------
               *  2. 로그인 유지 미선택 시
               * ------------------------------------------------------------------
               * 브라우저 종료 전까지만, 자동로그인이 수행 되도록 처리
               * -----------------------------------------------------------------*/
              setCookie(COOKIE_NAME_AUTO_LOGIN, "N", null, "/");
            }
          }

          // 2.3. 정상 처리
          updateIdentity(identity, data.result.user, data.result.userStores);
          __identity = identity;
          resolve(identity);

          identity = null;
        } catch (error) {
          console.error(TAG, "\n", error);
          reject(new Error(`저장 중 에러가 발생 함`));
        }

        return;
      }

      // 3. 입력 값 중에 주요 정보가 누락 되어 Reject 처리
      return reject(
        new Error(
          `Reject - 입력 값 중에 주요 정보가 누락. ${NAME_ACCESS_TOKEN}=${access_token}`
        )
      );
    }

    return reject(
      new Error(
        `Reject - 브라우저가 스토리지 기능(sessionStorage or localStorage)을 미지원`
      )
    );
  });
}

export function setIdentityByUser(user) {
  return new Promise(function (resolve, reject) {
    if (window.sessionStorage) {

      if (!!user) {
        window.sessionStorage.setItem(
            KEY_IDENTITY,
            JSON.stringify({
              [NAME_USER]: user,
            })
        );

        try {
          let identity =
              JSON.parse(window.sessionStorage.getItem(KEY_IDENTITY)) || null;

          if (!identity) {
            return reject(new Error(`브라우저에 저장 되지 않음`));
          }

          switch (user.type) {
            case "관리자":
            case USER_TYPE_ADMIN:
              // 관리자
              identity[NAME_ROLE] = ROLE_ADMIN;
              break;
            case USER_TYPE_MANAGER:
              // 매니저
              identity[NAME_ROLE] = ROLE_MANAGER;
              break;
            case USER_TYPE_OWNER:
              // 사업주
              identity[NAME_ROLE] = ROLE_OWNER;
              break;
            case USER_TYPE_FRONT:
            default:
              // 직원
              identity[NAME_ROLE] = ROLE_FRONT;
              break;
          }

          __identity = identity;
          resolve(identity);

          identity = null;

        } catch (error) {
          console.error(TAG, "\n", error);
          reject(new Error(`저장 중 에러가 발생 함`));
        }

        return;
      }
    }
    return reject(
      new Error(
        `Reject - 브라우저가 스토리지 기능(sessionStorage or localStorage)을 미지원`
      )
    );
  });
}

/**
 * 쿠키 저장 상태에 따라 사용자의 Identity 정보를 삭제하는 메소드 입니다
 */
export function removeIdentityByCookie() {
  // if (!SERVICE_TYPE_PC) return;
  if (!getCookie(COOKIE_NAME_AUTO_LOGIN)) removeIdentity();
}

/**
 * 쿠키 저장 상태에 따라 강제 로그아웃 여부를 반환하는 메소드 입니다.
 */
export function isForceLogoutByCookie() {
  // if (!SERVICE_TYPE_PC) return false;
  let identity = getIdentity();
  const accessToken = getAccessToken(identity); // 서버 연동 시 header에 필수 포함
  const refreshToken = getRefreshToken(identity); // 토큰 갱신 시 필요
  identity = null;
  if (!!accessToken && !!refreshToken) {
    return !getCookie(COOKIE_NAME_AUTO_LOGIN);
  }
  return false;
}

/**
 * 사용자의 Identity 정보를 삭제하는 메소드 입니다
 */
export function removeIdentity() {
  clearIdentity();
  try {
    window.localStorage.removeItem(KEY_IDENTITY);
    deleteCookie(COOKIE_NAME_AUTO_LOGIN);
  } catch (error) {}
}

/**
 * 실행 중에 사용 되는 Identity 정보를 삭제하는 메소드 입니다
 */
export function clearIdentity() {
  try {
    window.sessionStorage.removeItem(KEY_IDENTITY);
    window.sessionStorage.removeItem(KEY_CONNECTED_MOTEL_KEY);
  } catch (error) {}
  __identity = null;
  __motel = null;
  __consultant = null;
}

/**
 * 사용자의 Identity 정보를 반환하는 메소드 입니다
 *
 * @returns {object} 사용자의 Identity 정보
 */
export function getIdentity() {
  if (__identity !== null) return __identity;
  let identity;
  try {
    identity = JSON.parse(window.sessionStorage.getItem(KEY_IDENTITY)) || {};
  } catch (error) {
    identity = {};
  }
  __identity = identity;
  return identity;
}

/**
 * 로컬에 저장 된 사용자의 Identity 정보를 반환하는 메소드 입니다
 *
 * @returns {object} 사용자의 Identity 정보
 */
export function getAutoLoginData() {
  let identity;
  try {
    identity = JSON.parse(window.localStorage.getItem(KEY_IDENTITY)) || null;
  } catch (error) {
    identity = {};
  }
  return identity;
}

/**
 * 사용자의 Identity 정보를 설정하는 메소드 입니다
 *
 * @param {object} newIdentity - 사용자의 Identity 정보
 */
export function applyIdentity(newIdentity) {
  __identity = newIdentity;

  let access_token, refresh_token;
  if (!!__identity) {
    if (__identity.hasOwnProperty(KEY_ACCESS_TOKEN)) {
      access_token = __identity[KEY_ACCESS_TOKEN];
    }
    if (__identity.hasOwnProperty(KEY_REFRESH_TOKEN)) {
      refresh_token = __identity[KEY_REFRESH_TOKEN];
    }
  }

  if (!!access_token) {
    window.sessionStorage.setItem(
      KEY_IDENTITY,
      JSON.stringify({
        [KEY_ACCESS_TOKEN]: access_token,
        [KEY_REFRESH_TOKEN]: refresh_token,
      })
    );
  }
}

/**
 * 사용자의 최초 로그인 여부를 반환하는 메소드 입니다<p/>
 *
 * 반환 즉시 해당 정보는 false로 초기화 됩니다.
 *
 * @returns {boolean} 최초 로그인 여부
 */
export function isFirstLogin() {
  const isFirstLogin = __isFirstLogin;
  __isFirstLogin = false;
  return isFirstLogin;
}

/**
 * 사용자 상태를 체크하여 최초 로그인 여부를 설정하는 메소드 입니다.<p/>
 */
export function notifyFirstLogin() {
  __isFirstLogin = true;
}

/**
 * 사용자의 Identity 정보에서 Access Token을 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {string} Access Token
 */
export function getAccessToken(identity) {
  if (!!identity && identity.hasOwnProperty(KEY_ACCESS_TOKEN)) {
    return identity[KEY_ACCESS_TOKEN];
  }
  return null;
}

/**
 * 사용자의 Identity 정보에서 Refresh Token을 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {string} Refresh Token
 */
export function getRefreshToken(identity) {
  if (!!identity && identity.hasOwnProperty(KEY_REFRESH_TOKEN)) {
    return identity[KEY_REFRESH_TOKEN];
  }
  return null;
}

/**
 * 사용자의 Identity 정보에서 id를 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {string} id
 */
export function getUserId(identity) {
  if (!!identity && identity.hasOwnProperty(NAME_USER)) {
    return identity[NAME_USER][NAME_USER_ID] || "";
  }
  return null;
}

/**
 * 사용자의 Identity 정보에서 name을 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {string} name
 */
export function getUserName(identity) {
  // console.log(TAG, `Called getUserName()`);
  if (!!identity && identity.hasOwnProperty(NAME_USER)) {
    return identity[NAME_USER][NAME_USER_NAME] || "";
  }
  return null;
}

/**
 * 사용자의 Identity 정보에서 phone number를 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {string} phone number
 */
export function getUserPhoneNum(identity) {
  if (!!identity && identity.hasOwnProperty(NAME_USER)) {
    return identity[NAME_USER][NAME_USER_PHONE_NUM] || "";
  }
  return null;
}

/**
 * 사용자의 Identity 정보에서 email을 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {string} email
 */
export function getUserEmail(identity) {
  if (!!identity && identity.hasOwnProperty(NAME_USER)) {
    return identity[NAME_USER][NAME_USER_EMAIL] || "";
  }
  return null;
}

/**
 * 사용자의 Identity 정보에서 status을 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {string} status
 */
export function getUserStatus(identity) {
  if (!!identity && identity.hasOwnProperty(NAME_USER)) {
    return identity[NAME_USER][NAME_USER_STATUS] || "";
  }
  return null;
}

/**
 * 사용자의 Identity 정보에서 type을 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {string} type
 */
export function getUserType(identity) {
  if (!!identity && identity.hasOwnProperty(NAME_USER)) {
    return identity[NAME_USER][NAME_USER_TYPE] || "";
  }
  return null;
}

/**
 * 사용자의 Identity 정보에서 key를 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {string} type
 */
export function getUserKey(identity) {
  if (!!identity && identity.hasOwnProperty(NAME_USER)) {
    return identity[NAME_USER][NAME_USER_KEY] || "";
  }
  return null;
}

/**
 *  사용자 계정이 웹마스터인지 확인하여 반환합니다.
 * @return {boolean} isMaster - 웹마스터 권한 여부
 */
export function getIsMaster() {
  const identity = getIdentity();
  const userType = getUserType(identity);
  // 사장님 Web - master
  return userType === USER_TYPE_MANAGER;
}

/**
 * 사용자 정보를 업데이트 하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @param {object} identity - 사용자의 정보 모델
 */
export function updateUser(identity, modelUser) {
  if (!!identity && identity.hasOwnProperty(NAME_USER) && !!modelUser) {
    // modelUser의 데이타를 identity[NAME_USER]에 반영한다.
    Object.assign(identity[NAME_USER], modelUser);
    // email, phoneNumber 로 올 경우
    if (modelUser.hasOwnProperty("email")) {
      identity[NAME_USER][NAME_USER_EMAIL] = modelUser.email;
    }
    if (modelUser.hasOwnProperty("phoneNumber")) {
      identity[NAME_USER][NAME_USER_PHONE_NUM] = modelUser.phoneNumber;
    }
  }
}

/**
 * 사용자의 Identity 정보에서 Role 정보를 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {number} 역활(role)
 */
export function getUserRole(identity) {
  if (!!identity && identity.hasOwnProperty(NAME_ROLE)) {
    return identity[NAME_ROLE];
  }
  return null;
}

/**
 * 사용자의 Identity 정보에서 role를 조회하여 사업주 여부를 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {boolean} 사업주 여부
 */
export function isOwner(identity) {
  if (!!identity && identity.hasOwnProperty(NAME_ROLE)) {
    return parseInt(identity[NAME_ROLE]) === ROLE_OWNER;
  }
  return false;
}

/**
 * 사용자의 Identity 정보에서 motels를 추출하여 반환하는 메소드 입니다
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {array} motels
 */
export function getMotels(identity) {
  if (!!identity && identity.hasOwnProperty(NAME_MOTELS)) {
    return identity[NAME_MOTELS];
  }
  return [];
}

/**
 * 사용자가 현재 접속한(선택) 모델 정보를 설정하는 메소드 입니다
 *
 * @param {any} key - 모델의 식별키
 */
export function connectMotelWithPromise(key) {
  let resDetailMotels;

  return serviceAPI
    .getDetailMotels({
      motel_key: key,
    })
    .then((response) => {

      const { code, result } = response.data;
      if ( code === '20000000' && result.motel.category === 'HOTEL') {
        document.title = "꿀스테이 [호텔]";
      }
      __motel = JSON.parse(JSON.stringify(response.data.result.motel));
      window.sessionStorage.setItem(KEY_CONNECTED_MOTEL_KEY, key);

      if (SERVICE_TYPE_PC) {

        return Promise.resolve(response);
      }

      resDetailMotels = response;
      return serviceAPI
        .getServiceMotels({ motel_key: key })
        .then((response) => {
          let motelService = response.data.result.motel_service;
          if (!!motelService) {
            const name = motelService.consultant_name || "";
            const phoneNumber = motelService.consultant_phone_number || "";
            const callNumber = phoneNumber.replace(/-/gi, "");
            __consultant = {
              name: name,
              callNumber: callNumber,
              phoneNumber: phoneNumber,
            };
          }
          motelService = null;
          return Promise.resolve(resDetailMotels);
        })
        .catch((e) => {
          return Promise.resolve(resDetailMotels);
        });
    })
    .finally(() => {
      resDetailMotels = null;
    });
}

/**
 * 사용자가 직전에 접속한(선택) 모델 키를 반환하는 메소드 입니다
 *
 * @returns {string|null} 모델의 식별키
 */
export function getConnectedMotelKey() {
  let key = null;
  try {
    key = window.sessionStorage.getItem(KEY_CONNECTED_MOTEL_KEY) || null;
  } catch (error) {}
  return key;
}

/**
 * 현재 접속한(선택) 모델 정보를 반환하는 메소드 입니다
 *
 * @param {object|null} 모델 정보
 */
export function getCurrentMotel() {
  return __motel;
}

/**
 * 사용자의 이용 약관 동의 정보를 삭제하는 메소드 입니다
 */
export function removeAcceptTerms() {
  try {
    window.sessionStorage.removeItem(KEY_ACCEPT_TERMS);
  } catch (error) {}
}

/**
 * 사용자의 이용 약관 동의 정보를 저장하는 메소드 입니다
 *
 * @param {object} params - 사용자의 이용 약관 동의 정보
 */
export function saveAcceptTerms(params) {
  try {
    window.sessionStorage.setItem(KEY_ACCEPT_TERMS, JSON.stringify(params));
  } catch (error) {}
}

/**
 * 사용자의 이용 약관 동의 정보를 반환 하는 메소드 입니다
 *
 * @returns {object|null} 사용자의 이용 약관 동의 정보
 */
export function getAcceptTerms() {
  let acceptTerms = null;
  try {
    acceptTerms =
      JSON.parse(window.sessionStorage.getItem(KEY_ACCEPT_TERMS)) || null;
  } catch (error) {}
  return acceptTerms;
}

/**
 * 전역으로 관리 되는 임시 데이타를 반환 하는 메소드 입니다<p/>
 *
 * 해당 데이타는 브라우저 갱신 및 종료 시 사라집니다.<p/>
 * 키값은 tmp~ 접두사를 붙이는 것을 권장 합니다.
 *
 * @returns {object} 임시 데이타
 */
export function getTempData() {
  return __tempData;
}

/**
 * 임시 데이타를 초기화 하는 메소드 입니다
 */
export function resetTempData() {
  Object.keys(__tempData).map((key) => {
    __tempData[key] = null;
  });
  __tempData = {};
}

/**
 * 랜딩 페이지 정보를 설정 하는 메소드 입니다
 */
export function findLandingPageFromLocation() {
  let query = urlParser.getQueryString(window.location);
  let motelKey = query.get("motel_key");
  if (!!motelKey && !!window.location.pathname) {
    __landingPage = {
      motelKey: motelKey,
      route: {
        pathname: window.location.pathname.replace(configApp.BASE_NAME, ""),
        search: window.location.search,
      },
    };
  }
  query = null;
}

/**
 * 랜딩 페이지 정보를 반환 하는 메소드 입니다
 */
export function getLandingPageOnce() {
  let landingPage = __landingPage;
  __landingPage = null;
  return landingPage;
}

/**
 * 랜딩 페이지 정보를 지우는 메소드 입니다
 */
export function clearLandingPage() {
  if (!!__landingPage) {
    __landingPage = null;
  }
}

/**
 * 현재 계정의 휴대폰 번호의 Overwrite 여부를 반환 합니다.
 *
 * @param {object} identity - 사용자의 Identity 정보
 * @returns {boolean} 휴대폰 번호 Overwrite 여부
 */
export function isOverwritePhonenumber(identity) {
  if (USER_STATUS_CHANGED_INFO === getUserStatus(identity)) {
    return true;
  }
  return false;
}

/**
 * 현재 모텔의 컨설턴트 정보를 반환 합니다.
 *
 * @returns {object} 컨설턴트 정보
 */
export function getConsultant() {
  return __consultant;
}
