// action
import * as serviceAPI from "../../../lib/service-api";
import * as serviceBrowser from "lib/service-browser";
import * as serviceStorage from "../../../lib/service-storage";
import {logout, logoutRequest} from "../authentication";
import {getCookie} from "../../../utils";
const COOKIE_NAME_AUTO_LOGIN = "auto_login_yn";

export const GET_REFRESH_TOKEN_REQUEST = 'GET_REFRESH_TOKEN_REQUEST';
export const GET_REFRESH_TOKEN_SUCCESS = 'GET_REFRESH_TOKEN_SUCCESS';
export const GET_REFRESH_TOKEN_FAILURE = 'GET_REFRESH_TOKEN_FAILURE';

export const getRefreshTokenRequest = () => ({
    type: GET_REFRESH_TOKEN_REQUEST,
});
export const getRefreshTokenSuccess = (refreshToken) => ({
    type: GET_REFRESH_TOKEN_SUCCESS,
    refreshToken,
});

export const getREFRESHTokenFailure = (error) => ({
    type: GET_REFRESH_TOKEN_FAILURE, error
});

export const postRefreshToken = (refreshToken) => {
    serviceBrowser.disableBackNavigation();

    return (dispatch) => {
        dispatch(getRefreshTokenRequest());

        return serviceAPI.postRefreshToken({
            refreshToken: refreshToken
        })
            .then((response) => {
                if (response !== undefined) {
                    const value = getCookie(COOKIE_NAME_AUTO_LOGIN);
                    dispatch(getRefreshTokenSuccess(response?.data?.result?.refreshToken?.token));
                    serviceStorage.setIdentity(response?.data, value === 'Y');
                    return Promise.resolve(response);
                }
            })
            .catch(error => {
                dispatch(getREFRESHTokenFailure(error));
                return Promise.reject(error);
            })
            .finally(() => {
                // serviceStorage.resetTempData();
                serviceBrowser.enableBackNavigation();
            });
    };
};

