import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import InfiniteScroll from "react-infinite-scroll-component";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logoutRequest } from "system/redux-ducks/authentication";
import { openAlert, openSnackbar } from "lib/service-redux-alerts";

import { Link } from "react-router-dom";

import * as configApp from "config/config-app";
import * as serviceStorage from "lib/service-storage";
import * as serviceAPI from "lib/service-api";

import { history } from "lib/history";
import {changeIsCheapest, changeIsUnlimitedCoupon} from "system/redux-ducks/headerBadge";

import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Box,
  Popover,
  Button,
  Divider,
} from "@material-ui/core";
import {
  Notifications,
  // AccountCircle,
  Settings,
  Person,
  PowerSettingsNew,
  Storefront,
} from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";

import { HeaderAlarm } from "components";
import PopupMotelSelect from "pages/signin/components/molecules/PopupMotelSelect";
import PopupMotelSelectMaster from "pages/signin/components/molecules/PopupMotelSelectMaster";

import CloseIcon from "@material-ui/icons/Close";
import { grey } from "@material-ui/core/colors";

import { PopupConsts } from "constant";
// import { getUserTypeAtServiceCode } from "constant/ServiceCode";
import { POPUP_ACTION_OK, openConfirmPopupWithPromise } from "lib/helper-popup";
import AppVisibleBadge from "components/AppVisibleBadge";
import AppHotelBadge from "components/AppHotelBadge";
import AppLowestPriceBadge from "components/AppLowestPriceBadge";
import AppUnlimitedCouponBadge from "components/AppUnlimitedCouponBadge";
import AppSanHaWingsBadge from "../../../../components/AppSanHaWingsBadge";

const { ROLE_MANAGER, ROLE_ADMIN } = configApp;

//const drawerWidth = 256;
const drawerWidth = 0;
const ROOT_PATH = configApp.getPathRoot();

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    margin: "0 0 0 4px",
  },
  menuButtonOuter: {
    background: "#000",
    height: "64px",
    width: "72px",
    color: "#fff",
  },
  menuButtonHidden: {
    display: "none",
  },
  // 기존 스타일
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    // display: 'none',
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    "& a, & a:hover": {
      textDecoration: "none",
    },
  },
  toolbar: {
    paddingLeft: "0",
  },
  popoverRoot: {
    padding: theme.spacing(2),
    width: "440px",
    "& h2": {
      margin: "8px",
    },
  },
  topButton: {
    marginLeft: "0.5rem",
    /*
    20201014 박태성C - 상단 우측 버튼 영역의 스타일 수정
      - 현상 : IE11에서 [알림] 버튼의 뱃지 갯수가 버튼 바깥 영역에 그려지지 않음
      - 원인 : IE11에서는 button 태그의 overflow 속성이 hidden으로 설정 됨.
      - 처리 : 상단 우측 버튼의 overflow는 visible이 되도록 수정함
  */
    overflow: "visible",
  },
});

const WhiteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[50]),
    backgroundColor: grey[50],
    "&:hover": {
      backgroundColor: grey[100],
    },
  },
}))(Button);

// const $ = window.$; //jquery 선언

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.motelKey = serviceStorage.getConnectedMotelKey();

    this.state = {
      msgList: [],
      lastList: [],
      totalCnt: 0,
      ajaxOK: false,
      anchorElMypage: null,
      anchorElAlarm: null,
      hasMore: true,
      scrollCursor: 0,
      isOpenPopupMotelSelect: false,
      appVisibleYn: null, // 제휴점 노출 여부,
      isHotel : false, // 호텔 노출 여부,
      isSanHaWings: false, // 산하윙스 제휴점 여부
    };

    this.isActive = true;

    this.getMessageAlarmsMotels = this.getMessageAlarmsMotels.bind(this);

    this.fetchMoreData = this.fetchMoreData.bind(this);
  }
  componentDidMount() {
    // 제휴점 노출/비노출 여부 조회 및 적용 , 호텔 여부 조회
    if(serviceStorage.getCurrentMotel()) {

      this.setState({
        appVisibleYn: serviceStorage.getCurrentMotel().isAppVisible,
        isSanHaWings: serviceStorage.getCurrentMotel().isSanHaWings,
        isHotel: serviceStorage.getCurrentMotel().category === 'HOTEL' ? true : false,
        isUnlimitedCoupon: serviceStorage.getCurrentMotel().isUnlimitedCoupon,
      });
      this.props.changeIsCheapest(serviceStorage.getCurrentMotel().isCheapest)
      this.props.changeIsUnlimitedCoupon(serviceStorage.getCurrentMotel().isUnlimitedCoupon)
    }

    this.getMessageAlarmsMotels(null, true);
  }

  componentWillUnmount() {
    this.isActive = false;
  }

  handleOpenMotelSelectPopup = (event) => {
    this.setState({
      ...this.state,
      isOpenPopupMotelSelect: true,
    });
  };

  handleCloseMotelSelectPopup = (event) => {
    this.setState({
      ...this.state,
      isOpenPopupMotelSelect: false,
    });
  };

  getMessageAlarmsMotels = (cursor, hideLoading) => {
    if (!this.motelKey || !this.isActive) return;
    console.log("getMessageAlarmsMotels cursor: ", cursor);

    const reqObj = {
      motel_key: this.motelKey ? this.motelKey : null,
      count: 10,
      cursor: !!cursor ? cursor : 0,
    };

    // 20201019 박태성C - 최초 진입 시 로딩 화면을 숨기기 위해 hideLoading 추가
    serviceAPI
      .getMessageAlarmsMotels(reqObj, true)
      .then((res) => {
        console.log("getMessageAlarmsMotels !!!!!!!!!!!!!!!!!!!!!!!!!!!");
        console.log(res);
        if (res.data.code !== "20000000") {
          throw new Error("test");
        } else {
          let resultCnt = 0;
          const msgList = !!res.data?.result?.alarms
            ? res.data.result.alarms
            : [];

          const count = Number(res.data?.result?.total_count);
          if (count > 99) resultCnt = "N";
          else resultCnt = "" + count;

          if (!!msgList) {
            if (!this.isActive) return;
            console.log("msgList: ", msgList);

            this.setState({
              msgList: this.state.msgList.concat(msgList),
              totalCnt: resultCnt,
              ajaxOK: true,
              lastList: res.data.result.alarms,
              scrollCursor: !!cursor ? cursor : 0,
            });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  createDeleteMessageAlarmsMotels = (key, deleteYN) => {
    if (!this.motelKey || !this.isActive) return;

    const reqObj = {
      motel_key: this.motelKey ? this.motelKey : null,
      alarm_key: key,
      delete_all_yn: deleteYN,
    };

    //console.log("createDeleteMessageAlarmsMotels reqObj: ", reqObj);

    serviceAPI
      .createDeleteMessageAlarmsMotels(reqObj)
      .then((res) => {
        console.log(
          "createDeleteMessageAlarmsMotels !!!!!!!!!!!!!!!!!!!!!!!!!!!"
        );
        console.log(res);
        if (res.data.code !== "20000000") {
          throw new Error("test");
        } else {
          this.setState({
            msgList: [],
          });

          setTimeout(() => {
            if (!this.isActive) return;
            this.getMessageAlarmsMotels();
          }, 200);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  onScroll = (event) => {
    let scrollHeight = Math.max(
      document.documentElement.scrollHeight
      //document.body.scrollHeight
    );

    let scrollTop = Math.max(
      document.documentElement.scrollTop
      //document.body.scrollTop
    );

    let clientHeight = document.documentElement.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight - 50) {
      //스크롤이 맨 밑바닥에 닿으면 이벤트 발생

      console.log("밑바닥 도착!!!!!!");
      window.removeEventListener("scroll", this.onScroll, true);
    }
  };

  fetchMoreData = () => {
    console.log("fetchMoreData this.state: ", this.state);
    const { totalCnt } = this.state;
    const nextCursor = this.state.scrollCursor + 10;

    if (this.state.lastList.length < 10 || nextCursor >= totalCnt) {
      this.setState({ hasMore: false });
      return;
    }

    console.log("fetchMoreData nextCursor: ", nextCursor);

    this.getMessageAlarmsMotels(nextCursor);
  };

  render() {
    const {
      classes,
      open,
      // handleDrawerOpen, handleDrawerClose
    } = this.props;
    const {
      msgList,
      totalCnt,
      ajaxOK,
      anchorElMypage,
      anchorElAlarm,
      isOpenPopupMotelSelect,
      appVisibleYn,
      isSanHaWings,
      isHotel,
    } = this.state;
    //console.log("Header.js this.props : ", this.props);

    const handleClick = (event) => {
      if (event.currentTarget.id === "PopoverMypageBtn") {
        this.setState({
          anchorElMypage: event.currentTarget,
        });
      } else if (event.currentTarget.id == "PopoverAlarmBtn") {
        //if (!msgList || msgList.length === 0) return;
        this.setState({
          anchorElAlarm: event.currentTarget,
        });

        this.setState({
          msgList: [],
          totalCnt: 0,
          ajaxOK: false,
        });

        setTimeout(() => {
          this.getMessageAlarmsMotels();
        }, 100);
      }
    };

    const noticeModalDelete = (key, deleteYN) => {
      console.log("noticeModalDelete");
      //alert("삭제");
      this.createDeleteMessageAlarmsMotels(key, deleteYN);

      if (deleteYN === "Y") {
        handleClose("PopoverAlarmBtn");
      }
    };

    const handleClose = (btn) => {
      console.log("handleClose");

      if (btn === "PopoverMypageBtn") {
        this.setState({
          anchorElMypage: null,
        });
      } else if (btn === "PopoverAlarmBtn") {
        this.setState({
          anchorElAlarm: null,
        });
      }
    };

    // const PoperOpenMypage = Boolean(anchorElMypage);
    const PoperOpenAlarm = Boolean(anchorElAlarm);

    const handleLogoutRequest = () => {
      openConfirmPopupWithPromise(PopupConsts.LOGOUT).then((actionType) => {
        if (actionType !== POPUP_ACTION_OK) return;
        this.props.logoutRequest();
      });
    };

    let identity = serviceStorage.getIdentity();
    const userName = serviceStorage.getUserName(identity);
    const role = serviceStorage.getUserRole(identity);
    // const userType = getUserTypeAtServiceCode(
    //   serviceStorage.getUserType(identity)
    // );

    let isMaster = false;
    switch (role) {
      case ROLE_MANAGER:
      case ROLE_ADMIN:
        isMaster = true;
        break;
      default:
        break;
    }

    let currentMotel = serviceStorage.getCurrentMotel();
    let motelName = "";
    if (!!currentMotel) {
      motelName = currentMotel.motel_extra.motel_name;
    }
    identity = currentMotel = null;

    const menuNavControl = () => {
      /* 화면 사이즈에 반응되도록 되어있어서 일단 주선처리 */
      // if (open) {
      //   handleDrawerClose();
      // } else {
      //   handleDrawerOpen();
      // }
      history.push("/dashboard");
    };

    return (
      <>
        <AppBar
          // position="absolute"
          position="fixed" // 양태욱 변경
          className={clsx(classes.appBar, open && classes.appBarShift)}
          style={{backgroundColor : isHotel ? '#95de64' : '' }}
        >
          <Toolbar className={classes.toolbar}>
            <Box
                style={{
                  marginLeft: "1rem",
                  minWidth: `${configApp.ICON_MAIN_WIDTH}px`,
                }}
                onClick={menuNavControl}
            >
              <img
                  alt="header"
                  style={{
                    marginTop: "6px",
                    cursor: "pointer",
                    height: "44px",
                    width: "auto",
                  }}
                  // height={"44"}
                  src={`${ROOT_PATH}${configApp.ICON_MAIN}`}
              />
            </Box>

            {!!motelName && (
                <Box
                    ml={4}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                >
                  <h3>
                    {motelName.length > 50
                        ? motelName.substr(0, 50) + "..."
                        : motelName}
                  </h3>
                </Box>
            )}
            {/* 제휴점 노출/미노출 뱃지 영역 */}
            <div
                style={{
                  marginLeft: "10px",
                }}
            >
              <AppVisibleBadge visibleYn={appVisibleYn}/>
            </div>
            <div
                style={{
                  marginLeft: isHotel ? '10px' : '0px'
                }}
            >
              {isHotel &&
                  <AppHotelBadge/>
              }
            </div>
            <div
                style={{
                  marginLeft: this.props.isCheapest ? '10px' : '0px'
                }}
            >
              {this.props.isCheapest &&
                  <AppLowestPriceBadge/>
              }
            </div>
            <div
                style={{
                  marginLeft: this.props.isUnlimitedCoupon ? '10px' : '0px'
            }}
            >
              {this.props.isUnlimitedCoupon &&
                  <AppUnlimitedCouponBadge/>
              }
            </div>
            <div
                style={{
                  marginLeft: isSanHaWings ? '10px' : '0px'
                }}
            >
              {isSanHaWings &&
                  <AppSanHaWingsBadge/>
              }
            </div>
            <div className={classes.grow}/>
            <div className={classes.sectionDesktop}>
              <Button
                  variant="outlined"
                  size="small"
                  className={classes.topButton}
                  onClick={this.handleOpenMotelSelectPopup}
                  startIcon={<Storefront/>}
              >
                <strong>제휴점 변경</strong>
              </Button>

              <Link
                  to="/settings"
                  variant="outlined"
                  className={classes.topLink}
              >
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.topButton}
                    startIcon={<Settings/>}
                >
                  {/* <strong>설정</strong> */}
                  <strong>제휴점 설정</strong>
                </Button>
              </Link>

              {/* <Button
                variant="outlined"
                size="small"
                ml={1}
                onClick={handleClick}
                id="PopoverMypageBtn"
                className={classes.topButton}
                startIcon={<Person />}
              >
                <strong>내계정</strong>
              </Button> */}

              <Link to="/mypage" variant="outlined" className={classes.topLink}>
                <Button
                    variant="outlined"
                    size="small"
                    className={classes.topButton}
                    startIcon={<Person/>}
                >
                  <strong>내계정</strong>
                </Button>
              </Link>

              <Button
                  variant="outlined"
                  size="small"
                  ml={1}
                  onClick={handleLogoutRequest}
                  className={classes.topButton}
                  startIcon={<PowerSettingsNew/>}
              >
                <strong>로그아웃</strong>
              </Button>

              <Button
                  variant="outlined"
                  size="small"
                  ml={1}
                  onClick={handleClick}
                  id="PopoverAlarmBtn"
                  className={classes.topButton}
                  startIcon={<Notifications/>}
              >
                {!!msgList && msgList.length > 0 ? (
                    <Badge badgeContent={totalCnt} color="secondary">
                      <strong>알림</strong>
                    </Badge>
                ) : (
                    <strong>알림</strong>
                )}
              </Button>

              {/* 
              <Link to="/settings">
                <IconButton style={{ color: "#404149" }}>
                  <Settings />
                </IconButton>
              </Link>

              <IconButton
                id="PopoverMypageBtn"
                onClick={handleClick}
                style={{ color: "#404149" }}
              >
                <AccountCircle />
              </IconButton>

              <IconButton
                id="PopoverAlarmBtn"
                onClick={handleClick}
                style={{ color: "#404149" }}
              >
                {!!msgList && msgList.length > 0 ? (
                  <Badge badgeContent={totalCnt} color="secondary">
                    <Notifications />
                  </Badge>
                ) : (
                  <Notifications />
                )}
              </IconButton> */}

              {/* <Popover
                open={PoperOpenMypage}
                anchorEl={anchorElMypage}
                onClose={() => {
                  handleClose("PopoverMypageBtn");
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box className={classes.popoverRoot}>
                  <Box display="flex">
                    <Box style={{ flexGrow: "1" }}>
                      <h3 style={{ margin: "8px 0" }}>계정 정보</h3>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          handleClose("PopoverMypageBtn");
                        }}
                        style={{ padding: "4px" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box>
                    <Divider />
                  </Box>
                  <Box align="center" pt={3} pb={5}>
                    <h3>{userName}</h3>
                    <h5 style={{ margin: "0" }}>[ {userType} ]</h5>
                  </Box>
                  <Box align="center">
                    <WhiteButton
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleClose("PopoverMypageBtn");
                        history.push("/mypage");
                      }}
                      style={{ width: "100px" }}
                    >
                      <strong>마이페이지</strong>
                    </WhiteButton>
                    <WhiteButton
                      variant="contained"
                      size="small"
                      onClick={this.handleOpenMotelSelectPopup}
                      style={{ marginLeft: "16px", width: "100px" }}
                    >
                      <strong>숙소변경</strong>
                    </WhiteButton>

                    {-1 < configApp.PERMISSION_OWNER.indexOf(role) && (
                      <WhiteButton
                        variant="contained"
                        size="small"
                        onClick={() => {
                          handleClose("PopoverMypageBtn");
                          history.push("/alliance/add");
                        }}
                        style={{ marginLeft: "16px", width: "100px" }}
                      >
                        <strong>제휴신청</strong>
                      </WhiteButton>
                    )}

                  </Box>
                </Box>
              </Popover> */}

              <Popover
                  open={PoperOpenAlarm}
                  anchorEl={anchorElAlarm}
                  onClose={() => {
                    handleClose("PopoverAlarmBtn");
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  style={{overflow: "hidden"}}
              >
                <Box className={classes.popoverRoot}>
                  <Box display="flex">
                    <Box style={{flexGrow: "1"}}>
                      <h3 style={{margin: "8px 0"}}>알림</h3>
                    </Box>
                    <Box>
                      <IconButton
                          onClick={() => {
                            handleClose("PopoverAlarmBtn");
                          }}
                          style={{padding: "4px"}}
                      >
                        <CloseIcon/>
                      </IconButton>
                    </Box>
                  </Box>

                  <Box>
                    <Divider/>
                  </Box>

                  {!!msgList && msgList.length > 0 ? (
                      <InfiniteScroll
                          dataLength={msgList.length}
                          next={this.fetchMoreData}
                          hasMore={this.state.hasMore}
                          loader={<h4>Loading...</h4>}
                          height={"74vh"}
                          endMessage={
                            <p style={{textAlign: "center"}}>
                              <b>알림의 마지막 입니다.</b>
                              <br/>
                            </p>
                          }
                      >
                        {msgList.map((msg, index) => (
                            <HeaderAlarm
                                key={index}
                                content={msg}
                                onDelete={noticeModalDelete}
                                onClose={handleClose}
                            />
                        ))}
                      </InfiniteScroll>
                  ) : !!ajaxOK ? (
                      <p style={{textAlign: "center"}}>
                        <b>알림이 없습니다.</b>
                        <br/>
                      </p>
                  ) : (
                      <p style={{textAlign: "center"}}>
                        <b>Loading...</b>
                        <br/>
                      </p>
                  )}

                  <Box align="center" py={1}>
                    {!!msgList && msgList.length > 0 && (
                        <WhiteButton
                            variant="contained"
                            size="small"
                            onClick={() => {
                              // alert("마지막 키: ", msgList.length - 1);
                              //console.log("마지막 키: ", msgList[msgList.length - 1].key);
                              noticeModalDelete(msgList[0].key, "Y");
                            }}
                            style={{width: "100px"}}
                        >
                          <strong>전체삭제</strong>
                        </WhiteButton>
                    )}
                  </Box>
                </Box>
              </Popover>
            </div>
          </Toolbar>
        </AppBar>

        {!!isMaster && (
            <PopupMotelSelectMaster
                open={isOpenPopupMotelSelect}
                onClose={this.handleCloseMotelSelectPopup}
            />
        )}
        {!isMaster && (
            <PopupMotelSelect
            open={isOpenPopupMotelSelect}
            onClose={this.handleCloseMotelSelectPopup}
          />
        )}
      </>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
};

// 20200402 박태성C - Redux 모듈과 연동하여 로그아웃, 알림팝업 출력 처리하고자 추가
export default connect(
    (state) => {
      return {
        isCheapest : state.headerBadge.isCheapest,
        isUnlimitedCoupon : state.headerBadge.isUnlimitedCoupon,
      }
    },
    (dispatch) =>
  bindActionCreators({ logoutRequest, openAlert, openSnackbar, changeIsCheapest, changeIsUnlimitedCoupon}, dispatch)
)(withStyles(styles)(Header));

// function noop() {}
